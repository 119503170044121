import "./js/navbar.html";
import "./js/bank_account_list.html";
//import "./js/form_sidebar.html";
//import "./js/form_sidebar.js";
import "./js/page.html";
import "./js/templates/crm_notes.html";
import "./js/templates/crm_activities.js";
import "./js/workflow";
// import "./js/custom_quick_entry/customer_quick_entry";
import Column from "./js/column.js";

frappe.provide("frappe.views");
frappe.views.TreeView = class TreeView {
	constructor(opts) {
		var me = this;

		this.opts = {};
		this.opts.get_tree_root = true;
		this.opts.show_expand_all = true;
		$.extend(this.opts, opts);
		this.doctype = opts.doctype;
		this.args = { doctype: me.doctype };
		this.page_name = frappe.get_route_str();
		this.get_tree_nodes = me.opts.get_tree_nodes || "frappe.desk.treeview.get_children";

		this.get_permissions();
		this.make_page();
		this.make_filters();
		this.root_value = null;

		if (me.opts.get_tree_root) {
			this.get_root();
		}

		this.onload();
		if (!this.opts.do_not_setup_menu) {
			this.set_menu_item();
		}

		this.set_primary_action();
	}
	get_permissions() {
		this.can_read = frappe.model.can_read(this.doctype);
		this.can_create =
			frappe.boot.user.can_create.indexOf(this.doctype) !== -1 ||
			frappe.boot.user.in_create.indexOf(this.doctype) !== -1;
		this.can_write = frappe.model.can_write(this.doctype);
		this.can_delete = frappe.model.can_delete(this.doctype);
	}
	make_page() {
		if(this.doctype == "BOM Configurator"){
			var me = this;
			if (!this.opts || !this.opts.do_not_make_page) {
				this.parent = frappe.container.add_page(this.page_name);
				frappe.ui.make_app_page({ parent: this.parent, single_column: true });
				this.page = this.parent.page;
				frappe.container.change_to(this.page_name);
				frappe.breadcrumbs.add(
					me.opts.breadcrumb || locals.DocType[me.doctype].module,
					me.doctype
				);
				this.set_title();
				this.page.main.css({
					"min-height": "300px",
				});
				this.page.main.addClass("frappe-card");
			} else {
				this.page = this.opts.page;
				$(this.page[0]).addClass("frappe-card");
			}
		}
		else{
			var me = this;
			this.parent = frappe.container.add_page(this.page_name);
			frappe.ui.make_app_page({ parent: this.parent, single_column: true });
			this.page = this.parent.page;
			frappe.container.change_to(this.page_name);
			frappe.breadcrumbs.add(
				me.opts.breadcrumb || locals.DocType[me.doctype].module,
				me.doctype
			);
			this.set_title();
			this.page.main.css({
				"min-height": "300px",
			});
			this.page.main.addClass("frappe-card");			
		}
		if (this.opts.show_expand_all) {
			this.page.add_inner_button(__("File Manager"), function () {
				window.open(frappe.urllib.get_full_url("/app/file/view/home/"));
			});
			this.page.add_inner_button(__("Collapse All"), function () {
				me.tree.load_children(me.tree.root_node, false);
			});

			this.page.add_inner_button(__("Expand All"), function () {
				me.tree.load_children(me.tree.root_node, true);
			});
		}
		if (this.opts.view_template) {
			var row = $('<div class="row"><div>').appendTo(this.page.main);
			this.body = $('<div class="col-sm-6 col-xs-12"></div>').appendTo(row);
			this.node_view = $('<div class="col-sm-6 hidden-xs"></div>').appendTo(row);
		} else {
			this.body = this.page.main;
		}
	}
	set_title() {
		this.page.set_title(this.opts.title || __("{0} Tree", [__(this.doctype)]));
	}
	onload() {
		var me = this;
		this.opts.onload && this.opts.onload(me);
	}
	make_filters() {
		var me = this;
		frappe.treeview_settings.filters = [];
		$.each(this.opts.filters || [], function (i, filter) {
			if (frappe.route_options && frappe.route_options[filter.fieldname]) {
				filter.default = frappe.route_options[filter.fieldname];
			}

			if (!filter.disable_onchange) {
				filter.change = function () {
					filter.onchange && filter.onchange();
					var val = this.get_value();
					me.args[filter.fieldname] = val;
					if (val) {
						me.root_label = val;
					} else {
						me.root_label = me.opts.root_label;
					}
					me.set_title();
					me.make_tree();
				};
			}

			me.page.add_field(filter);

			if (filter.default) {
				$("[data-fieldname='" + filter.fieldname + "']").trigger("change");
			}
		});
	}
	get_root() {
		var me = this;
		frappe.call({
			method: me.get_tree_nodes,
			args: me.args,
			callback: function (r) {
				if (r.message) {
					if (r.message.length > 1) {
						me.root_label = me.doctype;
						me.root_value = "";
					} else {
						me.root_label = r.message[0]["value"];
						me.root_value = me.root_label;
					}
					me.make_tree();
				}
			},
		});
	}
	make_tree() {
		$(this.parent).find(".tree").remove();

		var use_label = this.args[this.opts.root_label] || this.root_label || this.opts.root_label;
		var use_value = this.root_value;
		if (use_value == null) {
			use_value = use_label;
		}
		this.tree = new frappe.ui.Tree({
			parent: this.body,
			label: use_label,
			root_value: use_value,
			expandable: true,

			args: this.args,
			method: this.get_tree_nodes,

			// array of button props: {label, condition, click, btnClass}
			toolbar: this.get_toolbar(),

			get_label: this.opts.get_label,
			on_render: this.opts.onrender,
			on_get_node: this.opts.on_get_node,
			on_click: (node) => {
				this.select_node(node);
			},
		});

		cur_tree = this.tree;
		cur_tree.view_name = "Tree";
		this.post_render();
	}

	rebuild_tree() {
		let me = this;

		frappe.call({
			method: "frappe.utils.nestedset.rebuild_tree",
			args: {
				doctype: me.doctype,
				parent_field: "parent_" + me.doctype.toLowerCase().replace(/ /g, "_"),
			},
			callback: function (r) {
				if (!r.exc) {
					me.make_tree();
				}
			},
		});
	}

	post_render() {
		var me = this;
		me.opts.post_render && me.opts.post_render(me);
	}

	select_node(node) {
		var me = this;
		if (this.opts.click) {
			this.opts.click(node);
		}
		if (this.opts.view_template) {
			this.node_view.empty();
			$(
				frappe.render_template(me.opts.view_template, {
					data: node.data,
					doctype: me.doctype,
				})
			).appendTo(this.node_view);
		}
		if (me.doctype == 'Company Core Documents') {
			frappe.call({
				method: "stanch.after_migrate.get_attached_file",
				args: {
					details: node.label
				},
				callback(r) {
					if (r.message) {
						frappe.confirm(__("Do you need to Download the attached file?"), function () {
							window.location.href = repl(r.message);

						})
					}
				}
			})

		}

	}
	get_toolbar() {
		var me = this;
		if (me.doctype == 'Company Core Documents') {
			var toolbar = [

				{
					label: __("Attach File"),
					condition: function (node) {
						return !node.is_root && node.expandable == 0;
					},
					click: function (node) {

						frappe.call({
							method: "stanch.after_migrate.get_ccd_is_group",
							args: {
								name: node.label,
							},
							callback(r) {
								if (r.message) {
									var dialog = new frappe.ui.Dialog({
										title: 'Attach File',
										fields: [
											{
												label: 'Attach File',
												fieldname: 'attach_file',
												fieldtype: 'Attach',
											},

										],
										primary_action: function () {
											var file_name = this.get_value('attach_file');
											frappe.call({
												method: "stanch.after_migrate.attach_doc",
												args: {
													file_name: file_name,
													name: node.label,
												},
												callback(r) {
													if (r.message) {
														frappe.msgprint("File has been Attached Successfully")
													}
												}
											})
											this.hide();
										},
										primary_action_label: 'Attach'
									}).show();
								}
							}
						})
					},
				},
				{
					label: __(me.can_write ? "Edit" : "Details"),
					condition: function (node) {
						return !node.is_root && me.can_read;
					},
					click: function (node) {
						frappe.set_route("Form", me.doctype, node.label);
					},
				},
				{
					label: __("Add Child"),
					condition: function (node) {
						return me.can_create && node.expandable && !node.hide_add;
					},
					click: function (node) {
						me.new_node();
					},
					btnClass: "hidden-xs",
				},
				{
					label: __("Rename"),
					condition: function (node) {
						let allow_rename = true;
						if (me.doctype && frappe.get_meta(me.doctype)) {
							if (!frappe.get_meta(me.doctype).allow_rename) allow_rename = false;
						}
						return !node.is_root && me.can_write && allow_rename;
					},
					click: function (node) {
						frappe.model.rename_doc(me.doctype, node.label, function (new_name) {
							node.$tree_link.find("a").text(new_name);
							node.label = new_name;
							me.tree.refresh();
						});
					},
					btnClass: "hidden-xs",
				},
				{
					label: __("Delete"),
					condition: function (node) {
						return !node.is_root && me.can_delete;
					},
					click: function (node) {
						frappe.model.delete_doc(me.doctype, node.label, function () {
							node.parent.remove();
						});
					},
					btnClass: "hidden-xs",
				},
			];
		}
		else if (me.doctype == 'Compliance Structure') {
			var toolbar = [

				{
					label: __("Create Task"),
					condition: function (node) {
						return !node.is_root && node.expandable == 0;
					},
					click: function (node) {
						frappe.route_options = {
							'select_the_compliance': node.data.title,
						}
						frappe.new_doc("Compliance Tasks")
					},
				},
				{
					label: __(me.can_write ? "Edit" : "Details"),
					condition: function (node) {
						return !node.is_root && me.can_read;
					},
					click: function (node) {
						frappe.set_route("Form", me.doctype, node.label);
					},
				},
				{
					label: __("Add Child"),
					condition: function (node) {
						return me.can_create && node.expandable && !node.hide_add;
					},
					click: function (node) {
						me.new_node();
					},
					btnClass: "hidden-xs",
				},
				{
					label: __("Rename"),
					condition: function (node) {
						let allow_rename = true;
						if (me.doctype && frappe.get_meta(me.doctype)) {
							if (!frappe.get_meta(me.doctype).allow_rename) allow_rename = false;
						}
						return !node.is_root && me.can_write && allow_rename;
					},
					click: function (node) {
						frappe.model.rename_doc(me.doctype, node.label, function (new_name) {
							node.$tree_link.find("a").text(new_name);
							node.label = new_name;
							me.tree.refresh();
						});
					},
					btnClass: "hidden-xs",
				},
				{
					label: __("Delete"),
					condition: function (node) {
						return !node.is_root && me.can_delete;
					},
					click: function (node) {
						frappe.model.delete_doc(me.doctype, node.label, function () {
							node.parent.remove();
						});
					},
					btnClass: "hidden-xs",
				},
			];
		}
		else {
			var toolbar = [

				{
					label: __(me.can_write ? "Edit" : "Details"),
					condition: function (node) {
						return !node.is_root && me.can_read;
					},
					click: function (node) {
						frappe.set_route("Form", me.doctype, node.label);
					},
				},
				{
					label: __("Add Child"),
					condition: function (node) {
						return me.can_create && node.expandable && !node.hide_add;
					},
					click: function (node) {
						me.new_node();
					},
					btnClass: "hidden-xs",
				},
				{
					label: __("Rename"),
					condition: function (node) {
						let allow_rename = true;
						if (me.doctype && frappe.get_meta(me.doctype)) {
							if (!frappe.get_meta(me.doctype).allow_rename) allow_rename = false;
						}
						return !node.is_root && me.can_write && allow_rename;
					},
					click: function (node) {
						frappe.model.rename_doc(me.doctype, node.label, function (new_name) {
							node.$tree_link.find("a").text(new_name);
							node.label = new_name;
							me.tree.refresh();
						});
					},
					btnClass: "hidden-xs",
				},
				{
					label: __("Delete"),
					condition: function (node) {
						return !node.is_root && me.can_delete;
					},
					click: function (node) {
						frappe.model.delete_doc(me.doctype, node.label, function () {
							node.parent.remove();
						});
					},
					btnClass: "hidden-xs",
				},
			];
		}


		if (this.opts.toolbar && this.opts.extend_toolbar) {
			toolbar = toolbar.filter((btn) => {
				return !me.opts.toolbar.find((d) => d["label"] == btn["label"]);
			});
			return toolbar.concat(this.opts.toolbar);
		} else if (this.opts.toolbar && !this.opts.extend_toolbar) {
			return this.opts.toolbar;
		} else {
			return toolbar;
		}
	}
	new_node() {
		var me = this;
		var node = me.tree.get_selected_node();

		if (!(node && node.expandable)) {
			frappe.msgprint(__("Select a group node first."));
			return;
		}

		this.prepare_fields();

		// the dialog
		var d = new frappe.ui.Dialog({
			title: __("New {0}", [__(me.doctype)]),
			fields: me.fields,
		});

		var args = $.extend({}, me.args);
		args["parent_" + me.doctype.toLowerCase().replace(/ /g, "_")] = me.args["parent"];

		d.set_value("is_group", 0);
		d.set_values(args);

		// create
		d.set_primary_action(__("Create New"), function () {
			var btn = this;
			var v = d.get_values();
			if (!v) return;

			v.parent = node.label;
			v.doctype = me.doctype;

			if (node.is_root) {
				v["is_root"] = node.is_root;
			} else {
				v["is_root"] = false;
			}

			d.hide();
			frappe.dom.freeze(__("Creating {0}", [me.doctype]));

			$.extend(args, v);
			return frappe.call({
				method: me.opts.add_tree_node || "frappe.desk.treeview.add_node",
				args: args,
				callback: function (r) {
					if (!r.exc) {
						me.tree.load_children(node);
					}
				},
				always: function () {
					frappe.dom.unfreeze();
				},
			});
		});
		d.show();
	}
	prepare_fields() {
		var me = this;

		this.fields = [
			{
				fieldtype: "Check",
				fieldname: "is_group",
				label: __("Group Node"),
				description: __("Further nodes can be only created under 'Group' type nodes"),
			},
		];

		if (this.opts.fields) {
			this.fields = this.opts.fields;
		}

		this.ignore_fields = this.opts.ignore_fields || [];
		if (me.doctype == "Compliance Structure") {
			var mandatory_fields = $.map(me.opts.meta.fields, function (d) {
				return d.allow_in_quick_entry ? d : null;
			});
		}
		else if (me.doctype == "Compliance Tasks") {
			var mandatory_fields = $.map(me.opts.meta.fields, function (d) {
				return d.allow_in_quick_entry ? d : null;
			});
			var complianceField = me.opts.meta.fields.find(function (field) {
				return field.fieldname === 'select_the_compliance';
			});
			if (complianceField) {
				complianceField.get_query = function () {
					return {
						filters: {
							is_group: '0'
						}
					};
				};
			}
		}
		else {
			var mandatory_fields = $.map(me.opts.meta.fields, function (d) {
				return d.reqd || (d.bold && !d.read_only && !!d.is_virtual) ? d : null;
			});
		}


		var opts_field_names = this.fields.map(function (d) {
			return d.fieldname;
		});

		mandatory_fields.map(function (d) {
			if (
				$.inArray(d.fieldname, me.ignore_fields) === -1 &&
				$.inArray(d.fieldname, opts_field_names) === -1
			) {
				me.fields.push(d);
			}
		});
	}
	print_tree() {
		if (!frappe.model.can_print(this.doctype)) {
			frappe.msgprint(__("You are not allowed to print this report"));
			return false;
		}
		var tree = $(".tree:visible").html();
		var me = this;
		frappe.ui.get_print_settings(false, function (print_settings) {
			var title = __(me.docname || me.doctype);
			frappe.render_tree({ title: title, tree: tree, print_settings: print_settings });
			frappe.call({
				method: "frappe.core.doctype.access_log.access_log.make_access_log",
				args: {
					doctype: me.doctype,
					report_name: me.page_name,
					page: tree,
					method: "Print",
				},
			});
		});
	}
	set_primary_action() {
		var me = this;
		if (!this.opts.disable_add_node && this.can_create) {
			me.page.set_primary_action(
				__("New"),
				function () {
					me.new_node();
				},
				"add"
			);
		}
	}
	set_menu_item() {
		var me = this;

		this.menu_items = [
			{
				label: __("View List"),
				action: function () {
					frappe.set_route("List", me.doctype);
				},
			},
			{
				label: __("Print"),
				action: function () {
					me.print_tree();
				},
			},
			{
				label: __("Refresh"),
				action: function () {
					me.make_tree();
				},
			},
		];

		if (
			frappe.user.has_role("System Manager") &&
			frappe.meta.has_field(me.doctype, "lft") &&
			frappe.meta.has_field(me.doctype, "rgt")
		) {
			this.menu_items.push({
				label: __("Rebuild Tree"),
				action: function () {
					me.rebuild_tree();
				},
			});
		}

		if (me.opts.menu_items) {
			me.menu_items.push.apply(me.menu_items, me.opts.menu_items);
		}

		$.each(me.menu_items, function (i, menu_item) {
			var has_perm = true;
			if (menu_item["condition"]) {
				has_perm = eval(menu_item["condition"]);
			}

			if (has_perm) {
				me.page.add_menu_item(menu_item["label"], menu_item["action"]);
			}
		});
	}
};

frappe.views.CommunicationComposer = class CommunicationComposer extends frappe.views.CommunicationComposer {
	get_attachment_row(attachment, checked) {
		checked = true
		return $(`<p class="checkbox flex">
			<label class="ellipsis" title="${attachment.file_name}">
				<input
					type="checkbox"
					data-file-name="${attachment.name}"
					${checked ? "checked" : ""}>
				</input>
				<span class="ellipsis">${attachment.file_name}</span>
			</label>
			&nbsp;
			<a href="${attachment.file_url}" target="_blank" class="btn-linkF">
				${frappe.utils.icon("link-url")}
			</a>
		</p>`);
	}
	setup_email() {
		// email
		const fields = this.dialog.fields_dict;
		if (this.frm.doc.doctype == "Payment Order") {
			this.attach_document_print = 0

		}


		if (this.attach_document_print) {
			$(fields.attach_document_print.input).click();
			$(fields.select_print_format.wrapper).toggle(true);
		}

		$(fields.send_me_a_copy.input).on("click", () => {
			// update send me a copy (make it sticky)
			const val = fields.send_me_a_copy.get_value();
			frappe.db.set_value("User", frappe.session.user, "send_me_a_copy", val);
			frappe.boot.user.send_me_a_copy = val;
		});
	}


}
frappe.views.FileView = class FileView extends frappe.views.ListView {
	static load_last_view() {
		const route = frappe.get_route();
		if (route.length === 2) {
			const view_user_settings = frappe.get_user_settings("File", "File");
			frappe.set_route(
				"List",
				"File",
				view_user_settings.last_folder || frappe.boot.home_folder
			);
			return true;
		}
		return redirect_to_home_if_invalid_route();
	}

	get view_name() {
		return "File";
	}

	show() {
		if (!redirect_to_home_if_invalid_route()) {
			super.show();
		}
	}

	setup_view() {
		this.render_header();
		this.setup_events();
		this.$page.find(".layout-main-section-wrapper").addClass("file-view");
		this.add_file_action_buttons();
		if (has_common(["Sales User", "Sales Approver", "Purchase User", "Purchase Approver", "Accounts User", "Accounts Manager", "Payments Manager", "HR User", "HR Manager"], frappe.user_roles)) {

			this.page.add_button(__("File Open Status Report"), () => {
				window.open(frappe.urllib.get_full_url("app/query-report/File%20Open%20Status"
				));
			});
			this.page.add_button(__("File Uploader"), () => {
				window.open(frappe.urllib.get_full_url("/app/file-uploader/"
				));
			});
		}
		this.page.add_button(__("Toggle Grid View"), () => {
			frappe.views.FileView.grid_view = !frappe.views.FileView.grid_view;
			this.refresh();
		});


	}

	setup_no_result_area() {
		this.$no_result = $(`<div class="no-result">
			<div class="breadcrumbs">${this.get_breadcrumbs_html()}</div>
			<div class="text-muted flex justify-center align-center">
				${this.get_no_result_message()}
			</div>
		</div>`).hide();
		this.$frappe_list.append(this.$no_result);
	}

	get_args() {
		let args = super.get_args();
		if (frappe.views.FileView.grid_view) {
			Object.assign(args, {
				order_by: `is_folder desc, ${this.sort_by} ${this.sort_order}`,
			});
		}
		return args;
	}

	set_breadcrumbs() {
		const route = frappe.get_route();
		route.splice(-1);
		const last_folder = route[route.length - 1];
		if (last_folder === "File") return;

		frappe.breadcrumbs.add({
			type: "Custom",
			label: __("Home"),
			route: "/app/List/File/Home",
		});
	}

	setup_defaults() {
		return super.setup_defaults().then(() => {
			this.page_title = __("File Manager");

			const route = frappe.get_route();
			this.current_folder = route.slice(2).join("/") || "Home";
			this.filters = [["File", "folder", "=", this.current_folder, true]];
			this.order_by = this.view_user_settings.order_by || "file_name asc";

			this.menu_items = this.menu_items.concat(this.file_menu_items());
		});
	}

	file_menu_items() {
		const items = [
			{
				label: __("Home"),
				action: () => {
					frappe.set_route("List", "File", "Home");
				},
			},
			{
				label: __("New Folder"),
				action: () => {
					frappe.prompt(
						__("Name"),
						(values) => {
							if (values.value.indexOf("/") > -1) {
								frappe.throw(__("Folder name should not include '/' (slash)"));
							}
							const data = {
								file_name: values.value,
								folder: this.current_folder,
							};
							frappe.call({
								method: "frappe.core.api.file.create_new_folder",
								args: data,
							});
						},
						__("Enter folder name"),
						__("Create")
					);
				},
			},
			{
				label: __("Import Zip"),
				action: () => {
					new frappe.ui.FileUploader({
						folder: this.current_folder,
						restrictions: {
							allowed_file_types: [".zip"],
						},
						on_success: (file) => {
							frappe.show_alert(__("Unzipping files..."));
							frappe
								.call("frappe.core.api.file.unzip_file", {
									name: file.name,
								})
								.then((r) => {
									if (r.message) {
										frappe.show_alert(__("Unzipped {0} files", [r.message]));
									}
								});
						},
					});
				},
			},
		];

		return items;
	}

	add_file_action_buttons() {
		this.$cut_button = this.page
			.add_button(__("Cut"), () => {
				frappe.file_manager.cut(this.get_checked_items(), this.current_folder);
				this.$checks.parents(".file-wrapper").addClass("cut");
			})
			.hide();

		this.$paste_btn = this.page
			.add_button(__("Paste"), () => frappe.file_manager.paste(this.current_folder))
			.hide();

		this.page.add_actions_menu_item(__("Export as zip"), () => {
			let docnames = this.get_checked_items(true);
			if (docnames.length) {
				open_url_post("/api/method/frappe.core.api.file.zip_files", {
					files: JSON.stringify(docnames),
				});
			}
		});
	}

	set_fields() {
		this.fields = this.meta.fields
			.filter((df) => frappe.model.is_value_type(df.fieldtype) && !df.hidden)
			.map((df) => df.fieldname)
			.concat(["name", "modified", "creation"]);
	}

	prepare_data(data) {
		super.prepare_data(data);

		this.data = this.data.map((d) => this.prepare_datum(d));

		// Bring folders to the top
		const { sort_by } = this.sort_selector;
		if (sort_by === "file_name") {
			this.data.sort((a, b) => {
				if (a.is_folder && !b.is_folder) {
					return -1;
				}
				if (!a.is_folder && b.is_folder) {
					return 1;
				}
				return 0;
			});
		}
	}

	prepare_datum(d) {
		let icon_class = "";
		let type = "";
		if (d.is_folder) {
			icon_class = "folder-normal";
			type = "folder";
		} else if (frappe.utils.is_image_file(d.file_name)) {
			icon_class = "image";
			type = "image";
		} else {
			icon_class = "file";
			type = "file";
		}

		let title = d.file_name || d.file_url;
		title = title.slice(0, 60);
		d._title = title;
		d.icon_class = icon_class;
		d._type = type;

		d.subject_html = `
			${frappe.utils.icon(icon_class)}
			<span>${title}</span>
			${d.is_private ? '<i class="fa fa-lock fa-fw text-warning"></i>' : ""}
		`;
		return d;
	}

	before_render() {
		super.before_render();
		frappe.model.user_settings.save("File", "grid_view", frappe.views.FileView.grid_view);
		this.save_view_user_settings({
			last_folder: this.current_folder,
		});
	}

	render() {
		this.$result.empty().removeClass("file-grid-view");
		if (frappe.views.FileView.grid_view) {
			this.render_grid_view();
		} else {
			super.render();
			this.render_header();
		}
	}

	after_render() { }

	render_list() {
		if (frappe.views.FileView.grid_view) {
			this.render_grid_view();
		} else {
			super.render_list();
		}
	}

	render_grid_view() {
		let html = this.data
			.map((d) => {
				const icon_class = d.icon_class + "-large";
				let file_body_html =
					d._type == "image"
						? `<div class="file-image"><img src="${d.file_url}" alt="${d.file_name}"></div>`
						: frappe.utils.icon(icon_class, {
							width: "40px",
							height: "45px",
						});
				const name = escape(d.name);
				const draggable = d.type == "Folder" ? false : true;
				return `
				<a href="${this.get_route_url(d)}"
					draggable="${draggable}" class="file-wrapper ellipsis" data-name="${name}">
					<div class="file-header">
						<input class="level-item list-row-checkbox hidden-xs" type="checkbox" data-name="${name}">
					</div>
					<div class="file-body">
						${file_body_html}
					</div>
					<div class="file-footer">
						<div class="file-title ellipsis">${d._title}</div>
						<div class="file-creation">${this.get_creation_date(d)}</div>
					</div>
				</a>
			`;
			})
			.join("");

		this.$result.addClass("file-grid-view");
		this.$result.empty().html(
			`<div class="file-grid">
				${html}
			</div>`
		);
	}

	get_breadcrumbs_html() {
		const route = frappe.get_route();
		const folders = route.slice(2);

		return folders
			.map((folder, i) => {
				if (i === folders.length - 1) {
					return `<span>${folder}</span>`;
				}
				const route = folders.reduce((acc, curr, j) => {
					if (j <= i) {
						acc += "/" + curr;
					}
					return acc;
				}, "/app/file/view");

				return `<a href="${route}">${folder}</a>`;
			})
			.join("&nbsp;/&nbsp;");
	}

	get_header_html() {
		const breadcrumbs_html = this.get_breadcrumbs_html();

		let header_selector_html = !frappe.views.FileView.grid_view
			? `<input class="level-item list-check-all hidden-xs" type="checkbox" title="${__(
				"Select All"
			)}">`
			: "";

		let header_columns_html = !frappe.views.FileView.grid_view
			? `
				<div class="list-row-col ellipsis hidden-xs">
					<span>${__("Document Name")}</span>
				</div>
				<div class="list-row-col ellipsis hidden-xs">
					<span>${__("Date")}</span>
				</div>
				<div class="list-row-col ellipsis hidden-xs">
					<span>${__("Party")}</span>
				</div>
				<div class="list-row-col ellipsis hidden-xs text-right">
					<span>${__("Total Amount")}</span>
				</div>
				<div class="list-row-col ellipsis hidden-xs text-right">
					<span>${__("Status")}</span>
				</div>`
			: "";

		let subject_html = `
			<div class="list-row-col list-subject level">
				${header_selector_html}
				<span class="level-item">${breadcrumbs_html}</span>
			</div>
			<div class="list-row-col list-subject level">
				${header_columns_html}

			</div>
		`;

		return this.get_header_html_skeleton(subject_html, '<span class="list-count"></span>');
	}

	get_route_url(file) {
		return file.is_folder ? "/app/List/File/" + file.name : this.get_form_link(file);
	}

	get_creation_date(file) {
		const [date] = file.creation.split(" ");
		let created_on;
		if (date === frappe.datetime.now_date()) {
			created_on = comment_when(file.creation);
		} else {
			created_on = frappe.datetime.str_to_user(date);
		}
		return created_on;
	}

	get_left_html(file) {
		file = this.prepare_datum(file);
		const file_size = file.file_size ? frappe.form.formatters.FileSize(file.file_size) : "";
		const route_url = this.get_route_url(file);
		const url = (file.file_name === 'Company Core Documents')
			? frappe.urllib.get_full_url("/app/company-core-documents/view/tree")
			: this.get_route_url(file);

		if (!file.is_folder) {
			if (file.status == 'Open') {
				return `
					<div class="list-row-col ellipsis list-subject level">
						<span class="level-item file-select">
							<input class="list-row-checkbox"
								type="checkbox" data-name="${file.name}">
						</span>
						<span class="level-item  ellipsis" title="${file.file_name}">
							<a class="ellipsis" href="${route_url}" title="${file.file_name}">
								${file.subject_html}
							</a>
						</span>
						</div>
						<div class="list-row-col ellipsis list-subject level">
						<div class="list-row-col ellipsis hidden-xs text-muted">
							<span>${file.attached_to_name || ''}</span>
						</div>
					
						<div class="list-row-col ellipsis hidden-xs text-muted">
							<span>${frappe.format(file.date, { fieldtype: 'Date' }) || ''}</span>
						</div>
						<div class="list-row-col ellipsis hidden-xs text-muted">
							<span>${file.party || ''}</span>
						</div>
						<div class="list-row-col ellipsis hidden-xs text-muted text-right">
							<span>${format_currency(file.grand_total, file.currency) || ''}</span>
						</div>
						<div class="list-row-col ellipsis hidden-xs text-muted text-right">
						<span class="indicator-pill whitespace-nowrap orange">
							<span class="ellipsis"> ${file.status || ''}</span>
						<span>
							</span></span>
							<span></span>
						</div>
					</div>
				`;
			}
			else if (file.status == 'Rejected') {
				return `
					<div class="list-row-col ellipsis list-subject level">
						<span class="level-item file-select">
							<input class="list-row-checkbox"
								type="checkbox" data-name="${file.name}">
						</span>
						<span class="level-item  ellipsis" title="${file.file_name}">
							<a class="ellipsis" href="${route_url}" title="${file.file_name}">
								${file.subject_html}
							</a>
						</span>
					</div>
					<div class="list-row-col ellipsis list-subject level">
						<div class="list-row-col ellipsis hidden-xs text-muted">
							<span>${file.attached_to_name || ''}</span>
						</div>
					
						<div class="list-row-col ellipsis hidden-xs text-muted">
							<span>${frappe.format(file.date, { fieldtype: 'Date' }) || ''}</span>
						</div>
						<div class="list-row-col ellipsis hidden-xs text-muted">
							<span>${file.party || ''}</span>
						</div>
						<div class="list-row-col ellipsis hidden-xs text-muted text-right">
							<span>${format_currency(file.grand_total, file.currency) || ''}</span>
						</div>
						<div class="list-row-col ellipsis hidden-xs text-muted">
						<span class="indicator-pill whitespace-nowrap pink">
							<span class="ellipsis"> ${file.status || ''}</span>
						<span>
							</span></span>
							<span></span>
						</div>
					</div>
				`;
			}
			else if (file.status == 'Cancelled') {
				return `
					<div class="list-row-col ellipsis list-subject level">
						<span class="level-item file-select">
							<input class="list-row-checkbox"
								type="checkbox" data-name="${file.name}">
						</span>
						<span class="level-item  ellipsis" title="${file.file_name}">
							<a class="ellipsis" href="${route_url}" title="${file.file_name}">
								${file.subject_html}
							</a>
						</span>
					</div>
					<div class="list-row-col ellipsis list-subject level">
						<div class="list-row-col ellipsis hidden-xs text-muted">
							<span>${file.attached_to_name || ''}</span>
						</div>
					
						<div class="list-row-col ellipsis hidden-xs text-muted">
							<span>${frappe.format(file.date, { fieldtype: 'Date' }) || ''}</span>
						</div>
						<div class="list-row-col ellipsis hidden-xs text-muted">
							<span>${file.party || ''}</span>
						</div>
						<div class="list-row-col ellipsis hidden-xs text-muted text-right">
							<span>${format_currency(file.grand_total, file.currency) || ''}</span>
						</div>
						<div class="list-row-col ellipsis hidden-xs text-muted text-right">
						<span class="indicator-pill whitespace-nowrap pink">
							<span class="ellipsis"> ${file.status || ''}</span>
						<span>
							</span></span>
							<span></span>
						</div>
					</div>
				`;
			}
			else {

				return `
					<div class="list-row-col ellipsis list-subject level">
						<span class="level-item file-select">
							<input class="list-row-checkbox"
								type="checkbox" data-name="${file.name}">
						</span>
						<span class="level-item  ellipsis" title="${file.file_name}">
							<a class="ellipsis" href="${route_url}" title="${file.file_name}">
								${file.subject_html}
							</a>
						</span>
						</div>
					<div class="list-row-col ellipsis list-subject level">
						<div class="list-row-col ellipsis hidden-xs text-muted">
							<span>${file.attached_to_name || ''}</span>
						</div>
					
						<div class="list-row-col ellipsis hidden-xs text-muted">
							<span>${frappe.format(file.date, { fieldtype: 'Date' }) || ''}</span>
						</div>
						<div class="list-row-col ellipsis hidden-xs text-muted">
							<span>${file.party || ''}</span>
						</div>
						<div class="list-row-col ellipsis hidden-xs text-muted text-right">
							<span>${format_currency(file.grand_total, file.currency) || ''}</span>
						</div>
						<div class="list-row-col ellipsis hidden-xs text-muted text-right">
						<span class="indicator-pill green ellipsis">
							<span class="ellipsis"> ${file.status || ''}</span>
						<span>
							</span></span>
							<span></span>
						</div>
					</div>

				`;

			}
		}
		else {
			return `
				<div class="list-row-col ellipsis list-subject level">
				<span class="level-item file-select">
					<input class="list-row-checkbox" type="checkbox" data-name="${file.name}">
				</span>
				<span class="level-item ellipsis" title="${file.file_name}">
					<a class="ellipsis" href="${url}" title="${file.file_name}">
					${file.subject_html}
					</a>
				</span>
				</div>
				<div class="list-row-col ellipsis hidden-xs text-muted">
				<span>${file.attached_to_name || ''}</span>
				</div>
				<div class="list-row-col ellipsis hidden-xs text-muted">
				<span>${frappe.format(file.date, { fieldtype: 'Date' }) || ''}</span>
				</div>
				<div class="list-row-col ellipsis hidden-xs text-muted">
						<span></span>
					</div>
					<div class="list-row-col ellipsis hidden-xs text-muted">
						<span></span>
					</div>
				<div class="list-row-col ellipsis hidden-xs text-muted">
				<span></span>
				</div>
				`;

		}
	}

	get_right_html(file) {
		return `
			<div class="level-item list-row-activity">
				${comment_when(file.modified)}
			</div>
		`;
	}

	setup_events() {
		super.setup_events();
		this.setup_drag_events();
	}

	setup_drag_events() {
		this.$result.on("dragstart", ".files .file-wrapper", (e) => {
			e.stopPropagation();
			e.originalEvent.dataTransfer.setData("Text", $(e.currentTarget).attr("data-name"));
			e.target.style.opacity = "0.4";
			frappe.file_manager.cut(
				[{ name: $(e.currentTarget).attr("data-name") }],
				this.current_folder
			);
		});

		this.$result.on(
			"dragover",
			(e) => {
				e.preventDefault();
			},
			false
		);

		this.$result.on("dragend", ".files .file-wrapper", (e) => {
			e.preventDefault();
			e.stopPropagation();
			e.target.style.opacity = "1";
		});

		this.$result.on("drop", (e) => {
			e.stopPropagation();
			e.preventDefault();
			const $el = $(e.target).parents(".file-wrapper");

			let dataTransfer = e.originalEvent.dataTransfer;
			if (!dataTransfer) return;

			if (dataTransfer.files && dataTransfer.files.length > 0) {
				new frappe.ui.FileUploader({
					files: dataTransfer.files,
					folder: this.current_folder,
				});
			} else if (dataTransfer.getData("Text")) {
				if ($el.parents(".folders").length !== 0) {
					const file_name = dataTransfer.getData("Text");
					const folder_name = decodeURIComponent($el.attr("data-name"));
					frappe.file_manager.paste(folder_name);
					frappe.show_alert(`File ${file_name} moved to ${folder_name}`);
				}
			}
		});
	}

	toggle_result_area() {
		super.toggle_result_area();
		this.toggle_cut_paste_buttons();
	}

	on_row_checked() {
		super.on_row_checked();
		this.toggle_cut_paste_buttons();
	}

	toggle_cut_paste_buttons() {
		const hide_paste_btn =
			!frappe.file_manager.can_paste ||
			frappe.file_manager.old_folder === this.current_folder;
		const hide_cut_btn = !(this.$checks && this.$checks.length > 0);

		this.$paste_btn.toggle(!hide_paste_btn);
		this.$cut_button.toggle(!hide_cut_btn);
	}
};

frappe.views.FileView.grid_view = frappe.get_user_settings("File").grid_view || false;

function redirect_to_home_if_invalid_route() {
	const route = frappe.get_route();
	if (route[2] === "List") {
		// if the user somehow redirects to List/File/List
		// redirect back to Home
		frappe.set_route("List", "File", "Home");
		return true;
	}
	return false;
}

frappe.ui.form.MultiSelectDialog = class MultiSelectDialog extends frappe.ui.form.MultiSelectDialog {
	get_primary_filters() {
		let fields = [];

		let columns = new Array(3);

		// Hack for three column layout
		// To add column break
		columns[0] = [
			{
				fieldtype: "Data",
				label: __("Name"),
				fieldname: "search_term",
			},
		];
		columns[1] = [];
		columns[2] = [];

		if ($.isArray(this.setters)) {
			this.setters.forEach((setter, index) => {
				columns[(index + 1) % 3].push(setter);
			});
		} else {
			Object.keys(this.setters).forEach((setter, index) => {
				let df_prop = frappe.meta.docfield_map[this.doctype][setter];

				// Index + 1 to start filling from index 1
				// Since Search is a standrd field already pushed
				columns[(index + 1) % 3].push({
					fieldtype: df_prop.fieldtype,
					label: df_prop.label,
					fieldname: setter,
					options: df_prop.options,
					default: this.setters[setter],
				});
			});
		}

		// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/seal
		if (Object.seal) {
			Object.seal(columns);
			// now a is a fixed-size array with mutable entries
		}

		if (this.allow_child_item_selection) {
			this.child_doctype = frappe.meta.get_docfield(
				this.doctype,
				this.child_fieldname
			).options;
			columns[0].push({
				fieldtype: "Check",
				label: __("Select {0}", [this.child_doctype]),
				fieldname: "allow_child_item_selection",
				onchange: this.toggle_child_selection.bind(this),
			});
		}

		fields = [
			...columns[0],
			{ fieldtype: "Column Break" },
			...columns[1],
			{ fieldtype: "Column Break" },
			...columns[2],
			{
				fieldtype: "Button", fieldname: "search", label: "Search", on_change: () => {

					this.get_results();

				}
			},
			{ fieldtype: "Section Break", fieldname: "primary_filters_sb" },
		];

		if (this.add_filters_group) {
			fields.push({
				fieldtype: "HTML",
				fieldname: "filter_area",
			});

		}

		return fields;
	}
}

frappe.ui.form.Layout = class Layout extends frappe.ui.form.Layout {
	constructor(opts) {
		super(opts)
		this.column_count = 1;
		this.row_count = 1;
	}

	render(new_fields) {
		let fields = new_fields || this.fields;

		this.section = null;
		this.column = null;

		if (this.no_opening_section() && !this.is_tabbed_layout()) {
			this.fields.unshift({ fieldtype: 'Section Break' });
		}

		if (this.is_tabbed_layout()) {
			let default_tab = { label: __('Details'), fieldname: 'details', fieldtype: "Tab Break" };
			let first_tab = this.fields[1].fieldtype === "Tab Break" ? this.fields[1] : null;
			if (!first_tab) {
				this.fields.splice(1, 0, default_tab);
			}
		}
		
		fields.forEach(df => {
			switch (df.fieldtype) {
				case "Fold":
					this.make_page(df);
					break;
				case "Section Break":
					this.column_count = 1;
					this.row_count = 1;
					this.make_section(df);
					break;
				case "Column Break":
					// debugger
					// if (!df.hidden)  {
					if (df.options?.toLowerCase() == 'full') {
						this.column_count = 1;
						this.row_count++;
					} else {
						this.column_count++;
					}

					this.make_column(df);
					// }
					break;
				case "Tab Break":
					this.column_count = 1;
					this.row_count = 1;
					this.make_tab(df);
					break;
				default:
					this.make_field(df);
			}
		});
	}

	make_column(df) {
		this.column = new Column(this.section, df, this.column_count, this.row_count);
		if (df && df.fieldname) {
			this.fields_list.push(this.column);
		}
	}

	setup_events() {
		let last_scroll = 0;
		let tabs_list = $(".form-tabs-list");
		let tabs_content = this.tabs_content[0];
		if (!tabs_list.length) return;

		this.tabs_list.off("click").on("click", ".nav-link", (e) => {
			e.preventDefault();
			e.stopImmediatePropagation();
			$(e.currentTarget).tab("show");
			if (tabs_content.getBoundingClientRect().top < 100) {
				tabs_content.scrollIntoView();
				setTimeout(() => {
					$(".page-head").css("top", "-15px");
					$(".form-tabs-list").removeClass("form-tabs-sticky-down");
					$(".form-tabs-list").addClass("form-tabs-sticky-up");
				}, 3);
			}
		});
	}
};


frappe.ui.form.ControlLink = class ControlLink extends frappe.ui.form.ControlLink {
	make_input() {
		super.make_input();
		let me = this;
		this.$input.on("focus", function () {
			me.previous_value = me.$input.val()
			document?.activeElement?.select();
		})
		this.$input.keydown((e) => {
			if (e.which == 27 || e.keyCode == 27) this.$input.val(me.previous_value);
		})
	}
	set_mandatory(value) {
		this.$wrapper.toggleClass("has-error", (this.df.reqd && is_null(value)));
	}

}

// frappe.ui.form.TaxesAndTotals = class TaxesAndTotals extends frappe.ui.form.Control  {
// 	calculate_taxes() {
// 		debugger;
// 		var me = this;
// 		this.frm.doc.rounding_adjustment = 0;
// 		var actual_tax_dict = {};
// 		var desc = ""
// 		// maintain actual tax rate based on idx
// 		$.each(this.frm.doc["taxes"] || [], function(i, tax) {
// 			if (tax.charge_type == "Actual") {
// 				actual_tax_dict[tax.idx] = flt(tax.tax_amount, precision("tax_amount", tax));
// 			}
// 		});
// 		var tds = 0
// 		$.each(this.frm.doc["items"] || [], function(n, item) {
// 			var item_tax_map = me._load_item_tax_rate(item.item_tax_rate);
// 			$.each(me.frm.doc["taxes"] || [], function(i, tax) {
// 				// tax_amount represents the amount of tax for the current step
// 				var current_tax_amount = me.get_current_tax_amount(item, tax, item_tax_map);

// 				// Adjust divisional loss to the last item
// 				if (tax.charge_type == "Actual") {
// 					actual_tax_dict[tax.idx] -= current_tax_amount;
// 					if (n == me.frm.doc["items"].length - 1) {
// 						current_tax_amount += actual_tax_dict[tax.idx];
// 					}
// 				}
// 				// accumulate tax amount into tax.tax_amount
// 				if (tax.charge_type != "Actual" &&
// 					!(me.discount_amount_applied && me.frm.doc.apply_discount_on=="Grand Total")) {
// 					tax.tax_amount += current_tax_amount;
// 				}

// 				// store tax_amount for current item as it will be used for
// 				// charge type = 'On Previous Row Amount'
// 				tax.tax_amount_for_current_item = current_tax_amount;

// 				// tax amount after discount amount
// 				tax.tax_amount_after_discount_amount += current_tax_amount;

// 				// for buying
// 				if(tax.category) {
// 					// if just for valuation, do not add the tax amount in total
// 					// hence, setting it as 0 for further steps
// 					current_tax_amount = (tax.category == "Valuation") ? 0.0 : current_tax_amount;

// 					current_tax_amount *= (tax.add_deduct_tax == "Deduct") ? -1.0 : 1.0;
// 				}

// 				// note: grand_total_for_current_item contains the contribution of
// 				// item's amount, previously applied tax and the current tax on that item
// 				if(i==0) {
// 					tax.grand_total_for_current_item = flt(item.net_amount + current_tax_amount);
// 				} else {
// 					tax.grand_total_for_current_item =
// 						flt(me.frm.doc["taxes"][i-1].grand_total_for_current_item + current_tax_amount);
// 				}

// 				// set precision in the last item iteration
// 				if (n == me.frm.doc["items"].length - 1) {
// 					me.round_off_totals(tax);
// 					me.set_in_company_currency(tax,
// 						["tax_amount", "tax_amount_after_discount_amount"]);

// 					me.round_off_base_values(tax);

// 					// in tax.total, accumulate grand total for each item
// 					me.set_cumulative_total(i, tax);

// 					me.set_in_company_currency(tax, ["total"]);

// 					// adjust Discount Amount loss in last tax iteration
// 					if ((i == me.frm.doc["taxes"].length - 1) && me.discount_amount_applied
// 						&& me.frm.doc.apply_discount_on == "Grand Total" && me.frm.doc.discount_amount) {
// 						me.frm.doc.rounding_adjustment = flt(me.frm.doc.grand_total -
// 							flt(me.frm.doc.discount_amount) - tax.total, precision("rounding_adjustment"));
// 					}
// 				}
// 	var tax_rate = (Object.keys(item_tax_map).indexOf(tax.account_head) != -1) ?
// flt(item_tax_map[tax.account_head], precision("rate", tax)) : tax.rate;
// if(tax.account_head.includes("SGST")){
// 	desc = desc.concat(`<div>SGST @${tax_rate}% on ${item.net_amount} = ${current_tax_amount}</div>`)
// }
// if(tax.account_head.includes("CGST")){
// 	desc = desc.concat(`<div>CGST @${tax_rate}% on ${item.net_amount} = ${current_tax_amount}</div>`)
// }
// if(tax.account_head.includes("IGST")){
// 	desc = desc.concat(`<div>IGST @${tax_rate}% on ${item.net_amount} = ${current_tax_amount}</div>`)
// }
// if(tax.account_head.includes("TDS")){
// 	tds = tds + current_tax_amount
// }
// 				});
// 			desc = desc.concat(`<div>TDS Amount = ${tds}</div>`)

// 			me.frm.set_df_property("total_taxes_and_charges","description",desc)

// 		});


// 	}
// }



// erpnext.TransactionController = class TransactionController extends erpnext.TransactionController {
// 	calculate_charges() {
// 		debugger
// 	}
// }






// frappe.ui.form.ControlData = class ControlData extends frappe.ui.form.ControlData {
// 	make_input() {
// 		super.make_input();
// 		let me = this;
// 		this.$input.on("focus", function() {
// 			me.previous_value = me.$input.val()
// 			document.activeElement && document.activeElement.select();
// 		})
// 		this.$input.keydown((e) => {
// 			if (e.which == 27 || e.keyCode == 27) this.$input.val(me.previous_value);
// 		})
// 	}
// }

frappe.standard_pages['Workspaces'] = function () {
	var wrapper = frappe.container.add_page('Workspaces');

	frappe.ui.make_app_page({
		parent: wrapper,
		name: 'Workspaces',
		title: __("Workspace"),
		single_column: true
	});

	frappe.workspace = new frappe.views.Workspace(wrapper);
	$(wrapper).bind('show', function () {
		frappe.workspace.show();
	});
};


frappe.router.on("change", () => {
	frappe.call({
		method: "stanch.api.employee_list",
		callback: function (r) {

		}
	})
	// var role_list = (frappe.user_roles).filter(item => !["All","Guest","Employee"].includes(item))
	// if(cur_list && in_list(["Purchase Invoice","Purchase Order","Purchase Receipt"],cur_list.doctype) && role_list.length == 1 && role_list[0] == "Purchase User"){
	// 	setTimeout(() => {
	// 		frm.fields_dict["items"].grid.update_docfield_property("item_tax_template", "read_only", 1);
	// 	},500);
	// }
	if (in_list(["List", "Form"], frappe.router.current_route[0]) && in_list(["Purchase Invoice"], frappe.router.current_route[1])) {
		if (frappe.user_roles.length == 3 && in_list(frappe.user_roles, "Employee") && in_list(frappe.user_roles, "All") && in_list(frappe.user_roles, "Guest")) {
			$(".btn-primary").hide();
		}
	}
	else {
		$(".btn-primary").show();
	}
	setTimeout(() => {
		$('.container').css('width', '100%');
		var body = document.body;
		body.style.overflowX = 'hidden';
		$("[data-fieldname='compact_item_print']").hide();
	
		$("#play-video").children().remove();
		var vid = frappe.boot.video_link_map;
		var current_page = cur_page.page.label;
	
		if (cur_page.page.label !== "Video Library") {
			if (current_page && in_list(["List/Sales Invoice/List", "List/Purchase Invoice/List"], current_page)) {
				var doc_map = {
					"Sales Invoice": "Credit Note",
					"Purchase Invoice": "Debit Note"
				};
	
				vid.forEach(item => {
					if (cur_list.doctype === item.item_label || doc_map[cur_list.doctype] === item.item_label) {
						$("#play-video").append(`<a class="dropdown-item" data-fieldname="${item.item_label}">
							<span title="${item.description}">${item.item_label}</span> </a>`);
						
						$(`[data-fieldname="${item.item_label}"]`).click(function () {
							var doctype = strip($(this).text().split("\t").join(""));
							let boot_filter = [...new Set(vid.map((item) => { if (item.item_label == doctype) return item.route }))];
	
							const d = new frappe.ui.Dialog({
								title: doctype,
								fields: [
									{
										fieldtype: 'HTML',
										fieldname: 'video_frame',
										options: `<div style="width: 70vw; height: 70vh; display: flex; justify-content: center; align-items: center;">
													<img src="loading_spinner.gif" alt="Loading..." style="width: 100px; height: 100px;">
												  </div>`
									}
								]
							});
	
							d.show();
	
							d.$wrapper.find('.modal-dialog').css({
								'max-width': '75vw',
								'margin': '30px auto',
								'display': 'flex',
								'align-items': 'center',
								'justify-content': 'center',
								'min-height': '40vh'
							});
	
							d.$wrapper.find('.modal-content').css({
								'width': '100%',
								'max-width': '80vw',
								'height': '100%',
								'margin': '0 auto'
							});
	
							d.$wrapper.find('.modal-body').css({
								'display': 'flex',
								'justify-content': 'center',
								'align-items': 'center',
								'height': 'calc(90vh - 90px)',
								'padding': '0'
							});
	
							d.$wrapper.find('[data-fieldname="video_frame"]').html(`<iframe src="${boot_filter[1]}" style="width: 70vw; height: 70vh;" allow="autoplay"></iframe>`);
						});
					}
				});
			}
			else {
				vid.forEach(item => {
					if (item.item_location == current_page) {
						$("#play-video").append(`<a class="dropdown-item" data-fieldname="${item.item_label}">
							<span title="${item.description}">${item.item_label}</span> </a>`);
	
						$(`[data-fieldname="${item.item_label}"]`).click(function () {
							const d = new frappe.ui.Dialog({
								title: item.item_label,
								fields: [
									{
										fieldtype: 'HTML',
										fieldname: 'video_frame',
										options: `<div style="width: 70vw; height: 70vh; display: flex; justify-content: center; align-items: center;">
													<img src="loading_spinner.gif" alt="Loading..." style="width: 100px; height: 100px;">
												  </div>`
									}
								]
							});
	
							d.show();
	
							d.$wrapper.find('.modal-dialog').css({
								'max-width': '75vw',
								'margin': '30px auto',
								'display': 'flex',
								'align-items': 'center',
								'justify-content': 'center',
								'min-height': '40vh'
							});
	
							d.$wrapper.find('.modal-content').css({
								'width': '100%',
								'max-width': '80vw',
								'height': '100%',
								'margin': '0 auto'
							});
	
							d.$wrapper.find('.modal-body').css({
								'display': 'flex',
								'justify-content': 'center',
								'align-items': 'center',
								'height': 'calc(90vh - 90px)',
								'padding': '0'
							});
	
							d.$wrapper.find('[data-fieldname="video_frame"]').html(`<iframe src="${item.route}" style="width: 70vw; height: 70vh;" allow="autoplay"></iframe>`);
						});
					}
				});
			}
		}
	
		$("#play-video").append(`<a class="dropdown-item" href="/app/video-library">
			<span title="View all">View all</span>
		</a>`);
	}, 1000);	


	if (window.location.pathname.split('/').includes("app") && frappe.session.user != "Administrator" && !frappe.boot.enable_full_feature) {
		if (!frappe.router.current_route.some(page => ["diy", "QuickBooks Migrator", "quickbooks-migrator"].includes(page))) {
			frappe.router.set_route(["List", "QuickBooks Migrator"])
			window.location.reload()
		}
	}

	if (cur_frm) {

		if (frappe.router.current_route[0] == 'Form') {
			setTimeout(() => {
				cur_frm.add_tabbed_sections()
			}, 2000);
			setTimeout(() => {
				$(".layout-side-section").hide();
			}, 1000);

		} else {
			$('.layout-side-section').toggle(/view\/report/.test(window.location.href));
		}
	} else {
		$('.layout-side-section').toggle(/view\/report/.test(window.location.href));
	}
	if (frappe.router.current_route[0] == "print") {
		$('.layout-side-section').show();
		$("[data-fieldname='compact_item_print']").hide()
	}
	else {
		$('.layout-side-section').hide();
	}

});

frappe.views.QueryReport = class QueryReport extends frappe.views.QueryReport {

	setup_events() {
		super.setup_events();
		this.setup_filterable();
	}

	setup_filterable() {
		// filterable events
		this.clear_filters();

		$('.report-wrapper').on("click", ".filterable", (e) => {
			if (e.metaKey || e.ctrlKey) return;
			e.stopPropagation();
			const $this = $(e.currentTarget);
			const key = $this.attr("data-fieldname");
			const value = $this.attr("data-filter");
			this.set_filter_value(key, value);
		});
	}

}

// frappe.Application = class Application extends frappe.Application {
// 	handle_session_expired() {
// 		this.redirect_to_login()
// 	}
// }

frappe.request.call = function (opts) {
	frappe.request.prepare(opts);

	var statusCode = {
		200: function (data, xhr) {
			opts.success_callback && opts.success_callback(data, xhr.responseText);
		},
		400: function (xhr) {
			if (xhr.responseJSON && xhr.responseJSON.exc_type) {
				if (xhr.responseJSON.exc_type == 'CSRFTokenError') {
					frappe.hide_msgprint();
					return frappe.app.redirect_to_login();
				}
			}
			r = xhr.responseJSON;
			r._server_messages = JSON.parse(r._server_messages);
			frappe.hide_msgprint();
			frappe.msgprint(r._server_messages);
		},
		401: function (xhr) {
			if (frappe.app.session_expired_dialog && frappe.app.session_expired_dialog.display) {
				frappe.app.redirect_to_login();
			} else {
				frappe.app.handle_session_expired();
			}
		},
		404: function (xhr) {
			if (frappe.flags.setting_original_route) {
				// original route is wrong, redirect to login
				frappe.app.redirect_to_login();
			} else {
				frappe.msgprint({
					title: __("Not found"), indicator: 'red',
					message: __('The resource you are looking for is not available')
				});
			}
		},
		403: function (xhr) {
			if (frappe.session.user === "Guest" && frappe.session.logged_in_user !== "Guest") {
				// session expired
				//frappe.app.handle_session_expired();
				frappe.app.redirect_to_login();
			} else if (xhr.responseJSON && xhr.responseJSON._error_message) {
				frappe.msgprint({
					title: __("Not permitted"), indicator: 'red',
					message: xhr.responseJSON._error_message
				});

				xhr.responseJSON._server_messages = null;
			} else if (xhr.responseJSON && xhr.responseJSON._server_messages) {
				var _server_messages = JSON.parse(xhr.responseJSON._server_messages);

				// avoid double messages
				if (_server_messages.indexOf(__("Not permitted")) !== -1) {
					return;
				}
			} else {
				frappe.msgprint({
					title: __("Not permitted"), indicator: 'red',
					message: __('You do not have enough permissions to access this resource. Please contact your manager to get access.')
				});
			}


		},
		508: function (xhr) {
			frappe.utils.play_sound("error");
			frappe.msgprint({
				title: __('Please try again'), indicator: 'red',
				message: __("Another transaction is blocking this one. Please try again in a few seconds.")
			});
		},
		413: function (data, xhr) {
			frappe.msgprint({
				indicator: 'red', title: __('File too big'), message: __("File size exceeded the maximum allowed size of {0} MB",
					[(frappe.boot.max_file_size || 5242880) / 1048576])
			});
		},
		417: function (xhr) {
			var r = xhr.responseJSON;
			if (!r) {
				try {
					r = JSON.parse(xhr.responseText);
				} catch (e) {
					r = xhr.responseText;
				}
			}

			opts.error_callback && opts.error_callback(r);
		},
		501: function (data, xhr) {
			if (typeof data === "string") data = JSON.parse(data);
			opts.error_callback && opts.error_callback(data, xhr.responseText);
		},
		500: function (xhr) {
			frappe.utils.play_sound("error");
			try {
				opts.error_callback && opts.error_callback();
				frappe.request.report_error(xhr, opts);
			} catch (e) {
				frappe.request.report_error(xhr, opts);
			}
		},
		504: function (xhr) {
			frappe.msgprint(__("Request Timed Out"))
			opts.error_callback && opts.error_callback();
		},
		502: function (xhr) {
			frappe.msgprint(__("Internal Server Error"));
		}
	};

	var exception_handlers = {
		'QueryTimeoutError': function () {
			frappe.utils.play_sound("error");
			frappe.msgprint({
				title: __('Request Timeout'),
				indicator: 'red',
				message: __("Server was too busy to process this request. Please try again.")
			});
		},
		'QueryDeadlockError': function () {
			frappe.utils.play_sound("error");
			frappe.msgprint({
				title: __('Deadlock Occurred'),
				indicator: 'red',
				message: __("Server was too busy to process this request. Please try again.")
			});
		}
	};

	var ajax_args = {
		url: opts.url || frappe.request.url,
		data: opts.args,
		type: opts.type,
		dataType: opts.dataType || 'json',
		async: opts.async,
		headers: Object.assign({
			"X-Frappe-CSRF-Token": frappe.csrf_token,
			"Accept": "application/json",
			"X-Frappe-CMD": (opts.args && opts.args.cmd || '') || ''
		}, opts.headers),
		cache: false
	};

	if (opts.args && opts.args.doctype) {
		ajax_args.headers["X-Frappe-Doctype"] = encodeURIComponent(opts.args.doctype);
	}

	frappe.last_request = ajax_args.data;

	return $.ajax(ajax_args)
		.done(function (data, textStatus, xhr) {
			try {
				if (typeof data === "string") data = JSON.parse(data);

				// sync attached docs
				if (data.docs || data.docinfo) {
					frappe.model.sync(data);
				}

				// sync translated messages
				if (data.__messages) {
					$.extend(frappe._messages, data.__messages);
				}

				// sync link titles
				if (data._link_titles) {
					if (!frappe._link_titles) {
						frappe._link_titles = {};
					}
					$.extend(frappe._link_titles, data._link_titles);
				}

				// callbacks
				var status_code_handler = statusCode[xhr.statusCode().status];
				if (status_code_handler) {
					status_code_handler(data, xhr);
				}
			} catch (e) {
				console.log("Unable to handle success response", data); // eslint-disable-line
				console.trace(e); // eslint-disable-line
			}

		})
		.always(function (data, textStatus, xhr) {
			try {
				if (typeof data === "string") {
					data = JSON.parse(data);
				}
				if (data.responseText) {
					var xhr = data;
					data = JSON.parse(data.responseText);
				}
			} catch (e) {
				data = null;
				// pass
			}
			frappe.request.cleanup(opts, data);
			if (opts.always) {
				opts.always(data);
			}
		})
		.fail(function (xhr, textStatus) {
			try {
				if (xhr.getResponseHeader('content-type') == 'application/json' && xhr.responseText) {
					var data;
					try {
						data = JSON.parse(xhr.responseText);
					} catch (e) {
						console.log("Unable to parse reponse text");
						console.log(xhr.responseText);
						console.log(e);
					}
					if (data && data.exception) {
						// frappe.exceptions.CustomError: (1024, ...) -> CustomError
						var exception = data.exception.split('.').at(-1).split(':').at(0);
						var exception_handler = exception_handlers[exception];
						if (exception_handler) {
							exception_handler(data);
							return;
						}
					}
				}
				var status_code_handler = statusCode[xhr.statusCode().status];
				if (status_code_handler) {
					status_code_handler(xhr);
					return;
				}
				// if not handled by error handler!
				opts.error_callback && opts.error_callback(xhr);
			} catch (e) {
				console.log("Unable to handle failed response"); // eslint-disable-line
				console.trace(e); // eslint-disable-line
			}
		});
}


frappe.ui.form.States = class FormStates extends frappe.ui.form.States {
	setup_help() { }
}

frappe.breadcrumbs = Object.assign(frappe.breadcrumbs, {
	update() {
		var breadcrumbs = this.all[frappe.breadcrumbs.current_page()];

		this.clear();
		if (!breadcrumbs) return this.toggle(false);

		if (breadcrumbs.type === 'Custom') {
			this.set_custom_breadcrumbs(breadcrumbs);
		} else {
			// Home
			//this.set_home_breadcrumb(breadcrumbs)

			// workspace
			//this.set_workspace_breadcrumb(breadcrumbs);

			// form / print
			let view = frappe.get_route()[0];
			view = view ? view.toLowerCase() : null;
			if (breadcrumbs.doctype && ["print", "form"].includes(view)) {
				this.set_list_breadcrumb(breadcrumbs);
				this.set_form_breadcrumb(breadcrumbs, view);
			} else if (breadcrumbs.doctype && view === 'list') {
				this.set_list_breadcrumb(breadcrumbs);
			} else if (breadcrumbs.doctype && view == 'dashboard-view') {
				this.set_list_breadcrumb(breadcrumbs);
				this.set_dashboard_breadcrumb(breadcrumbs);
			}
		}

		this.toggle(true);
	},

	// set_home_breadcrumb(breadcrumbs) {
	// 	$(`<li><a id="home-breadcrumb" href="/app">Home</a></li>`)
	// 		.appendTo(this.$breadcrumbs);
	// }
})

// let get_user_default = frappe.defaults.get_user_default;

// frappe.defaults.get_user_default = function(key) {
// 	if (key.toLowerCase() == 'company') {
// 		return frappe.boot.company;
// 	}

// 	return get_user_default(key)
// }

// frappe.utils.fetch_link_title = function(doctype, name) {
// 	return Promise.resolve(name.replace(new RegExp(` *- *(${frappe.boot.company}|${frappe.boot.company_abbr})`), ''))
// }

// external
var tree = document.getElementById("treemenu");
if (tree) {
	tree.querySelectorAll("ul").forEach(function (el, key, parent) {
		var elm = el.parentNode;
		elm.classList.add("branch");
		var x = document.createElement("i");
		x.classList.add("indicator");
		x.classList.add("bi-chevron-down");
		elm.insertBefore(x, elm.firstChild);
		el.classList.add("collapse");

		elm.addEventListener(
			"click",
			function (event) {
				if (elm === event.target || elm === event.target.parentNode) {
					if (el.classList.contains("collapse")) {
						el.classList.add("expand");
						el.classList.remove("collapse");
						x.classList.remove("bi-chevron-down");
						x.classList.add("bi-chevron-up");
					} else {
						el.classList.add("collapse");
						el.classList.remove("expand");
						x.classList.remove("bi-chevron-up");
						x.classList.add("bi-chevron-down");
					}
				}
			},
			false
		);
	});
}


// customize javascript
$(document).ready(function () {
	if (!frappe.boot.coa_created) {
		$('div.modal.fade.show').off('click')
		let coa_tree;
		let d = new frappe.ui.Dialog({
			title: 'Chart of Account',
			fields: [
				{
					label: 'COA',
					fieldname: 'coa',
					fieldtype: 'Select',
					options: "Standard\nTally",
					default: "Standard"
				},
				{
					fieldtype: "Column Break"
				},
				{
					fieldname: 'view_coa',
					label: __('View Chart of Accounts'),
					fieldtype: 'Button',
					click: function () {
						if (d.get_value('coa')) {
							$('.tree.with-skeleton').remove()
							// charts_modal(d.get_value('coa'), d)
							let chart_template = d.get_value('coa')
							if (d.get_value('coa') == "Tally") chart_template = "Standard";

							console.log(chart_template)
							let parent = __('All Accounts');

							// render tree structure in the dialog modal
							coa_tree = new frappe.ui.Tree({
								parent: $(d.body),
								label: parent,
								expandable: true,
								method: 'erpnext.accounts.utils.get_coa',
								args: {
									chart: chart_template,
									parent: parent,
									doctype: 'Account'
								},
								onclick: function (node) {
									parent = node.value;
								}
							});

							// add class to show buttons side by side
							const form_container = $(d.body).find('form');
							const buttons = $(form_container).find('.frappe-control');
							// form_container.addClass('flex');
							// buttons.map((index, button) => {
							// $(button).css({"margin-right": "1em"});
							// })

							coa_tree.load_children(coa_tree.root_node, true);
						}
					}
				},
				{
					fieldtype: "Section Break"
				},
				{
					'fieldname': 'expand_all', 'label': __('Expand All'), 'fieldtype': 'Button',
					click: function () {
						// expand all nodes on button click
						coa_tree.load_children(coa_tree.root_node, true);
					}
				},
				{
					fieldtype: "Column Break"
				},
				{
					'fieldname': 'collapse_all', 'label': __('Collapse All'), 'fieldtype': 'Button',
					click: function () {
						// collapse all nodes
						coa_tree.get_all_nodes(coa_tree.root_node.data.value, coa_tree.root_node.is_root)
							.then(data_list => {
								data_list.map(d => { coa_tree.toggle_node(coa_tree.nodes[d.parent]); });
							});
					}
				}
			],
			size: 'large', // small, large, extra-large 
			primary_action_label: 'Create',
			primary_action(values) {
				frappe.call({
					method: 'stanch.api.create_coa',
					args: {
						coa: values.coa,
						company: frappe.defaults.get_default('company')
					},
					freeze: true,
					freeze_msg: __("Creating COA"),
					callback: function (r) {
						if (r.message) {
							frappe.call({
								method: "stanch.api.update_coa",
								freeze: true,
								freeze_msg: __("Creating COA"),
								callback: function (r) {
									if (r.message) {
										frappe.msgprint("Chart of Account Created")
										location.reload()

									}
								},
							});
						}
						else {
							frappe.msgprint("Chart of Account Creation Failed")
						}
					}
				});
				d.hide();
			}
		});
		d.no_cancel()
		d.show();
		setTimeout(() => { $('div.modal.fade.show').off('click') }, 1000)

	}
	frappe.realtime.on("roles_and_permission_updated", (data) => {
		frappe.msgprint({
			title: __('Notification'),
			message: __('Your roles and permission have been updated kindly refresh'),
			primary_action: {
				label: 'Refresh',
				action(values) {
					frappe.ui.toolbar.clear_cache()
				}
			}
		});
	}),
		frappe.realtime.on("update_current_company", (data) => {
			frappe.msgprint({
				title: __('Notification'),
				message: __('Default company has been modified, please refresh'),
				primary_action: {
					label: 'Refresh',
					action(values) {
						frappe.ui.toolbar.clear_cache()
					}
				}
			});
		})
	localStorage.container_fullwidth = true;
	frappe.ui.toolbar.set_fullwidth_if_enabled();
	$(document.body).trigger("toggleFullWidth");

	// var navbar = document.getElementsByClassName("container")
	// for (let index = 0; index < navbar.length; index++) {
	// 	navbar[index].style.width = "100%"
	// }
	// for(var i=0; i<document.getElementById("body").getElementsByClassName("container").length;i++){
	// 	document.getElementById("body").getElementsByClassName("container")[i].style.width = "100%"
	// }
	// const body = document.getElementById("body").getElementsByClassName("container")
	// document.getElementById("body").getElementsByClassName("container")[0].style.width = "100%";
	// for (let index = 0; index < 3; index++) {
	// 	body[index].style.width = "100%"
	// }
	if (frappe.session.user == "Guest") {
		window.location.replace(location.origin)
	}
	// $(document).on("keydown", ".input-with-feedback", function(event) {
	// 	allInputs = $('.input-with-feedback:visible');
	// 	if (event.keyCode == 13) {
	// 		event.preventDefault();
	// 		var nextInput = allInputs.get(allInputs.index(this) + 1);
	// 		if (nextInput) {
	// 			nextInput.focus();
	// 		}
	// 	}
	// });
	let doctypes = [
		{ name: "Gear Icon", id: "gear-icon", has_permission: 1, is_report: 0 },

		{ name: "Customer", id: "customer-list", has_permission: 1, is_report: 0 },
		{ name: "Quotation", id: "quotation", has_permission: 1, is_report: 0 },
		{ name: "Sales Order", id: "sales-order", has_permission: 1, is_report: 0 },
		{ name: "Sales Invoice", id: "sales-invoice", has_permission: 1, is_report: 0 },
		{ name: "Sales Invoice", id: "credit-note", has_permission: 1, is_report: 0 },
		{ name: "Bank Reconciliation Tool", id: "bank-reconciliation", has_permission: 1, is_report: 0 },
		{ name: "Delivery Note", id: "delivery-note", has_permission: 1, is_report: 0 },

		{ name: "Supplier", id: "supplier-list", has_permission: 1, is_report: 0 },
		{ name: "Material Request", id: "material-request", has_permission: 1, is_report: 0 },
		{ name: "Request for Quotation", id: "request-for-quotation", has_permission: 1, is_report: 0 },
		{ name: "Purchase Order", id: "purchase-order", has_permission: 1, is_report: 0 },
		{ name: "Purchase Receipt", id: "purchase-receipt", has_permission: 1, is_report: 0 },
		{ name: "Purchase Invoice", id: "purchase-invoice", has_permission: 1, is_report: 0 },
		{ name: "Purchase Invoice", id: "debit-note", has_permission: 1, is_report: 0 },
		{ name: "Supplier Advance - Non PO", id: "supplier-advance-nonpo", has_permission: 1, is_report: 0 },

		{ name: "Expense Claim", id: "expense-claim", has_permission: 1, is_report: 0 },
		{ name: "Employee Advance", id: "employee-advances", has_permission: 1, is_report: 0 },
		{ name: "ToDo", id: "todo", has_permission: 1, is_report: 0 },
		{ name: "Project", id: "project", has_permission: 1, is_report: 0 },
		//   {name:"Employee",id:"employee-list", has_permission:1, is_report:0},
		{ name: "Task", id: "task", has_permission: 1, is_report: 0 },
		{ name: "Timesheet", id: "timesheet", has_permission: 1, is_report: 0 },

		{ name: "Payment Entry", id: "payment-entry", has_permission: 1, is_report: 0 },
		{ name: "Journal Entry", id: "journal-entry", has_permission: 1, is_report: 0 },
		{ name: "Stock Entry", id: "stock-entry", has_permission: 1, is_report: 0 },
		{ name: "Payment Order", id: "payment-order", has_permission: 1, is_report: 0 },
		{ name: "Stock Reconciliation", id: "stock-reconciliation", has_permission: 1, is_report: 0 },
		{ name: "Payment Reconciliation", id: "payment-reconciliation", has_permission: 1, is_report: 0 },
		{ name: "Auto Repeat", id: "auto-repeat", has_permission: 1, is_report: 0 },

		{ name: "Trial Balance", doctype: "GL Entry", id: "trial-balance", has_permission: 1, is_report: 1 },
		{ name: "Accounts Receivable", doctype: "Sales Invoice", id: "accounts-receivable", has_permission: 1, is_report: 1 },
		{ name: "Accounts Payable", doctype: "Purchase Invoice", id: "accounts-payable", has_permission: 1, is_report: 1 },
		{ name: "Asset", id: "asset-register", has_permission: 1, is_report: 1, is_report_builder: 1, doctype: "Asset" },
		{ name: "General Ledger", doctype: "GL Entry", id: "general-ledger", has_permission: 1, is_report: 1 },
		{ name: "Payment Ledger", doctype: "Payment Ledger Entry", id: "payment-ledger", has_permission: 1, is_report: 1 },
		{ name: "Profit and Loss Statement", doctype: "GL Entry", id: "profit-loss", has_permission: 1, is_report: 1 },
		{ name: "Profitability Analysis", doctype: "GL Entry", id: "profitability-analysis", has_permission: 1, is_report: 1 },
		{ name: "Balance Sheet", doctype: "GL Entry", id: "balance-sheet", has_permission: 1, is_report: 1 },
		{ name: "Cash Flow", doctype: "Sales Invoice", id: "cashflow", has_permission: 1, is_report: 1 },
		{ name: "Consolidated Financials", doctype: "Account", id: "consolidated-financials", has_permission: 1, is_report: 1 },
		{ name: "Stock", id: "stock-dashboard", has_permission: 1, is_report: 0, is_dashboard: 1, is_accountant_only: 1 },
		{ name: "Stock Analytics", doctype: "Stock Entry", id: "stock-analytics", has_permission: 1, is_report: 1 },
		{ name: "Stock Ledger", doctype: "Stock Ledger Entry", id: "stock-ledger", has_permission: 1, is_report: 1 },
		{ name: "stock-balance", id: "stock-summary", has_permission: 1, is_report: 0, is_page: 1 },
		{ name: "Customer Ledger Summary", doctype: "Sales Invoice", id: "customer-ledgers", has_permission: 1, is_report: 1 },
		{ name: "Sales Register", doctype: "Sales Invoice", id: "sales-register", has_permission: 1, is_report: 1 },
		{ name: "Sales Analytics", doctype: "Sales Order", id: "sales-analytics", has_permission: 1, is_report: 1 },
		{ name: "Sales Invoice Trends", doctype: "Sales Invoice", id: "sales-invoice-trend", has_permission: 1, is_report: 1 },
		{ name: "Supplier Ledger Summary", doctype: "Purchase Invoice", id: "supplier-ledgers", has_permission: 1, is_report: 1 },
		{ name: "Purchase Register", doctype: "Purchase Invoice", id: "purchase-register", has_permission: 1, is_report: 1 },
		{ name: "Purchase Analytics", doctype: "Purchase Order", id: "purchase-analutics", has_permission: 1, is_report: 1 },
		{ name: "Purchase Invoice Trends", doctype: "Purchase Invoice", id: "purchase-invoice-trend", has_permission: 1, is_report: 1 },
		{ name: "Employee Billing Summary", doctype: "Timesheet", id: "billing-summary", has_permission: 1, is_report: 1 },
		{ name: "Employee Advance Summary", doctype: "Employee Advance", id: "advance-summary", has_permission: 1, is_report: 1 },
		{ name: "Expense Claim Report", id: "expense-claim-report", has_permission: 1, is_report: 1, is_report_builder: 1, doctype: "Expense Claim" },
		{ name: "Project Report", id: "project-report", has_permission: 1, is_report: 1, is_report_builder: 1, doctype: "Project" },
		{ name: "Task Report", id: "task-report", has_permission: 1, is_report: 1, is_report_builder: 1, doctype: "Task" },
		{ name: "Timesheet Report", id: "timesheet-report", has_permission: 1, is_report: 1, is_report_builder: 1, doctype: "Timesheet" },
		{ name: "Bank Transaction Report", id: "bank-transaction-report", has_permission: 1, is_report: 1, is_report_builder: 1, doctype: "Bank Transaction" },
		{ name: "Bank Reconciliation Statement", doctype: "Journal Entry", id: "bank-reconciliation-statement", has_permission: 1, is_report: 1 },
		{ name: "GST India", id: "gst-reports", has_permission: 1, is_report: 0, is_dashboard: 1, is_accountant_only: 1 },
		{ name: "Tax Withholding Category", id: "withholding-taxes", has_permission: 1, is_report: 1, is_report_builder: 1, doctype: "Tax Withholding Category" },
		{ name: "my-space", id: "my_space", has_permission: 1, is_report: 0, is_page: 1 },
		{ name: "finance-dashboard", id: "finance_dashboard", has_permission: 1, is_report: 0, is_page: 1 },
		{ name: "Accounts", id: "accounts_dashboard", has_permission: 1, is_report: 0, is_dashboard: 1, is_accountant_only: 1 },
		//   {name:"diy", id:"diy", has_permission:1, is_report:0, is_page:1},

		{ name: "roles-permissions", id: "roles-and-permissions", has_permission: 1, is_report: 0, is_page: 1, for_company_admin: 1 },
		{ name: "upload-employees", id: "import-employees", has_permission: 1, is_report: 0, is_page: 1, for_company_admin: 1 },
		{ name: "Company", id: "company-info", has_permission: 1, is_report: 0, for_company_admin: 1 },
		{ name: "User", id: "user-list", has_permission: 1, is_report: 0, for_company_admin: 1 },
		{ name: "Employee", id: "employees-list", has_permission: 1, is_report: 0, for_company_admin: 1 },
		{ name: "GST Settings", id: "gst-settings", has_permission: 1, is_report: 0, for_company_admin: 1 },
	]

	for (let doc of doctypes) {
		if (frappe.boot.enable_full_feature || frappe.session.user == "Administrator") {
			if ('for_company_admin' in doc && doc.for_company_admin && !has_common(["Company Administrator"], frappe.user_roles)) {
				$("#" + doc.id).addClass("disable-click");
				$("#" + doc.id).css('color', "#9a9a9a");
				continue
			}

			if (doc.id == "debit-note" && !["Purchase User", "Purchase Approver", "Accounts User", "Accounts Manager", "Payments Manager"].some(role => frappe.user_roles.includes(role))) {
				$("#" + doc.id).addClass("disable-click");
				$("#" + doc.id).css('color', "#9a9a9a");
				continue
			}

			if (doc.id == "gear-icon") {
				if (!["Accounts User", "Accounts Manager", "Company Administrator"].some(role => frappe.user_roles.includes(role))) {
					$("#" + doc.id).addClass("disable-click");
					$("#" + doc.id).css('color', "#9a9a9a");
				}
				continue
			}

			if (doc.is_report == 0 && !('is_dashboard' in doc) && !('is_page' in doc)) {
				if (!frappe.model.can_read(doc.name)) {
					$("#" + doc.id).addClass("disable-click");
					$("#" + doc.id).css('color', "#9a9a9a");
				}
			}
			else if (doc.is_report == 0 && 'is_page' in doc && doc.is_page) {
				if (!frappe.boot.allowed_pages.includes(doc.name)) {
					$("#" + doc.id).addClass("disable-click");
					$("#" + doc.id).css('color', "#9a9a9a");
				}
			}
			else if (doc.is_report == 1) {
				if (!frappe.model.can_get_report(doc.doctype)) {
					$("#" + doc.id).addClass("disable-click");
					$("#" + doc.id).css('color', "#9a9a9a");
				}
			}
			else if ('is_accountant_only' in doc && doc.is_accountant_only) {
				if (!has_common(["Accounts User", "Accounts Manager"], frappe.user_roles)) {
					$("#" + doc.id).addClass("disable-click");
					$("#" + doc.id).css('color', "#9a9a9a");
				}
			}
		}
		else {
			$("#" + doc.id).addClass("disable-click");
			$("#" + doc.id).css('color', "#9a9a9a");
		}

		// $('head').append('<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>');

		// var aCheckinout = `
		// <script>

		// aCheckinout();
		// </script>
		// `

		// $('head').append(aCheckinout);
	}
	if (frappe.boot.redirect_to_url && !window.location.href.includes(frappe.boot.redirect_to_url)) {
		window.location.href = frappe.boot.redirect_to_url;
	}
	// $('.navbar.gpnav_bottom .navbar-nav > li.dropdown').hover(function () {
	//     $('ul.dropdown-menu', this).stop(true, false).slideDown('fast');
	//     $(this).addClass('show');
	// }, function () {
	//     $('ul.dropdown-menu', this).stop(true, false).slideUp('fast');
	//     $(this).removeClass('show');
	// });

	// $("li.nav-item").on('click', function (e) {
	//     console.log(e.target, new Date().toString());
	//     $("li.nav-item.active").removeClass('active');
	//     $(this).addClass('active');
	// });

	$('.navbar-nav.toprightmenu .dropdown,.navbar.gpnav_bottom .navbar-nav > li.dropdown').on('click', function (e) {

		var target = $(e.target);
		var dropdown = target.closest('.dropdown-menu');
		if (target.hasClass('show')) {
			$(dropdown).addClass('show');
		} else {
			$(dropdown).removeClass('show');
		}
	});

	// oneclick
	$('a.masterscrolllink').bind('click', function (event) {
		var $anchor = $(this);
		$('html, body').stop().animate({
			scrollTop: $($anchor.attr('href')).offset().top
		}, 1500, 'easeInOutExpo');
		event.preventDefault();
	});

	// <option value="Survey Sparrow">Survey Sparrow</option>
	// frappe.call({
	// 	method: "frappe.client.get_list",
	// 	args:{
	// 		"doctype": "Company"
	// 	},
	// 	callback:function(res){
	// 		if(!res.exc){
	// 			res.message.forEach(company => {
	// 				$('[data-fieldname="default_company"]').append(`<option value='${company.name}'>${company.name.length > 35?company.name.substring(0,35)+'...':company.name}</div>`)
	// 			})
	// 		}
	// 		frappe.call({
	// 			method: "frappe.core.doctype.session_default_settings.session_default_settings.get_session_default_values",
	// 			callback: function (data) {
	// 				fields = JSON.parse(data.message);
	// 				fields.forEach(field => {
	// 					if(field.fieldname == "company")
	// 						if(field.default){
	// 							$('option[value="'+ field.default +'"]').attr('selected', true)
	// 						}else{
	// 							frappe.msgprint("Select Default Company!")
	// 						}
	// 				})
	// 			}
	// 		})
	// 	}
	// })
	frappe.call({
		method: "frappe.client.get_list",
		args: {
			"doctype": "Company"
		},
		callback: function (res) {
			if (!res.exc) {
				if (res.message.length === 1) {
					// If only one company is returned, change the field type
					$('[data-fieldname="default_company"]').replaceWith(`<div class="frappe-control input-max-width" data-fieldtype="Data" data-fieldname="default_company" title="current_company">
						<input type="text" class="input-with-feedback form-control ellipsis" value="${res.message[0].name}" style="min-width: 240px;max-width: 240px;border-radius: 3px !important;line-height: 1;padding: 0 23px 0 4px!important;height: 28px;background: #fff;margin-left: 15px;" disabled>
						</div>`);
				} else {
					// If more than one company is returned, keep the field type as select
					res.message.forEach(company => {
						$('[data-fieldname="default_company"]').append(`<option value='${company.name}'>${company.name.length > 35 ? company.name.substring(0, 35) + '...' : company.name}</option>`);
					});
				}
			}
			frappe.call({
				method: "frappe.core.doctype.session_default_settings.session_default_settings.get_session_default_values",
				callback: function (data) {
					fields = JSON.parse(data.message);
					fields.forEach(field => {
						if (field.fieldname == "company")
							if (field.default) {
								$('option[value="' + field.default + '"]').attr('selected', true)
							} else {
								frappe.msgprint("Select Default Company!")
							}
					});
				}
			});
		}
	});



	$('[data-fieldname="default_company"]').on("change", (e) => {
		let default_company = e.currentTarget.value;
		if (default_company == "Select Company") {
			return
		}
		frappe.call({
			method: "stanch.api.set_session_default_values",
			args: {
				default_values: {
					"company": default_company,
					"undefined": "",
					"settings": ""
				},
			},
			callback: function (data) {
				if (data.message == "success") {
					frappe.show_alert({
						message: __("Current Company Saved"),
						indicator: "green",
					});
					window.history.replaceState({}, document.title, window.location.pathname);
					frappe.ui.toolbar.clear_cache();
				} else {
					frappe.show_alert({
						message: __(
							"An error occurred while setting company"
						),
						indicator: "red",
					});
				}
			},
		});
	})

});

frappe.views.ReportView = class ReportView extends frappe.views.ReportView {
	after_render() {
		super.after_render()
		if (cur_page.page.list_view.page.fields_dict.company && cur_page.page.list_view.page.fields_dict.company.get_input_value() != frappe.defaults.get_user_default("Company") && !frappe.ignore_company) {
			cur_page.page.list_view.page.fields_dict.company.set_input(frappe.defaults.get_user_default("Company"))
			cur_page.page.list_view.refresh()
		}
	}
	setup_datatable(values) {
		this.$datatable_wrapper.empty();
		for (var i = 0; i < this.columns.length; i++) {
			this.columns[i]["docfield"]["read_only"] = 1
		}
		this.datatable = new DataTable(this.$datatable_wrapper[0], {
			columns: this.columns,
			data: this.get_data(values),
			getEditor: this.get_editing_object.bind(this),
			language: frappe.boot.lang,
			translations: frappe.utils.datatable.get_translations(),
			checkboxColumn: true,
			inlineFilters: true,
			cellHeight: 35,
			direction: frappe.utils.is_rtl() ? "rtl" : "ltr",
			events: {
				onRemoveColumn: (column) => {
					this.remove_column_from_datatable(column);
				},
				onSwitchColumn: (column1, column2) => {
					this.switch_column(column1, column2);
				},
				onCheckRow: () => {
					const checked_items = this.get_checked_items();
					this.toggle_actions_menu_button(checked_items.length > 0);
				},
			},
			hooks: {
				columnTotal: frappe.utils.report_column_total,
			},
			headerDropdown: [
				{
					label: __("Add Column"),
					action: (datatabe_col) => {
						let columns_in_picker = [];
						const columns = this.get_columns_for_picker();

						columns_in_picker = columns[this.doctype]
							.filter((df) => !this.is_column_added(df))
							.map((df) => ({
								label: __(df.label),
								value: df.fieldname,
							}));

						delete columns[this.doctype];

						for (let cdt in columns) {
							columns[cdt]
								.filter((df) => !this.is_column_added(df))
								.map((df) => ({
									label: __(df.label) + ` (${cdt})`,
									value: df.fieldname + "," + cdt,
								}))
								.forEach((df) => columns_in_picker.push(df));
						}

						const d = new frappe.ui.Dialog({
							title: __("Add Column"),
							fields: [
								{
									label: __("Select Column"),
									fieldname: "column",
									fieldtype: "Autocomplete",
									options: columns_in_picker,
								},
								{
									label: __("Insert Column Before {0}", [
										__(datatabe_col.docfield.label).bold(),
									]),
									fieldname: "insert_before",
									fieldtype: "Check",
								},
							],
							primary_action: ({ column, insert_before }) => {
								if (!columns_in_picker.map((col) => col.value).includes(column)) {
									frappe.show_alert({
										message: __("Invalid column"),
										indicator: "orange",
									});
									d.hide();
									return;
								}

								let doctype = this.doctype;
								if (column.includes(",")) {
									[column, doctype] = column.split(",");
								}

								let index = datatabe_col.colIndex;
								if (insert_before) {
									index = index - 1;
								}

								this.add_column_to_datatable(column, doctype, index);
								d.hide();
							},
						});

						d.show();
					},
				},
			],
		});
	}
}

frappe.views.ListView = class ListView extends frappe.views.ListView {
	refresh() {
		super.refresh()
		if (cur_page.page.list_view.page.fields_dict.company && cur_page.page.list_view.page.fields_dict.company.get_input_value() != frappe.defaults.get_user_default("Company") && !frappe.ignore_company) {
			cur_page.page.list_view.page.fields_dict.company.set_input(frappe.defaults.get_user_default("Company"))
		}
		// if (cur_page?.page?.list_view?.doctype) {
		// 	$(".form-tabs-list[doctype!='"+cur_page.page.list_view.doctype+"']").remove()
		// }


		if (!$("[doctype='" + cur_list.doctype + "']").length && cur_page.page.list_view.page.fields_dict.workflow_state && (!["Attendance Request","Leave Application"].includes(cur_list.doctype))) {
			cur_page.page.list_view.page.fields_dict.workflow_state.set_input(cur_page.page.list_view.page.fields_dict.workflow_state.value || '');
			// cur_page.page.list_view.refresh();

			frappe.call({
				method: "stanch.api.get_workflow_states",
				args: { doctype: cur_list.doctype },
				callback: function (res) {
					if (res.exc) {
						return
					}
					if (res.message.length) {
						let tabs = `<li class="nav-item">
										<a class="nav-link" id="state-tab" data-toggle="tab" data-value="All" data-fieldname="user_enabled_tab" role="tab" aria-controls="User Enabled" aria-selected="true">
											All
										</a>
									</li>`;
						res.message.forEach((state) => {
							tabs += `<li class="nav-item">
										<a class="nav-link" id="state-tab" data-toggle="tab" data-value="${state}" data-fieldname="user_archived_tab" role="tab" aria-controls="User Archived" aria-selected="true">
											${state}
										</a>
									</li>`
						})
						$(".form-tabs-list").remove()
						$(".col.layout-main-section-wrapper>.layout-main-section.frappe-card").prepend(
							`<div class="form-tabs-list" doctype="${cur_list.doctype}">
								<ul class="nav form-tabs" id="form-tabs" role="tablist">
									${tabs}
								</ul>
							</div>`
						);
						$('.form-tabs .nav-link.active').removeClass('active')
						$(`[data-value="${cur_page.page.list_view.page.fields_dict.workflow_state.value != '' ? cur_page.page.list_view.page.fields_dict.workflow_state.value : 'All'}"]`).addClass('active')
						$('div').find(`[data-fieldname="workflow_state"]`).hide();
						$("a#state-tab.nav-link").click((e) => {

							let state = e.target.dataset.value;
							if (state == 'All') {
								state = ''
							}
							if (cur_page.page.list_view.page.fields_dict.workflow_state) {
								cur_page.page.list_view.page.fields_dict.workflow_state.set_input(state);
								cur_page.page.list_view.refresh();
							}
						});
					}
				}
			})
		} else {
			if (cur_list.doctype == 'Address') {
				frappe.call({
					method: "stanch.after_migrate.get_meta_of_address",
					args: { doctype: cur_list.doctype },
					callback: function (res) {
						if (res.exc) {
							return
						}
						if (res.message.length) {
							let tabs = `<li class="nav-item">
											<a class="nav-link" id="state-tab" data-toggle="tab" data-value="All" data-fieldname="user_enabled_tab" role="tab" aria-controls="User Enabled" aria-selected="true">
												All
											</a>
										</li>`;
							res.message.forEach((state) => {
								tabs += `<li class="nav-item">
											<a class="nav-link" id="state-tab" data-toggle="tab" data-value="${state}" data-fieldname="user_archived_tab" role="tab" aria-controls="User Archived" aria-selected="true">
												${state}
											</a>
										</li>`
							})
							$(".form-tabs-list").remove()
							$(".col.layout-main-section-wrapper>.layout-main-section.frappe-card").prepend(
								`<div class="form-tabs-list" doctype="${cur_list.doctype}">
									<ul class="nav form-tabs" id="form-tabs" role="tablist">
										${tabs}
									</ul>
								</div>`
							);
							$('.form-tabs .nav-link.active').removeClass('active')
							$(`[data-value="${cur_page.page.list_view.page.fields_dict.address_tag.value != '' ? cur_page.page.list_view.page.fields_dict.address_tag.value : 'All'}"]`).addClass('active')
							$('div').find(`[data-fieldname="address_tag"]`).hide();
							$("a#state-tab.nav-link").click((e) => {

								let state = e.target.dataset.value;
								if (state == 'All') {
									state = ''
								}
								if (cur_page.page.list_view.page.fields_dict.address_tag) {
									cur_page.page.list_view.page.fields_dict.address_tag.set_input(state);
									cur_page.page.list_view.refresh();
								}
							});
						}
					}
				})

			}
			if (cur_list.doctype == 'Item') {
				frappe.call({
					method: "stanch.after_migrate.get_item_group",
					args: { doctype: cur_list.doctype },
					callback: function (res) {
						if (res.exc) {
							return;
						}
						if (res.message.length) {
							setTimeout(() => {
								// Inject updated CSS dynamically
								const css = `
									.scrollable-tabs {
										display: flex;
										flex-wrap: nowrap;
										overflow-x: auto;
										overflow-y: hidden;
										max-height: 60px;
										border: 1px solid #ccc;
										padding: 5px;
										white-space: nowrap;
										scroll-behavior: smooth;
									}
									.scrollable-tabs .nav-item {
										flex: 0 0 auto;
										margin-right: 10px;
									}
									/* Hide the scrollbar but keep the scroll functionality */
									.scrollable-tabs::-webkit-scrollbar {
										display: none;
									}
									.scrollable-tabs {
										-ms-overflow-style: none;  /* for IE and Edge */
										scrollbar-width: none;  /* for Firefox */
									}
								`;
								const styleSheet = document.createElement("style");
								styleSheet.type = "text/css";
								styleSheet.innerText = css;
								document.head.appendChild(styleSheet);
			
								let tabs = `<li class="nav-item">
												<a class="nav-link" id="state-tab" data-toggle="tab" data-value="All" data-fieldname="user_enabled_tab" role="tab" aria-controls="User Enabled" aria-selected="true">
													All
												</a>
											</li>`;
								res.message.forEach((state) => {
									tabs += `<li class="nav-item">
												<a class="nav-link" id="state-tab" data-toggle="tab" data-value="${state}" data-fieldname="user_archived_tab" role="tab" aria-controls="User Archived" aria-selected="true">
													${state}
												</a>
											</li>`;
								});
			
								$(".form-tabs-list").remove();
								$(".col.layout-main-section-wrapper>.layout-main-section.frappe-card").prepend(
									`<div class="form-tabs-list">
										<ul class="nav form-tabs scrollable-tabs" id="form-tabs" role="tablist">
											${tabs}
										</ul>
									</div>`
								);
			
								$('.form-tabs .nav-link.active').removeClass('active');
								$(`[data-value="${cur_page.page.list_view.page.fields_dict.item_group.value != '' ? cur_page.page.list_view.page.fields_dict.item_group.value : 'All'}"]`).addClass('active');
								$('div').find(`[data-fieldname="item_group"]`).hide();
			
								$("a#state-tab.nav-link").click((e) => {
									let state = e.target.dataset.value;
									if (state == 'All') {
										state = '';
									}
									if (cur_page.page.list_view.page.fields_dict.item_group) {
										cur_page.page.list_view.page.fields_dict.item_group.set_input(state);
										cur_page.page.list_view.refresh();
									}
								});
							}, 1200);
						}
					}
				});
			}
			if (cur_list.doctype == 'Attendance Request' || cur_list.doctype == 'Leave Application') {
				let status_list = ["In-Progress","Approved","Rejected","Cancelled"]
				let tabs = `<li class="nav-item">
								<a class="nav-link" id="state-tab" data-toggle="tab" data-value="All" data-fieldname="user_enabled_tab" role="tab" aria-controls="User Enabled" aria-selected="true">
									All
								</a>
							</li>`;
				status_list.forEach((state) => {
					tabs += `<li class="nav-item">
								<a class="nav-link" id="state-tab" data-toggle="tab" data-value="${state}" data-fieldname="user_archived_tab" role="tab" aria-controls="User Archived" aria-selected="true">
									${state}
								</a>
							</li>`
				})
				$(".form-tabs-list").remove()
				$(".col.layout-main-section-wrapper>.layout-main-section.frappe-card").prepend(
					`<div class="form-tabs-list" doctype="${cur_list.doctype}">
						<ul class="nav form-tabs" id="form-tabs" role="tablist">
							${tabs}
						</ul>
					</div>`
				);
				$('.form-tabs .nav-link.active').removeClass('active')
				$(`[data-value="${cur_page.page.list_view.page.fields_dict.current_status.value != '' ? cur_page.page.list_view.page.fields_dict.current_status.value : 'All'}"]`).addClass('active')
				$('div').find(`[data-fieldname="current_status"]`).hide();
				$("a#state-tab.nav-link").click((e) => {
					let state = e.target.dataset.value;
					if (state == 'All') {
						state = ''
					}
					if (cur_page.page.list_view.page.fields_dict.current_status) {
						cur_page.page.list_view.page.fields_dict.current_status.set_input(state);
						cur_page.page.list_view.refresh();
					}
				});

			}
			else if (cur_list.doctype == 'Job Applicant') {
				let status_list = ["Qualified","Disqualified"]
				let tabs = `<li class="nav-item">
								<a class="nav-link" id="state-tab" data-toggle="tab" data-value="All" data-fieldname="user_enabled_tab" role="tab" aria-controls="User Enabled" aria-selected="true">
									All
								</a>
							</li>`;
				status_list.forEach((state) => {
					tabs += `<li class="nav-item">
								<a class="nav-link" id="state-tab" data-toggle="tab" data-value="${state}" data-fieldname="user_archived_tab" role="tab" aria-controls="User Archived" aria-selected="true">
									${state}
								</a>
							</li>`
				})
				$(".form-tabs-list").remove()
				$(".col.layout-main-section-wrapper>.layout-main-section.frappe-card").prepend(
					`<div class="form-tabs-list" doctype="${cur_list.doctype}">
						<ul class="nav form-tabs" id="form-tabs" role="tablist">
							${tabs}
						</ul>
					</div>`
				);
				$('.form-tabs .nav-link.active').removeClass('active')
				$(`[data-value="${cur_page.page.list_view.page.fields_dict.qualifying_status.value != '' ? cur_page.page.list_view.page.fields_dict.qualifying_status.value : 'All'}"]`).addClass('active')
				$('div').find(`[data-fieldname="qualifying_status"]`).hide();
				$("a#state-tab.nav-link").click((e) => {
					let state = e.target.dataset.value;
					if (state == 'All') {
						state = ''
					}
					if (cur_page.page.list_view.page.fields_dict.qualifying_status) {
						cur_page.page.list_view.page.fields_dict.qualifying_status.set_input(state);
						cur_page.page.list_view.refresh();
					}
				});

			}
			else {
				$('.form-tabs .nav-link.active').removeClass('active')
				$(`[data-value="${cur_page.page.list_view.page.fields_dict.workflow_state?.value != '' ? cur_page.page.list_view.page.fields_dict.workflow_state?.value : 'All'}"]`).addClass('active')
			}
		}
		$("[data-fieldtype='Date']").focus(function () { $(this).select(); });
		// cur_page.page.list_view.page.fields_dict.company.$wrapper.hide()
		setTimeout(cur_list.after_refresh(), 1000)
	}

	get_column_html(col, doc) {
		if (col.type === "Status") {
			return `
				<div class="list-row-col hidden-xs ellipsis">
					${this.get_indicator_html(doc)}
				</div>
			`;
		}
		if (col.type === "Working Hours") {
			return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :center">
					${frappe.utils.escape_html(doc.working_hours)}
				</div>
			`;
		}
		if (col.type === "Original Status") {
			return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :Left">
					${frappe.utils.escape_html(doc.original_status)}
				</div>
			`;
		}
		if (col.type === "Number Of Employees") {
			return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :center">
					${frappe.utils.escape_html(doc.number_of_employees)}
				</div>
			`;
		}
		if (col.type === "Due Date") {
			return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :left">
					${frappe.utils.escape_html(doc.due_date)}
				</div>
			`;
		}
		if (col.type === "Frequency") {
			return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :left">
					${frappe.utils.escape_html(doc.task_frequency)}
				</div>
			`;
		}
		if (col.type === "Start Date") {
			if (doc.start_date) {
				return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :left">
					${frappe.utils.escape_html(moment(doc.start_date).format("DD-MM-YYYY"),)}
				</div>
			`;
			} else {
				return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :left">
					${frappe.utils.escape_html(" ")}
				</div>
			`;
			}
		}

		if (col.type === "Total Working Hours") {
			return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :left">
					${frappe.utils.escape_html(doc.total_hours)}
				</div>
			`;
		}
		if (col.type === "Expected Start Date") {
			if (doc.expected_start_date) {
				return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :left">
					${frappe.utils.escape_html(moment(doc.expected_start_date).format("DD-MM-YYYY"),)}
				</div>
			`;
			} else {
				return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :left">
					${frappe.utils.escape_html(" ")}
				</div>
			`;
			}
		}
		if (col.type === "Expected End Date") {
			if (doc.expected_end_date) {
				return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :left">
					${frappe.utils.escape_html(moment(doc.expected_end_date).format("DD-MM-YYYY"),)}
				</div>
			`;
			} else {
				return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :left">
					${frappe.utils.escape_html(" ")}
				</div>
			`;
			}
		}

		if (col.type === "Task Name") {
			return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :left">
					${frappe.utils.escape_html(doc.task_name)}
				</div>
			`;
		}
		if (col.type === "Compliance Structure") {
			return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :left">
					${frappe.utils.escape_html(doc.compliance_structure)}
				</div>
			`;
		}
		if (col.type === "Parent Name") {
			return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :left">
					${frappe.utils.escape_html(doc.parent_name)}
				</div>
			`;
		}
		if (col.type === "Compliance Name") {
			return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :left">
					${frappe.utils.escape_html(doc.select_the_compliance)}
				</div>
			`;
		}
		if (cur_page.page.list_view.doctype === "Compliance Tasks") {
			if (col.type === "Frequency") {

				return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :left">
					${frappe.utils.escape_html(doc.frequency)}
				</div>
			`;
			}

		}
		if (col.type === "Effective From") {
			if (doc.effective_from) {
				return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :left">
					${frappe.utils.escape_html(moment(doc.effective_from).format("DD-MM-YYYY"),)}
				</div>
			`;
			} else {
				return `
				<div class="list-row-col hidden-xs ellipsis" style= "text-align :left">
					${frappe.utils.escape_html(" ")}
				</div>
			`;
			}

		}
		if (col.type === "Tag") {
			const tags_display_class = !this.tags_shown ? 'hide' : '';
			let tags_html = doc._user_tags ? this.get_tags_html(doc._user_tags, 2) : '<div class="tags-empty">-</div>';
			return `
				<div class="list-row-col tag-col ${tags_display_class} hidden-xs ellipsis">
					${tags_html}
				</div>
			`;
		}

		

		const df = col.df || {};
		const label = df.label;
		const fieldname = df.fieldname;
		const value = doc[fieldname] || "";

		const format = () => {
			if (df.fieldtype === "Code") {
				return value;
			} else if (df.fieldtype === "Percent") {
				return `<div class="progress" style="margin: 0px;">
						<div class="progress-bar progress-bar-success" role="progressbar"
							aria-valuenow="${value}"
							aria-valuemin="0" aria-valuemax="100" style="width: ${Math.round(value)}%;">
						</div>
					</div>`;
			} else {
				return frappe.format(value, df, null, doc);
			}
		};

		const field_html = () => {
			let html;
			let _value;
			let strip_html_required =
				df.fieldtype == "Text Editor" ||
				(df.fetch_from &&
					["Text", "Small Text"].includes(df.fieldtype));

			if (strip_html_required) {
				_value = strip_html(value);
			} else {
				_value =
					typeof value === "string"
						? frappe.utils.escape_html(value)
						: value;
			}

			if (df.fieldtype === "Rating") {
				let out_of_ratings = df.options || 5;
				_value = _value * out_of_ratings;
			}

			if (df.fieldtype === "Image") {
				html = df.options ? `<img src="${doc[df.options]}"
					style="max-height: 30px; max-width: 100%;">`
					: `<div class="missing-image small">
						${frappe.utils.icon('restriction')}
					</div>`;
			} else if (df.fieldtype === "Select") {
				html = `<span class="filterable indicator-pill ${frappe.utils.guess_colour(
					_value
				)} ellipsis"
					data-filter="${fieldname},=,${value}">
					<a class="ellipsis" href="${this.get_form_link(doc)}"> ${__(_value)} </a>
				</span>`;
			} else if (df.fieldtype === "Link") {
				html = `<a class="ellipsis" href="${this.get_form_link(doc)}"
					data-filter="${fieldname},=,${value}">
					${_value}
				</a>`;
			} else if (
				["Text Editor", "Text", "Small Text", "HTML Editor", "Markdown Editor"].includes(
					df.fieldtype
				)
			) {
				html = `<span class="ellipsis">
				<a href="${this.get_form_link(doc)}"> ${_value} </a>
				</span>`;
			} else {
				if (this.doctype == 'Variable Pay Allocation') {
					if (fieldname == 'current_status' && value == 'Draft') {
						html = `<a class="indicator-pill red filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'In-Progress') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Approved') {
						html = `<a class="indicator-pill green filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Cancelled') {
						html = `<a class="indicator-pill pink filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Rejected') {
						html = `<a class="indicator-pill orange filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status') {
						html = `<a class="indicator-pill blue filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else {
						html = `<a class="filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
				}
				else if (this.doctype == 'Leave Application') {
					if (fieldname == 'current_status' && value == 'Draft') {
						html = `<a class="indicator-pill red filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'In-Progress') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Approved') {
						html = `<a class="indicator-pill green filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Cancelled') {
						html = `<a class="indicator-pill pink filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Rejected') {
						html = `<a class="indicator-pill orange filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status') {
						html = `<a class="indicator-pill blue filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else {
						html = `<a class="filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
				}
				else if (this.doctype == 'Employee Benefit Claim') {
					if (fieldname == 'current_status' && value == 'Draft') {
						html = `<a class="indicator-pill red filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'In-Progress') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Approved') {
						html = `<a class="indicator-pill green filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Cancelled') {
						html = `<a class="indicator-pill pink filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Rejected') {
						html = `<a class="indicator-pill orange filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status') {
						html = `<a class="indicator-pill blue filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else {
						html = `<a class="filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
				}
				else if (this.doctype == 'Attendance Request') {
					if (fieldname == 'current_status' && value == 'Draft') {
						html = `<a class="indicator-pill red filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'In-Progress') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Approved') {
						html = `<a class="indicator-pill green filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Cancelled') {
						html = `<a class="indicator-pill pink filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Rejected') {
						html = `<a class="indicator-pill orange filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status') {
						html = `<a class="indicator-pill blue filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else {
						html = `<a class="filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
				}
				else if (this.doctype == 'Resignation Form') {
					console.log('this.doctype',this.doctype)
					if (fieldname == 'current_status' && value == 'Draft') {
						html = `<a class="indicator-pill red filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'In-Progress') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Approved') {
						html = `<a class="indicator-pill green filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Cancelled') {
						html = `<a class="indicator-pill pink filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Rejected') {
						html = `<a class="indicator-pill orange filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status') {
						html = `<a class="indicator-pill blue filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else {
						html = `<a class="filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
				}
				else if (this.doctype == 'Full and Final Statement') {
					if (fieldname == 'current_status' && value == 'Draft') {
						html = `<a class="indicator-pill red filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'In-Progress') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Approved') {
						html = `<a class="indicator-pill green filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Cancelled') {
						html = `<a class="indicator-pill pink filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Rejected') {
						html = `<a class="indicator-pill orange filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status') {
						html = `<a class="indicator-pill blue filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else {
						html = `<a class="filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
				}
				else if (this.doctype == 'Payroll Entry') {
					if (fieldname == 'current_status' && value == 'Draft') {
						html = `<a class="indicator-pill red filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'In-Progress') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Approved') {
						html = `<a class="indicator-pill green filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Cancelled') {
						html = `<a class="indicator-pill pink filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Rejected') {
						html = `<a class="indicator-pill orange filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status') {
						html = `<a class="indicator-pill blue filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else {
						html = `<a class="filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
				}
				else if (this.doctype == 'Shift Request') {
					if (fieldname == 'current_status' && value == 'Draft') {
						html = `<a class="indicator-pill red filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'In-Progress') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Approved') {
						html = `<a class="indicator-pill green filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Cancelled') {
						html = `<a class="indicator-pill pink filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Rejected') {
						html = `<a class="indicator-pill orange filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status') {
						html = `<a class="indicator-pill blue filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else {
						html = `<a class="filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
				}
				else if (this.doctype == 'Lead') {
					if (fieldname == 'current_status' && value == 'Open') {
						html = `<a class="indicator-pill red filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Assigned') {
						html = `<a class="indicator-pill blue filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'First-Touch') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Second-Touch') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}

					else if (fieldname == 'current_status' && value == 'Q-New') {
						html = `<a class="indicator-pill blue filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Q-Verified') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Q-Proposed') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}

					else if (fieldname == 'current_status' && value == 'Parked') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}

					else if (fieldname == 'current_status' && value == "Don't-Contact") {
						html = `<a class="indicator-pill red filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Third-Touch') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Rejected') {
						html = `<a class="indicator-pill orange filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Converted') {
						html = `<a class="indicator-pill green filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else {
						html = `<a class="filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
				}
				else if (this.doctype == 'Supplier Advance') {
					if (fieldname == 'current_status' && value == 'Open') {
						html = `<a class="indicator-pill red filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'In-Progress') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Approved') {
						html = `<a class="indicator-pill blue filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Accounted') {
						html = `<a class="indicator-pill blue filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Rejected') {
						html = `<a class="indicator-pill red filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Processed') {
						html = `<a class="indicator-pill green filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
				}
				else if (this.doctype == 'Upload Purchase Bill') {
					if (fieldname == 'current_status' && value == 'Open') {
						html = `<a class="indicator-pill red filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'In-Progress') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Approved') {
						html = `<a class="indicator-pill blue filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Accounted') {
						html = `<a class="indicator-pill blue filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Rejected') {
						html = `<a class="indicator-pill red filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'current_status' && value == 'Processed') {
						html = `<a class="indicator-pill green filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
				}
				else if (this.doctype == 'Corporate Card Expense') {
					if (fieldname == 'workflow_state' && value == 'Open') {
						html = `<a class="indicator-pill red filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'workflow_state' && value == 'In-Progress') {
						html = `<a class="indicator-pill gray filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'workflow_state' && value == 'Approved') {
						html = `<a class="indicator-pill blue filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'workflow_state' && value == 'Accounted') {
						html = `<a class="indicator-pill blue filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'workflow_state' && value == 'Rejected') {
						html = `<a class="indicator-pill red filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
					else if (fieldname == 'workflow_state' && value == 'Processed') {
						html = `<a class="indicator-pill green filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
					}
				}
				else {
					html = `<a class="filterable ellipsis" href="${this.get_form_link(doc)}"
						data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
						${format()}
					</a>`;
				}


			}

			return `<span class="ellipsis"
				title="${__(label)}: ${frappe.utils.escape_html(_value)}">
				${html}
			</span>`;
		};

		const class_map = {
			Subject: "list-subject level",
			Field: "hidden-xs",
		};
		const css_class = [
			"list-row-col ellipsis",
			class_map[col.type],
			frappe.model.is_numeric_field(df) ? "text-right" : "",
		].join(" ");

		const html_map = {
			Subject: this.get_subject_html(doc),
			Field: field_html(),
		};
		let column_html = html_map[col.type];

		// listview_setting formatter
		if (
			this.settings.formatters &&
			this.settings.formatters[fieldname]
		) {
			column_html = this.settings.formatters[fieldname](value, df, doc);
		}

		return `
			<div class="${css_class}">
				${column_html}
			</div>
		`;
	}

	setup_filterable() {
		// filterable events
		this.$result.on("click", ".filterable", (e) => {
			if (e.metaKey || e.ctrlKey) return;
			e.stopPropagation();
			const $this = $(e.currentTarget);
		});
	}

	get_fields_in_list_view() {
		return this.meta.fields.filter((df) => {
			return (
				(frappe.model.is_value_type(df.fieldtype) &&
					(df.in_list_view &&
						frappe.perm.has_perm(
							this.doctype,
							df.permlevel,
							"read"
						))) ||
				(df.fieldtype === "Currency" &&
					df.options &&
					!df.options.includes(":")) ||
				df.fieldname === "status"
			);
		});
	}

	setup_columns() {
		// setup columns for list view
		this.columns = [];

		const get_df = frappe.meta.get_docfield.bind(null, this.doctype);

		// 1st column: title_field or name
		if (this.meta.title_field) {
			this.columns.push({
				type: "Subject",
				df: get_df(this.meta.title_field),
			});
		} else {
			this.columns.push({
				type: "Subject",
				df: {
					label: this.doctype + " " + __("ID"),
					fieldname: "name",
				},
			});
		}


		this.columns.push({
			type: "Tag"
		});

		if (
			!this.settings.hide_name_column &&
			this.meta.title_field &&
			this.meta.title_field !== 'name'
		) {
			var doctypes_ls = ["Attendance","Expense Claim","Timesheet"]

			if(! doctypes_ls.includes(this.doctype)){
				this.columns.push({
					type: "Field",
					df: {
						label: this.doctype + " " + __("ID"),
						fieldname: "name",
					},
				});
			}
		}

		const fields_in_list_view = this.get_fields_in_list_view();
		// Add rest from in_list_view docfields
		this.columns = this.columns.concat(
			fields_in_list_view
				.filter((df) => {
					if (
						frappe.has_indicator(this.doctype) &&
						df.fieldname === "status"
					) {
						return false;
					}
					if (df.fieldname === "number_of_employees") {
						return false;
					}
					if (!df.in_list_view) {
						return false;
					}
					return df.fieldname !== this.meta.title_field;
				})
				.map((df) => ({
					type: "Field",
					df,
				}))
		);

		// if (this.list_view_settings.fields) {
		// 	this.columns = this.reorder_listview_fields();
		// }

		// limit max to 8 columns if no total_fields is set in List View Settings
		// Screen with low density no of columns 4
		// Screen with medium density no of columns 6
		// Screen with high density no of columns 8
		let total_fields = 10;

		if (window.innerWidth <= 1366) {
			total_fields = 8;
		} else if (window.innerWidth >= 1920) {
			total_fields = 10;
		}

		this.columns = this.columns.slice(0, /*this.list_view_settings.total_fields || */total_fields);

		// 2nd column: Status indicator
		if (frappe.has_indicator(this.doctype)) {

			var excluded = ["Full and Final Statement","Corporate Card Expense", "Supplier Advance", "Upload Purchase Bill", "Lead", "Variable Pay Allocation", "Leave Application", "Employee Benefit Claim", "Attendance Request", "Resignation Form", "Payroll Entry", "Shift Request"];
			var doc = ["Attendance"]
			var docs = ["Payroll Entry"];
			var comp_todo = ["Compliance To-Do"]


			if (docs.includes(this.doctype)) {
				this.columns.splice(5, 0, {
					type: "Number Of Employees",
				});
			}
			if (comp_todo.includes(this.doctype)) {
				this.columns.splice(1, 0, {
					type: "Compliance Structure",
				});
				this.columns.splice(2, 0, {
					type: "Task Name",
				});
				this.columns.splice(3, 0, {
					type: "Frequency",
				});
				this.columns.splice(4, 0, {
					type: "Due Date",
				});
			}
			if (!excluded.includes(this.doctype)) {
				if (doc.includes(this.doctype)) {

					this.columns.push({
						type: "Working Hours",
					});
					this.columns.splice(7, 0, {
						type: "Original Status",
					});
				}
				this.columns.push({
					type: "Status",
				});
			}
			var doctypes = ["Salary Structure Assignment", "Leave Travel Allowance Policy Assignment", "Leave Policy Assignment", "Holiday Assignments", "Shift Assignment"]
			if (doctypes.includes(this.doctype)) {
				cur_page.page.list_view.filter_area.add(this.doctype, "docstatus", "!=", "2")
			}
		}
		var comp_task = ["Compliance Tasks"]
		if (comp_task.includes(this.doctype)) {
			this.columns.splice(1, 0, {
				type: "Parent Name",
			});
			this.columns.splice(2, 0, {
				type: "Compliance Name",
			});
			this.columns.splice(5, 0, {
				type: "Effective From",
			});
		}

		var time_doc = ["Timesheet"]
		if (time_doc.includes(this.doctype)) {

			this.columns.splice(4, 0, {
				type: "Start Date",
			});
			this.columns.splice(5, 0, {
				type: "Total Working Hours",
			});
		}
		var project_doc = ["Project"]
		if (project_doc.includes(this.doctype)) {

			this.columns.splice(5, 0, {
				type: "Expected Start Date",
			});
			this.columns.splice(6, 0, {
				type: "Expected End Date",
			});
		}

	}

	setup_list_click() {
		this.$result.on("click", ".list-row, .image-view-header, .file-header", (e) => {
			const $target = $(e.target);
			// tick checkbox if Ctrl/Meta key is pressed
			if ((e.ctrlKey || e.metaKey) && !$target.is("a")) {
				const $list_row = $(e.currentTarget);
				const $check = $list_row.find(".list-row-checkbox");
				$check.prop("checked", !$check.prop("checked"));
				e.preventDefault();
				this.on_row_checked();
				return;
			}
			// don't open form when checkbox, like, filterable are clicked
			if (
				// $target.hasClass("filterable") ||
				$target.hasClass("select-like") ||
				$target.hasClass("file-select") ||
				$target.hasClass("list-row-like") ||
				$target.is(":checkbox")
			) {
				e.stopPropagation();
				return;
			}

			// link, let the event be handled via set_route
			if ($target.is("a")) return;

			// clicked on the row, open form
			const $row = $(e.currentTarget);
			const link = $row.find(".list-subject a").get(0);
			if (link) {
				frappe.set_route(link.pathname);
				return false;
			}
		});
	}

	get_header_html_skeleton(left = "", right = "") {
		return `
			<header class="level list-row-head text-muted">
				<div class="level-left list-header-subject" style="flex: 4!important; min-width:90% !important;">
					${left}
				</div>
				<div class="level-left checkbox-actions">
					<div class="level list-subject">
						<input class="level-item list-check-all" type="checkbox"
							title="${__("Select All")}">
						<span class="level-item list-header-meta"></span>
					</div>
				</div>
				<div class="level-right">
					${right}
				</div>
			</header>
		`;
	}

	get_list_row_html_skeleton(left = "", right = "") {
		return `
			<div class="list-row-container" tabindex="1">
				<div class="level list-row">
					<div class="level-left ellipsis" style="flex:4 !important; min-width:90% !important;">
						${left}
					</div>
					<div class="level-right text-muted ellipsis">
						${right}
					</div>
				</div>
			</div>
		`;
	}
	set_actions_menu_items() {
		super.set_actions_menu_items();
		frappe.db.get_list("Report", {
			filters: { "ref_doctype": cur_list.doctype, "owner": frappe.session.user, "report_type": ["in", ["Report Builder", "Custom Report"]] },
			fields: ["name", "report_type"]
		})
			.then((result) => {
				if (result.length > 0) {
					const views_menu = cur_list.page.add_custom_button_group(__('My Favorite Views'));

					let d = {};
					for (var i = 0; i < result.length; i++) {
						if (result[i]["report_type"] == "Report Builder") {
							var report_name = result[i]["name"];
							var data_label = report_name.replace(" ", "%20");
							d[report_name] = data_label;
							cur_list.page.add_custom_menu_item(views_menu, report_name, function (res) {
								frappe.set_route('Report', cur_list.doctype, res.target.innerText);
							});
						}
						else {
							var report_name = result[i]["name"];
							var data_label = report_name.replace(" ", "%20");
							d[report_name] = data_label;
							cur_list.page.add_custom_menu_item(views_menu, report_name, function (res) {
								frappe.set_route('query-report', res.target.innerText);
							});
						}

					}
				}
				// $(`[data-label= "${data_label}"]`).on("click", (cur_list) => {
				// 	console.log(data_label)

				// });
			});
	}

}

frappe.ui.form.ControlMultiSelectPills = class ControlMultiSelectPills extends frappe.ui.form.ControlMultiSelectPills {
	make_input() {
		super.make_input();
		this.$input_area.on("click", ".btn-remove", (e) => {
			const $target = $(e.currentTarget);
			const $value = $target.closest(".tb-selected-value");

			const value = decodeURIComponent($value.data().value);
			if (typeof (this.rows) == "string") {
				this.rows = this.rows.split(", ")
			}
			this.rows = this.rows.filter((val) => val !== value);

			this.parse_validate_and_set_in_model("");
		});
	}

	parse(value) {
		if (typeof value == "object" || !this.rows) {
			return value;
		}

		if (value && !this.rows.includes(value)) {
			if (typeof (this.rows) == 'object') {
				this.rows.push(value);
			}
			else if (typeof (this.rows) == 'string') {
				this.rows += ", " + value;
			}
			if (this.rows.length) this.set_pill_html(this.rows)
		}

		return this.rows;
	}

	set_pill_html(values) {
		let html = ''
		if (typeof (values) == 'object') {
			html = values.map((value) => this.get_pill_html(value)).join("");
		}
		else if (typeof (values) == 'string') {
			html = values.split(', ').map((value) => this.get_pill_html(value)).join("");
		}

		this.$multiselect_wrapper.find(".tb-selected-value").remove();
		this.$multiselect_wrapper.prepend(html);
	}
}


frappe.ui.form.QuickEntryForm = class QuickEntryForm extends frappe.ui.form.QuickEntryForm {

	render_edit_in_full_page_link() {
		if (!["Customer", "Supplier"].includes(this.doctype))
			super.render_edit_in_full_page_link()
	}
}
frappe.utils.new_auto_repeat_prompt = function (frm) {
	const fields = [
		{
			fieldname: "frequency",
			fieldtype: "Select",
			label: __("Frequency"),
			reqd: 1,
			options: [
				{ label: __("Daily"), value: "Daily" },
				{ label: __("Weekly"), value: "Weekly" },
				{ label: __("Monthly"), value: "Monthly" },
				{ label: __("Quarterly"), value: "Quarterly" },
				{ label: __("Half-yearly"), value: "Half-yearly" },
				{ label: __("Yearly"), value: "Yearly" },
			],
		},
		{
			fieldname: "start_date",
			fieldtype: "Date",
			label: __("Start Date"),
			reqd: 1,
			default: frappe.datetime.nowdate(),
		},
		{
			fieldname: "end_date",
			fieldtype: "Date",
			label: __("End Date"),
		},
	];

	const dialog = new frappe.ui.Dialog({
		title: __("Auto Repeat"),
		fields: fields,
		primary_action_label: __("Save"),
		primary_action(values) {
			frappe.call({
				method: "frappe.automation.doctype.auto_repeat.auto_repeat.make_auto_repeat",
				args: {
					doctype: frm.doc.doctype,
					docname: frm.doc.name,
					frequency: values["frequency"],
					start_date: values["start_date"],
					end_date: values["end_date"],
				},
				callback: function (r) {
					if (r.message) {
						frappe.show_alert({
							message: __("Auto Repeat created for this document"),
							indicator: "green",
						});
						frm.reload_doc();
					}
				},
			});
			dialog.hide();
		},
	});
	dialog.show();
	const $footer = dialog.$wrapper.find('.modal-footer');
	const $secondary_button = $('<button class="btn btn-secondary btn-sm">')
		.text(__('Edit Full Form'))
		.on('click', function () {
			const values = dialog.get_values();
			frappe.route_options = {
				'frequency': values["frequency"],
				'reference_doctype' : frm.doc.doctype,
				'reference_document' : frm.doc.name,
			};
			window.open(
			frappe.urllib.get_full_url('/app/auto-repeat/new-auto-repeat-1') + 
			'?' + $.param(frappe.route_options))
		});
	$footer.prepend($secondary_button);
};



frappe.ui.form.Form = class Form extends frappe.ui.form.Form {

	showVidoPreviewPopup(playlink, videotitle) {
		const me = this;
		frappe.call({
			method: "stanch.doc_events.video_preview_for_doctypes_bundle",
			args: {	
				doctype: cur_frm.doc.doctype,
			},
			callback: function(r) {
				if(r.message){
					const videoUrl = r.message[0];
					const fetchDoc = r.message[1];
					const users = r.message[2];
					const d = new frappe.ui.Dialog({
						title: videotitle,
						fields: [
							{
								fieldtype: 'HTML',
								fieldname: 'video_frame',
								options: `<div style="width: 70vw; height: 70vh; display: flex; justify-content: center; align-items: center;">
											<img src="loading_spinner.gif" alt="Loading..." style="width: 100px; height: 100px;">
										</div>`
							}
						],
						primary_action_label: "Ok !Got it",
						primary_action: function() {
							frappe.call({
								method: "stanch.doc_events.skip_video_function_doctypes",
								args: {
									doctype: cur_frm.doc.doctype,
									user : frappe.session.user,
									fetch_doctype: fetchDoc,
									video_link: videoUrl,
									viewers : users
								},
							});
							d.hide();
						},
						secondary_action_label: "Remind Me Later",
						secondary_action: function() {
							d.hide();
						},
					});

					d.show();

					d.onhide = function () {
						d.$wrapper.find('[data-fieldname="video_frame"]').html('');
					};
					d.$wrapper.find('.close-alt').hide();

					d.$wrapper.find('.modal-dialog').css({
						'max-width': '75vw',
						'margin': '30px auto',
						'display': 'flex',
						'align-items': 'center',
						'justify-content': 'center',
						'min-height': '40vh'
					});

					d.$wrapper.find('.modal-content').css({
						'width': '100%',
						'max-width': '80vw',
						'height': '100%',
						'margin': '0 auto'
					});

					d.$wrapper.find('.modal-body').css({
						'display': 'flex',
						'justify-content': 'center',
						'align-items': 'center',
						'height': 'calc(90vh - 90px)',
						'padding': '0'
					});
					
					d.$wrapper.find('[data-fieldname="video_frame"]').html(`<iframe src="${playlink}" style="width: 70vw; height: 70vh;" allow="autoplay"></iframe>`);
				}
			}
		})
		me.show_video_library()

	}

	showVideoList(videos) {
		const me = this;
		const listHtml = videos.map((video, idx) => {
			return `<tr>
                <td>${idx + 1}</td>
                <td>${video.page}</td>
                <td>${video.description}</td>
                <td><button id="mainPlaybtn" data-title="${video.page}" data-playlink="${video.video_link}" class="btn btn-primary play-btn">
                    <i class="fas fa-play play-icon"></i>
                </button></td>
            </tr>`;
		}).join('');

		const template =
			`<table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Page</th>
                        <th scope="col">Description</th>
                        <th scope="col">Play Video</th>
                    </tr>
                </thead>
                <tbody>
                    ${listHtml}
                </tbody>
            </table>`;

		let d = new frappe.ui.Dialog({
			title: 'Watch Tutorial',
			fields: [
				{
					fieldtype: 'HTML',
					fieldname: 'video_frame',
					options: template
				}
			],
			size: 'large'
		});
		d.show();

		d.body.querySelectorAll("#mainPlaybtn").forEach(button => {
			button.addEventListener("click", (event) => {
				const buttonElement = event.target.closest("#mainPlaybtn");
				const playlink = buttonElement.dataset.playlink;
				const videotitle = buttonElement.dataset.title;
				console.log("Button clicked:", playlink, videotitle);
				me.showVidoPreviewPopup(playlink, videotitle);
				d.hide();


			});
		});
		me.show_video_library()

	}
	show_video_library(){
		const me = this;

		cur_frm.add_custom_button(__("Watch Tutorial"), function () {
			frappe.call({
				method: "stanch.stanch.doctype.video_library.video_library.get_videos",
				args: {
					doctype: cur_frm.doc.doctype
				}
			}).then(res => {
				if (res.message.length > 0) {
						if (res.message.length > 1) {
							cur_frm.remove_custom_button("Watch Tutorial")
							me.showVideoList(res.message);
						} else if (res.message.length == 1) {
							const video = res.message[0];
							cur_frm.remove_custom_button("Watch Tutorial")
							me.showVidoPreviewPopup(video.video_link, video.page);
						}
				}
			}).catch(err => {
				console.error("ERROR: ", err);
				frappe.msgprint(__("An error occurred while fetching the video link."));
			});
		}).removeClass('btn-default')
		.html('<i class="fas fa-play-circle text-primary"></i>');
	}
	refresh_fields() {
		const me = this;
		let showUnsyncButton = true;
		const isNewDoc = cur_frm.is_new();
		if (isNewDoc == 1) {
			me.show_video_library()
		}

		if (showUnsyncButton && isNewDoc != 1) {
			cur_frm.page.add_menu_item(__("Unsync the Attachments"), function () {
				frappe.confirm(__("Are you sure to unsync the Attachments"), function () {
					$.each(cur_frm.get_files(), function (i, z) {
						if (z.name) {
							frappe.call({
								method: "stanch.api.unsync_files",
								args: {
									"files": z.name
								},
								callback(r) {
									if (r.message) {
										frappe.show_alert({
											message: __("Attachments unsynced successfully"),
											indicator: "green",
										}, 5);
										cur_frm.reload_doc();
									}
								}
							});
						}
					});
				});
			});
		}

		super.refresh_fields()
		// $('.col-lg-2.layout-side-section').toggle(!cur_frm.is_new())
		$('.col-lg-2.layout-side-section').toggle(false)
		cur_frm.dashboard.hide()
		if (!['Employee','Quotation'].includes(cur_frm.doctype)) {
			cur_frm.toggle_display("naming_series", false);
		}
		let company_list = frappe.get_list("Company");
		if (company_list.length > 1) {
			cur_frm.toggle_display("company", true);
		}
		else {
			cur_frm.toggle_display("company", false);
		}
		if (cur_frm.is_new() && cur_frm.doc.docstatus == 0) {
			cur_frm.doc.company = frappe.defaults.get_user_default("Company")
		}

	}
	setup() {
		super.setup()
		this.add_tabbed_sections()
		//if(cur_frm.doc.__islocal){
		//	frappe.call({
		//		method: "stanch.doc_events.video_preview_for_doctypes",
		//		args: {	
		//			doctype: cur_frm.doc.doctype,
		//		},
			//	callback: function(r) {
			//		if(r.message){
			//			const videoUrl = r.message[0];
			//			const fetchDoc = r.message[1];
			//			const users = r.message[2];
			//			let videoDialog = new frappe.ui.Dialog({
			//				title: fetchDoc,
			//				// size:'large',
			//				fields: [
			//					{
			//						fieldtype: "HTML",
			//						fieldname: "video_html"
			//					},
			//				],
			//				
			//				primary_action_label: "Ok !Got it",
			//				primary_action: function() {
			//					frappe.call({
			//						method: "stanch.doc_events.skip_video_function_doctypes",
			//						args: {
			//							doctype: cur_frm.doc.doctype,
			//							user : frappe.session.user,
			//							fetch_doctype: fetchDoc,
           		//						video_link: videoUrl,
			//							viewers : users
			//						},
			//					});
			//					videoDialog.hide();
			//				},
			//				secondary_action_label: "Remind Me Later",
			//				secondary_action: function() {
			//					videoDialog.hide();
			//				},
			//			});
			//			
			//			videoDialog.onhide = function () {
			//				videoDialog.$wrapper.find('[data-fieldname="video_html"]').html('');
			//			};
//	
//						videoDialog.$wrapper.find('.modal-dialog').css({
//							'max-width': '75vw',
//							'margin': '30px auto',
//							'display': 'flex',
//							'align-items': 'center',
//							'justify-content': 'center',
//							'min-height': '40vh'
//						});
//						videoDialog.$wrapper.find('.close-alt').hide();
//
//						videoDialog.$wrapper.find('.modal-content').css({
//							'width': '100%',
//							'max-width': '80vw',
//							'height': '100%',
//							'margin': '0 auto'
//						});
//	
//						videoDialog.$wrapper.find('.modal-body').css({
//							'display': 'flex',
//							'justify-content': 'center',
//							'align-items': 'center',
//							'height': 'calc(90vh - 90px)',
//							'padding': '0'
//						});
//	
//						videoDialog.$wrapper.find('[data-fieldname="video_html"]').html(`<iframe src="${videoUrl}" style="width: 70vw; height: 70vh;" allow="autoplay"></iframe>`);
//						videoDialog.$wrapper.modal({
//							backdrop: 'static',
//							keyboard: false
//						});
//						videoDialog.show();
//					}
//				}
//			})
//		}
	}
	
	add_tabbed_sections() {
		if (has_common(["Company Administrator", "Sales User", "Sales Approver", "Purchase User", "Purchase Approver", "Accounts User", "Accounts Manager", "Payments Manager", "HR User", "HR Manager"], frappe.user_roles)) {
			if (cur_frm.doc.doctype == "Purchase Invoice" && !has_common(["Purchase User", "Accounts User", "Accounts Manager", "Payments Manager"], frappe.user_roles)) {
				return
			}
			let tabbed_section = []
			let more_info_section = []
			let add_tab = null
			for (let i of cur_frm.meta.fields) {
				if (i.in_tab)
					tabbed_section.push(i)
				if (i.in_more_info)
					more_info_section.push(i.fieldname)
				if (i.insert_tabs_after_this_section)
					add_tab = i.fieldname
			}
			let tab_list = []
			let first_section = null
			tabbed_section.forEach(field => {
				if (tab_list.length == 0) {
					first_section = field.fieldname
				}
				tab_list.push(
					`<li class="nav-item" role="presentation">
					<button class="btn btn-link nav-link ${first_section == field.fieldname ? "active" : ''}" id="pills-notifications-tab" value="${field.fieldname}" data-toggle="pill" role="section-tab" aria-controls="pills-notifications" aria-selected="true" style="border-top-width: 0px;">
						${field.label}
					</button>
				</li>`
				)
			});
			if (first_section && !cur_frm.doc.current_tab) {
				cur_frm.doc.current_tab = first_section
			}
			if (more_info_section.length) {
				tab_list.push(
					`<li class="nav-item" role="presentation">
					<button class="btn btn-link nav-link" id="pills-notifications-tab" value="${more_info_section.toString()}" data-toggle="pill" role="section-tab" aria-controls="pills-notifications" aria-selected="true" style="border-top-width: 0px;">
						More Info
					</button>
				</li>`
				)
			}
			let tabs = `<div class="row form-section card-section custom-tab-switcher" style="margin-bottom: auto;border-bottom-width:0px;"><ul class="nav nav-pills tabsbtn" id="pills-tab" role="tablist">${tab_list.join('')}</ul></div>`;
			$('.custom-tab-switcher').remove()
			$(tabs).insertAfter('[data-fieldname="' + add_tab + '"]')
			$('[role="section-tab"]').on("click", (e) => {
				if (cur_frm.doc.current_tab == e.currentTarget.value) {
					return
				}
				if (e.currentTarget.innerText == "More Info") {
					let sections = e.currentTarget.value.split(',')
					sections.forEach(section => {
						cur_frm.set_df_property(section, "hidden", 0)
						$('[data-fieldname="' + section + '"]').css("margin-bottom", "auto").css("border-bottom-width", "0px")
					})
				} else {
					cur_frm.set_df_property(e.currentTarget.value, "hidden", 0)
				}

				if (cur_frm.doc.current_tab && cur_frm.doc.current_tab.includes(',')) {
					let sections = cur_frm.doc.current_tab.split(',')
					sections.forEach(section => {
						cur_frm.set_df_property(section, "hidden", 1)
					})
				} else {
					cur_frm.set_df_property(cur_frm.doc.current_tab, "hidden", 1)
				}
				cur_frm.doc.current_tab = e.currentTarget.value
			})
			if (cur_frm.doc.current_tab)
				$("[value='" + cur_frm.doc.current_tab + "']").click()
			// debugger
			// $('[value="'+first_section+'"]').click()
		}
	}
	onload_post_render() {
		
		let parent = frappe.get_meta(cur_frm.doctype);
		parent.fields.forEach(function(parent_field) {
			if (parent_field.fieldname === 'branch') {
				cur_frm.set_df_property(parent_field.fieldname,'reqd',frappe.boot.is_branch_mandatory)
			}
		});
		
		if (frappe.boot.check_inventory_subscription == 1){
			if (['Sales Order','Delivery Note','Purchase Order'].includes(cur_frm.doc.doctype)) {
				cur_frm.set_df_property('set_warehouse','hidden',0)
			}
		}

		// if (!this.doctype.includes("Lead","Variable Pay Allocation","Leave Application","Employee Benefit Claim","Attendance Request","Payroll Entry","Shift Request"))

		super.onload_post_render()
		if (!['Employee','Quotation'].includes(cur_frm.doctype)) {
			cur_frm.toggle_display("naming_series", false);
		}
		let company_list = frappe.get_list("Company");
		if (company_list.length > 1) {
			cur_frm.toggle_display("company", true);
		}
		else {
			cur_frm.toggle_display("company", false);
		}
		let doc_table_map = {
			//Purchase
			"Request for Quotation": ["suppliers", "items"],
			"Purchase Order": ["items"],
			"Purchase Invoice": ["items"],

			//Sales
			"Quotation": ["items"],
			"Sales Order": ["items"],
			"Sales Invoice": ["items"],

			//Employee
			"Expense Claim": ["expenses"],
			"Timesheet": ["time_logs"],

			//Accounts
			"Journal Entry": ["accounts"],
			"Payment Entry": ["references"],
			"Payment Order": ["references"],

			//Stock
			"Purchase Receipt": ["items"],
			"Delivery Note": ["items"],
			"Stock Entry": ["items"],
			"Stock Reconciliation": ["items"]
		}

		if (cur_frm.is_new() && cur_frm.doc.doctype in doc_table_map) {
			doc_table_map[cur_frm.doc.doctype].forEach(e => {
				if (!(e in cur_frm.doc) || cur_frm.doc[e].length == 0) {
					cur_frm.add_child(e, {})
				}
			})
			cur_frm.refresh()
		}

		erpnext.accounts.dimensions.setup_dimension_filters(cur_frm, cur_frm.doctype)

		$("[data-fieldtype='Date']").focus(function () { $(this).select(); });
		$("[data-fieldtype='Data']").focus(function () { $(this).select(); });
		$("[data-fieldtype='Text']").focus(function () { $(this).select(); });
		$("[data-fieldtype='Small Text']").focus(function () { $(this).select(); });
		$("[data-fieldtype='Data']").focus(function () { $(this).select(); });
	}
}


frappe.ui.toolbar.reset_password = function () {
	frappe.prompt([
		{
			label: 'New Password',
			fieldname: 'new_password',
			fieldtype: 'Password'
		},
		{
			label: 'Confirm New Password',
			fieldname: 'confirm_new_password',
			fieldtype: 'Password'
		},
	], (values) => {
		if (values.new_password == values.confirm_new_password) {
			frappe.call({
				type: "POST",
				method: "frappe.core.doctype.user.user.test_password_strength",
				args: {
					new_password: values.new_password || "",
				},
				callback: function (s) {
					if (s.message || s.message.entropy) {
						var score = s.message.score;
						var feedback = s.message.feedback;
						feedback.crack_time_display = s.message.crack_time_display;

						frappe.call({
							type: "POST",
							method: "stanch.api.reset_password",
							args: {
								reset_password: values.new_password,
							}
						})
					}
				}
			})
		}
		else {
			frappe.show_alert({
				message: __('Passwords do not match. Try Again!'),
				indicator: 'red'
			}, 3);
			frappe.ui.toolbar.reset_password();
		}
	})
};
frappe.provide("frappe.ui");

frappe.ui.Tree = class {
	constructor({
		parent,
		label,
		root_value,
		icon_set,
		toolbar,
		expandable,
		with_skeleton = 1, // eslint-disable-line

		args,
		method,
		get_label,
		on_render,
		on_click, // eslint-disable-line
	}) {
		$.extend(this, arguments[0]);
		if (root_value == null) {
			this.root_value = label;
		}
		this.setup_treenode_class();
		this.nodes = {};
		this.wrapper = $('<div class="tree">').appendTo(this.parent);
		if (with_skeleton) this.wrapper.addClass("with-skeleton");

		if (!icon_set) {
			this.icon_set = {
				open: frappe.utils.icon("folder-open", "md"),
				closed: frappe.utils.icon("folder-normal", "md"),
				leaf: frappe.utils.icon("primitive-dot", "xs"),
			};
		}

		this.setup_root_node();
	}

	get_nodes(value, is_root) {
		var args = Object.assign({}, this.args);
		args.parent = value;
		args.is_root = is_root;

		return new Promise((resolve) => {
			frappe.call({
				method: this.method,
				args: args,
				callback: (r) => {
					const allNodes = r.message;

					frappe.call({
						method: 'frappe.client.get_list',
						args: {
							doctype: 'Account',
							filters: { name: ['in', allNodes.map(node => node.value)] },
							fields: ['name', 'disabled'],
							limit_page_length: 1000
						},
						callback: (accountResponse) => {
							if (args.doctype === 'Account') {
								const accountData = accountResponse.message || [];

								const filteredNodes = allNodes.filter(node => {
									const account = accountData.find(acc => acc.name === node.value);
									return account ? !account.disabled : false;
								});

								this.on_get_node && this.on_get_node(filteredNodes);
								resolve(filteredNodes);
							} else {
								this.on_get_node && this.on_get_node(allNodes);
								resolve(allNodes);
							}
						}
					});
				},
			});
		});
	}

	get_all_nodes(value, is_root, label) {
		var args = Object.assign({}, this.args);
		args.label = label || value;
		args.parent = value;
		args.is_root = is_root;

		args.tree_method = this.method;

		return new Promise((resolve) => {
			frappe.call({
				method: "frappe.desk.treeview.get_all_nodes",
				args: args,
				callback: (r) => {
					this.on_get_node && this.on_get_node(r.message, true);
					resolve(r.message);
				},
			});
		});
	}

	setup_treenode_class() {
		let tree = this;
		this.TreeNode = class {
			constructor({
				parent,
				label,
				parent_label,
				expandable,
				is_root,
				data, // eslint-disable-line
			}) {
				$.extend(this, arguments[0]);
				this.loaded = 0;
				this.expanded = 0;
				if (this.parent_label) {
					this.parent_node = tree.nodes[this.parent_label];
				}

				tree.nodes[this.label] = this;
				tree.make_node_element(this);
				tree.on_render && tree.on_render(this);
			}
		};
	}

	setup_root_node() {
		this.root_node = new this.TreeNode({
			parent: this.wrapper,
			label: this.label,
			parent_label: null,
			expandable: true,
			is_root: true,
			data: {
				value: this.root_value,
			},
		});
		this.expand_node(this.root_node, false);
	}

	refresh() {
		this.selected_node.parent_node && this.load_children(this.selected_node.parent_node, true);
	}

	make_node_element(node) {
		node.$tree_link = $('<span class="tree-link">')
			.attr("data-label", node.label)
			.data("node", node)
			.appendTo(node.parent);

		node.$ul = $('<ul class="tree-children">').hide().appendTo(node.parent);

		this.make_icon_and_label(node);
		if (this.toolbar) {
			node.$toolbar = this.get_toolbar(node).insertAfter(node.$tree_link);
		}
	}

	add_node(node, data) {
		var $li = $('<li class="tree-node">');

		return new this.TreeNode({
			parent: $li.appendTo(node.$ul),
			parent_label: node.label,
			label: data.value,
			title: data.title,
			expandable: data.expandable,
			data: data,
		});
	}

	reload_node(node) {
		return this.load_children(node);
	}

	toggle() {
		this.get_selected_node().toggle();
	}

	get_selected_node() {
		return this.selected_node;
	}

	set_selected_node(node) {
		this.selected_node = node;
	}

	load_children(node, deep = false) {
		const value = node.data.value,
			is_root = node.is_root;

		return deep
			? frappe.run_serially([
				() => this.get_all_nodes(value, is_root, node.label),
				(data_list) => this.render_children_of_all_nodes(data_list),
				() => this.set_selected_node(node),
			])
			: frappe.run_serially([
				() => this.get_nodes(value, is_root),
				(data_set) => this.render_node_children(node, data_set),
				() => this.set_selected_node(node),
			]);
	}

	render_children_of_all_nodes(data_list) {
		data_list.map((d) => this.render_node_children(this.nodes[d.parent], d.data));
	}

	render_node_children(node, data_set) {
		node.$ul.empty();
		if (data_set) {
			$.each(data_set, (i, data) => {
				var child_node = this.add_node(node, data);
				child_node.$tree_link.data("node-data", data).data("node", child_node);
			});
		}

		node.expanded = false;

		// As children loaded
		node.loaded = true;
		this.expand_node(node);
	}

	on_node_click(node) {
		this.expand_node(node);
		frappe.dom.activate(this.wrapper, node.$tree_link, "tree-link");
		if (node.$toolbar) this.show_toolbar(node);
	}

	expand_node(node, click = true) {
		this.set_selected_node(node);

		if (click) {
			this.on_click && this.on_click(node);
		}

		if (node.expandable) {
			this.toggle_node(node);
		}
		this.select_link(node);

		node.expanded = !node.expanded;
		node.parent.toggleClass("opened", node.expanded);
	}

	toggle_node(node) {
		if (node.expandable && this.get_nodes && !node.loaded) {
			return this.load_children(node);
		}

		// expand children
		if (node.$ul) {
			if (node.$ul.children().length) {
				node.$ul.toggle(!node.expanded);
			}

			// open close icon
			if (this.icon_set) {
				if (!node.expanded) {
					node.$tree_link.find(".icon").parent().html(this.icon_set.open);
				} else {
					node.$tree_link
						.find(".icon")
						.parent()
						.addClass("node-parent")
						.html(this.icon_set.closed);
				}
			}
		}
	}

	select_link(node) {
		this.wrapper.find(".selected").removeClass("selected");
		node.$tree_link.toggleClass("selected");
	}

	show_toolbar(node) {
		if (this.cur_toolbar) $(this.cur_toolbar).hide();
		this.cur_toolbar = node.$toolbar;
		node.$toolbar.show();
	}

	get_node_label(node) {
		if (this.get_label) {
			return this.get_label(node);
		}
		if (node.title && node.title != node.label) {
			return __(node.title) + ` <span class='text-muted'>(${node.label})</span>`;
		} else {
			return __(node.title || node.label);
		}
	}

	make_icon_and_label(node) {
		let icon_html = "";
		if (this.icon_set) {
			if (node.expandable) {
				icon_html = `<span class="node-parent">${this.icon_set.closed}</span>`;
			} else {
				icon_html = `<span>${this.icon_set.leaf}</span>`;
			}
		}

		$(icon_html).appendTo(node.$tree_link);
		$(`<a class="tree-label"> ${this.get_node_label(node)}</a>`).appendTo(node.$tree_link);

		node.$tree_link.on("click", () => {
			setTimeout(() => {
				this.on_node_click(node);
			}, 100);
		});

		node.$tree_link.hover(
			function () {
				$(this).parent().addClass("hover-active");
			},
			function () {
				$(this).parent().removeClass("hover-active");
			}
		);
	}

	get_toolbar(node) {
		let $toolbar = $('<span class="tree-node-toolbar btn-group"></span>').hide();

		Object.keys(this.toolbar).map((key) => {
			let obj = this.toolbar[key];
			if (!obj.label) return;
			if (obj.condition && !obj.condition(node)) return;

			var label = obj.get_label ? obj.get_label() : obj.label;
			var $link = $("<button class='btn btn-default btn-xs'></button>")
				.html(label)
				.addClass("tree-toolbar-button " + (obj.btnClass || ""))
				.appendTo($toolbar);
			$link.on("click", () => {
				obj.click(node);
			});
		});

		return $toolbar;
	}
};

frappe.ui.form.ControlTime = class ControlTime extends frappe.ui.form.ControlDate {
	set_formatted_input(value) {
		super.set_formatted_input(value);
	}
	make_input() {
		this.timepicker_only = true;
		super.make_input();
	}
	make_picker() {
		this.set_time_options();
		this.set_datepicker();
		this.refresh();
	}
	set_time_options() {
		let sysdefaults = frappe.boot.sysdefaults;

		let time_format =
			sysdefaults && "HH:mm";

		this.time_format = frappe.defaultTimeFormat;
		this.datepicker_options = {
			language: "en",
			timepicker: true,
			onlyTimepicker: true,
			timeFormat: time_format.toLowerCase().replace("mm", "ii"),
			startDate: frappe.datetime.now_time(true),
			onSelect: () => {
				// ignore micro seconds
				if (
					moment(this.get_value(), time_format).format("HH:mm") !=
					moment(this.value, time_format).format("HH:mm")
				) {
					this.$input.trigger("change");
				}
			},
			onShow: () => {
				$(".datepicker--button:visible").text(__("Now"));
				$(".datepicker--time").hide()
				$(".datepicker--buttons").hide()
				this.update_datepicker_position();
			},
			keyboardNav: false,
			todayButton: true,
		};
	}
	set_input(value) {
		super.set_input(value);
		if (
			value &&
			((this.last_value && this.last_value !== this.value) ||
				!this.datepicker.selectedDates.length)
		) {
			let time_format = "HH:mm";
			var date_obj = frappe.datetime.moment_to_date_obj(moment(value, time_format));
			this.datepicker.selectDate(date_obj);
		}
	}
	set_datepicker() {
		this.$input.datepicker(this.datepicker_options);
		this.datepicker = this.$input.data("datepicker");

		this.datepicker.$datepicker.find('[data-action="today"]').click(() => {
			this.datepicker.selectDate(frappe.datetime.now_time(true));
			this.datepicker.hide();
		});
		if (this.datepicker.opts.timeFormat.indexOf("s") == -1) {
			// No seconds in time format
			const $tp = this.datepicker.timepicker;
			$tp.$seconds.parent().css("display", "none");
			$tp.$secondsText.css("display", "none");
			$tp.$secondsText.prev().css("display", "none");
		}
	}
	set_description() {
		const { description } = this.df;
		const { time_zone } = frappe.sys_defaults;
		if (!frappe.datetime.is_system_time_zone()) {
			if (!description) {
				this.df.description = time_zone;
			} else if (!description.includes(time_zone)) {
				this.df.description += "<br>" + time_zone;
			}
		}
		super.set_description();
	}
	parse(value) {
		if (value) {
			if (value == "Invalid date") {
				value = "";
			}
			return frappe.datetime.user_to_str(value, true);
		}
	}
	format_for_input(value) {
		if (value) {
			return frappe.datetime.str_to_user(value, true);
		}
		return "";
	}
	validate(value) {
		if (value && !frappe.datetime.validate(value)) {
			let sysdefaults = frappe.sys_defaults;
			let time_format =
				sysdefaults && "HH:mM";
			frappe.msgprint(__("Time {0} must be in format: {1}", [value, time_format]));
			return "";
		}
		return value;
	}
};



erpnext.TransactionController = class TransactionController extends erpnext.taxes_and_totals {
	setup() {
		super.setup();
		let me = this;
		frappe.flags.hide_serial_batch_dialog = true;
		frappe.ui.form.on(this.frm.doctype + " Item", "rate", function (frm, cdt, cdn) {
			var item = frappe.get_doc(cdt, cdn);
			var has_margin_field = frappe.meta.has_field(cdt, 'margin_type');

			frappe.model.round_floats_in(item, ["rate", "price_list_rate"]);

			if (item.price_list_rate) {
				if (item.rate > item.price_list_rate && has_margin_field) {
					// if rate is greater than price_list_rate, set margin
					// or set discount
					item.discount_percentage = 0;
					item.margin_type = 'Amount';
					item.margin_rate_or_amount = flt(item.rate - item.price_list_rate,
						precision("margin_rate_or_amount", item));
					item.rate_with_margin = item.rate;
				} else {
					item.discount_percentage = flt((1 - item.rate / item.price_list_rate) * 100.0,
						precision("discount_percentage", item));
					item.discount_amount = flt(item.price_list_rate) - flt(item.rate);
					item.margin_type = '';
					item.margin_rate_or_amount = 0;
					item.rate_with_margin = 0;
				}
			} else {
				item.discount_percentage = 0.0;
				item.margin_type = '';
				item.margin_rate_or_amount = 0;
				item.rate_with_margin = 0;
			}
			item.base_rate_with_margin = item.rate_with_margin * flt(frm.doc.conversion_rate);

			cur_frm.cscript.set_gross_profit(item);
			cur_frm.cscript.calculate_taxes_and_totals();
			cur_frm.cscript.calculate_stock_uom_rate(frm, cdt, cdn);
		});

		frappe.ui.form.on(this.frm.cscript.tax_table, "rate", function (frm, cdt, cdn) {
			cur_frm.cscript.calculate_taxes_and_totals();
		});

		frappe.ui.form.on(this.frm.cscript.tax_table, "tax_amount", function (frm, cdt, cdn) {
			cur_frm.cscript.calculate_taxes_and_totals();
		});

		frappe.ui.form.on(this.frm.cscript.tax_table, "row_id", function (frm, cdt, cdn) {
			cur_frm.cscript.calculate_taxes_and_totals();
		});

		frappe.ui.form.on(this.frm.cscript.tax_table, "included_in_print_rate", function (frm, cdt, cdn) {
			cur_frm.cscript.set_dynamic_labels();
			cur_frm.cscript.calculate_taxes_and_totals();
		});

		frappe.ui.form.on(this.frm.doctype, "apply_discount_on", function (frm) {
			if (frm.doc.additional_discount_percentage) {
				frm.trigger("additional_discount_percentage");
			} else {
				cur_frm.cscript.calculate_taxes_and_totals();
			}
		});

		frappe.ui.form.on(this.frm.doctype, "additional_discount_percentage", function (frm) {
			if (!frm.doc.apply_discount_on) {
				frappe.msgprint(__("Please set 'Apply Additional Discount On'"));
				return;
			}

			frm.via_discount_percentage = true;

			if (frm.doc.additional_discount_percentage && frm.doc.discount_amount) {
				// Reset discount amount and net / grand total
				frm.doc.discount_amount = 0;
				frm.cscript.calculate_taxes_and_totals();
			}

			var total = flt(frm.doc[frappe.model.scrub(frm.doc.apply_discount_on)]);
			var discount_amount = flt(total * flt(frm.doc.additional_discount_percentage) / 100,
				precision("discount_amount"));

			frm.set_value("discount_amount", discount_amount)
				.then(() => delete frm.via_discount_percentage);
		});

		frappe.ui.form.on(this.frm.doctype, "discount_amount", function (frm) {
			frm.cscript.set_dynamic_labels();

			if (!frm.via_discount_percentage) {
				frm.doc.additional_discount_percentage = 0;
			}

			frm.cscript.calculate_taxes_and_totals();
		});

		frappe.ui.form.on(this.frm.doctype + " Item", {
			items_add: function (frm, cdt, cdn) {
				var item = frappe.get_doc(cdt, cdn);
				if (!item.warehouse && frm.doc.set_warehouse) {
					item.warehouse = frm.doc.set_warehouse;
				}

				if (!item.target_warehouse && frm.doc.set_target_warehouse) {
					item.target_warehouse = frm.doc.set_target_warehouse;
				}

				if (!item.from_warehouse && frm.doc.set_from_warehouse) {
					item.from_warehouse = frm.doc.set_from_warehouse;
				}

				erpnext.accounts.dimensions.copy_dimension_from_first_row(frm, cdt, cdn, 'items');
			}
		});

		if (this.frm.fields_dict["items"].grid.get_field('batch_no')) {
			this.frm.set_query("batch_no", "items", function (doc, cdt, cdn) {
				return me.set_query_for_batch(doc, cdt, cdn);
			});
		}

		if (
			this.frm.docstatus < 2
			&& this.frm.fields_dict["payment_terms_template"]
			&& this.frm.fields_dict["payment_schedule"]
			&& this.frm.doc.payment_terms_template
			&& !this.frm.doc.payment_schedule.length
		) {
			this.frm.trigger("payment_terms_template");
		}

		if (this.frm.fields_dict["taxes"]) {
			this["taxes_remove"] = this.calculate_taxes_and_totals;
		}

		if (this.frm.fields_dict["items"]) {
			this["items_remove"] = this.calculate_net_weight;
		}

		if (this.frm.fields_dict["recurring_print_format"]) {
			this.frm.set_query("recurring_print_format", function (doc) {
				return {
					filters: [
						['Print Format', 'doc_type', '=', cur_frm.doctype],
					]
				};
			});
		}

		if (this.frm.fields_dict["return_against"]) {
			this.frm.set_query("return_against", function (doc) {
				var filters = {
					"docstatus": 1,
					"is_return": 0,
					"company": doc.company
				};
				if (me.frm.fields_dict["customer"] && doc.customer) filters["customer"] = doc.customer;
				if (me.frm.fields_dict["supplier"] && doc.supplier) filters["supplier"] = doc.supplier;

				return {
					filters: filters
				};
			});
		}

		if (this.frm.fields_dict["items"].grid.get_field("expense_account")) {
			this.frm.set_query("expense_account", "items", function (doc) {
				return {
					filters: {
						"company": doc.company
					}
				};
			});
		}

		if (frappe.meta.get_docfield(this.frm.doc.doctype, "pricing_rules")) {
			this.frm.set_indicator_formatter('pricing_rule', function (doc) {
				return (doc.rule_applied) ? "green" : "red";
			});
		}

		let batch_no_field = this.frm.get_docfield("items", "batch_no");
		if (batch_no_field) {
			batch_no_field.get_route_options_for_new_doc = function (row) {
				return {
					"item": row.doc.item_code
				}
			};
		}

		if (this.frm.fields_dict["items"].grid.get_field('blanket_order')) {
			this.frm.set_query("blanket_order", "items", function (doc, cdt, cdn) {
				var item = locals[cdt][cdn];
				return {
					query: "erpnext.controllers.queries.get_blanket_orders",
					filters: {
						"company": doc.company,
						"blanket_order_type": doc.doctype === "Sales Order" ? "Selling" : "Purchasing",
						"item": item.item_code
					}
				}
			});
		}

		if (this.frm.fields_dict.taxes_and_charges) {
			this.frm.set_query("taxes_and_charges", function () {
				return {
					filters: [
						['company', '=', me.frm.doc.company],
						['docstatus', '!=', 2]
					]
				};
			});
		}

	}
	onload() {
		var me = this;

		if (this.frm.doc.__islocal) {
			var currency = frappe.defaults.get_user_default("currency");

			let set_value = (fieldname, value) => {
				if (me.frm.fields_dict[fieldname] && !me.frm.doc[fieldname]) {
					return me.frm.set_value(fieldname, value);
				}
			};

			this.frm.trigger('set_default_internal_warehouse');

			return frappe.run_serially([
				() => set_value('currency', currency),
				() => set_value('price_list_currency', currency),
				() => set_value('status', 'Draft'),
				() => set_value('is_subcontracted', 0),
				() => {
					if (this.frm.doc.company && !this.frm.doc.amended_from) {
						this.frm.trigger("company");
					}
				}
			]);
		}
	}

	is_return() {
		if (!this.frm.doc.is_return && this.frm.doc.return_against) {
			this.frm.set_value('return_against', '');
		}
	}

	setup_quality_inspection() {
		if (!in_list(["Delivery Note", "Sales Invoice", "Purchase Receipt", "Purchase Invoice"], this.frm.doc.doctype)) {
			return;
		}

		const me = this;
		if (!this.frm.is_new() && this.frm.doc.docstatus === 0) {
			this.frm.add_custom_button(__("Quality Inspection(s)"), () => {
				me.make_quality_inspection();
			}, __("Create"));
			this.frm.page.set_inner_btn_group_as_primary(__('Create'));
		}

		const inspection_type = in_list(["Purchase Receipt", "Purchase Invoice"], this.frm.doc.doctype)
			? "Incoming" : "Outgoing";

		let quality_inspection_field = this.frm.get_docfield("items", "quality_inspection");
		quality_inspection_field.get_route_options_for_new_doc = function (row) {
			if (me.frm.is_new()) return;
			return {
				"inspection_type": inspection_type,
				"reference_type": me.frm.doc.doctype,
				"reference_name": me.frm.doc.name,
				"item_code": row.doc.item_code,
				"description": row.doc.description,
				"item_serial_no": row.doc.serial_no ? row.doc.serial_no.split("\n")[0] : null,
				"batch_no": row.doc.batch_no
			}
		}

		this.frm.set_query("quality_inspection", "items", function (doc, cdt, cdn) {
			let d = locals[cdt][cdn];
			return {
				filters: {
					docstatus: 1,
					inspection_type: inspection_type,
					reference_name: doc.name,
					item_code: d.item_code
				}
			}
		});
	}

	make_payment_request() {
		let me = this;
		const payment_request_type = (in_list(['Sales Order', 'Sales Invoice'], this.frm.doc.doctype))
			? "Inward" : "Outward";

		frappe.call({
			method: "erpnext.accounts.doctype.payment_request.payment_request.make_payment_request",
			args: {
				dt: me.frm.doc.doctype,
				dn: me.frm.doc.name,
				recipient_id: me.frm.doc.contact_email,
				payment_request_type: payment_request_type,
				party_type: payment_request_type == 'Outward' ? "Supplier" : "Customer",
				party: payment_request_type == 'Outward' ? me.frm.doc.supplier : me.frm.doc.customer
			},
			callback: function (r) {
				if (!r.exc) {
					frappe.model.sync(r.message);
					frappe.set_route("Form", r.message.doctype, r.message.name);
				}
			}
		})
	}

	onload_post_render() {
		if (this.frm.doc.__islocal && !(this.frm.doc.taxes || []).length
			&& !(this.frm.doc.__onload ? this.frm.doc.__onload.load_after_mapping : false)) {
			frappe.after_ajax(() => this.apply_default_taxes());
		} else if (this.frm.doc.__islocal && this.frm.doc.company && this.frm.doc["items"]
			&& !this.frm.doc.is_pos) {
			frappe.after_ajax(() => this.calculate_taxes_and_totals());
		}
		if (frappe.meta.get_docfield(this.frm.doc.doctype + " Item", "item_code")) {
			this.setup_item_selector();
			this.frm.get_field("items").grid.set_multiple_add("item_code", "qty");
		}
	}

	refresh() {
		erpnext.toggle_naming_series();
		erpnext.hide_company();
		this.set_dynamic_labels();
		this.setup_sms();
		this.setup_quality_inspection();
		this.validate_has_items();
	}

	scan_barcode() {
		const barcode_scanner = new erpnext.utils.BarcodeScanner({ frm: this.frm });
		barcode_scanner.process_scan();
	}

	validate_has_items() {
		let table = this.frm.doc.items;
		this.frm.has_items = (table && table.length
			&& table[0].qty && table[0].item_code);
	}

	apply_default_taxes() {
		var me = this;
		var taxes_and_charges_field = frappe.meta.get_docfield(me.frm.doc.doctype, "taxes_and_charges",
			me.frm.doc.name);

		if (!this.frm.doc.taxes_and_charges && this.frm.doc.taxes && this.frm.doc.taxes.length > 0) {
			return;
		}

		if (taxes_and_charges_field) {
			return frappe.call({
				method: "erpnext.controllers.accounts_controller.get_default_taxes_and_charges",
				args: {
					"master_doctype": taxes_and_charges_field.options,
					"tax_template": me.frm.doc.taxes_and_charges || "",
					"company": me.frm.doc.company
				},
				debounce: 2000,
				callback: function (r) {
					if (!r.exc && r.message) {
						frappe.run_serially([
							() => {
								// directly set in doc, so as not to call triggers
								if (r.message.taxes_and_charges) {
									me.frm.doc.taxes_and_charges = r.message.taxes_and_charges;
								}

								// set taxes table
								if (r.message.taxes) {
									me.frm.set_value("taxes", r.message.taxes);
								}
							},
							() => me.set_dynamic_labels(),
							() => me.calculate_taxes_and_totals()
						]);
					}
				}
			});
		}
	}

	setup_sms() {
		var me = this;
		let blacklist = ['Purchase Invoice', 'BOM'];
		if (this.frm.doc.docstatus === 1 && !in_list(["Lost", "Stopped", "Closed"], this.frm.doc.status)
			&& !blacklist.includes(this.frm.doctype)) {
			this.frm.page.add_menu_item(__('Send SMS'), function () { me.send_sms(); });
		}
	}

	send_sms() {
		var sms_man = new erpnext.SMSManager(this.frm.doc);
	}

	item_code(doc, cdt, cdn) {
		var me = this;
		var item = frappe.get_doc(cdt, cdn);
		var update_stock = 0, show_batch_dialog = 0;

		item.weight_per_unit = 0;
		item.weight_uom = '';
		item.conversion_factor = 0;

		if (['Sales Invoice'].includes(this.frm.doc.doctype)) {
			update_stock = cint(me.frm.doc.update_stock);
			show_batch_dialog = update_stock;

		} else if ((this.frm.doc.doctype === 'Purchase Receipt' && me.frm.doc.is_return) ||
			this.frm.doc.doctype === 'Delivery Note') {
			show_batch_dialog = 1;
		}
		item.barcode = null;
        if (["Quotation", "Sales Order", "Sales Invoice"].includes(me.frm.doc.doctype)){
			frappe.db.get_value("Customer",me.frm.doc.customer || me.frm.doc.party_name, 'fixed_discount', (r) => {
				if (r && r.fixed_discount) {
					item.discount_percentage = r.fixed_discount
					if (item.item_code || item.serial_no) {
						if (!this.validate_company_and_party()) {
							this.frm.fields_dict["items"].grid.grid_rows[item.idx - 1].remove();
						} else {
							item.pricing_rules = ''
							return this.frm.call({
								method: "erpnext.stock.get_item_details.get_item_details",
								child: item,
								args: {
									doc: me.frm.doc,
									args: {
										item_code: item.item_code,
										barcode: item.barcode,
										serial_no: item.serial_no,
										batch_no: item.batch_no,
										set_warehouse: me.frm.doc.set_warehouse,
										warehouse: item.warehouse,
										customer: me.frm.doc.customer || me.frm.doc.party_name,
										quotation_to: me.frm.doc.quotation_to,
										supplier: me.frm.doc.supplier,
										currency: me.frm.doc.currency,
										update_stock: update_stock,
										conversion_rate: me.frm.doc.conversion_rate,
										price_list: me.frm.doc.selling_price_list || me.frm.doc.buying_price_list,
										price_list_currency: me.frm.doc.price_list_currency,
										plc_conversion_rate: me.frm.doc.plc_conversion_rate,
										company: me.frm.doc.company,
										order_type: me.frm.doc.order_type,
										is_pos: cint(me.frm.doc.is_pos),
										is_return: cint(me.frm.doc.is_return),
										is_subcontracted: me.frm.doc.is_subcontracted,
										ignore_pricing_rule: me.frm.doc.ignore_pricing_rule,
										doctype: me.frm.doc.doctype,
										name: me.frm.doc.name,
										project: item.project || me.frm.doc.project,
										qty: item.qty || 1,
										net_rate: item.rate,
										stock_qty: item.stock_qty,
										conversion_factor: item.conversion_factor,
										weight_per_unit: item.weight_per_unit,
										uom: item.uom,
										weight_uom: item.weight_uom,
										manufacturer: item.manufacturer,
										stock_uom: item.stock_uom,
										pos_profile: cint(me.frm.doc.is_pos) ? me.frm.doc.pos_profile : '',
										cost_center: item.cost_center,
										tax_category: me.frm.doc.tax_category,
										item_tax_template: item.item_tax_template,
										child_docname: item.name,
										discount_percentage:item.discount_percentage,
										is_old_subcontracting_flow: me.frm.doc.is_old_subcontracting_flow,
									}
								},
			
								callback: function (r) {
									if (!r.exc) {
										frappe.run_serially([
											() => {
												var d = locals[cdt][cdn];
												cur_frm.script_manager.trigger('item_tax_template', cdt, cdn)
												me.add_taxes_from_item_tax_template(d.item_tax_rate);
												if (d.free_item_data) {
													me.apply_product_discount(d);
												}
											},
											() => {
												// for internal customer instead of pricing rule directly apply valuation rate on item
												if (me.frm.doc.is_internal_customer || me.frm.doc.is_internal_supplier) {
													me.get_incoming_rate(item, me.frm.posting_date, me.frm.posting_time,
														me.frm.doc.doctype, me.frm.doc.company);
												} else {
													me.frm.script_manager.trigger("price_list_rate", cdt, cdn);
												}
											},
											() => {
												if (me.frm.doc.is_internal_customer || me.frm.doc.is_internal_supplier) {
													me.calculate_taxes_and_totals();
												}
											},
											() => me.toggle_conversion_factor(item),
											() => {
												if (show_batch_dialog)
													return frappe.db.get_value("Item", item.item_code, ["has_batch_no", "has_serial_no"])
														.then((r) => {
															if (r.message &&
																(r.message.has_batch_no || r.message.has_serial_no)) {
																frappe.flags.hide_serial_batch_dialog = false;
															}
														});
											},
											() => {
												// check if batch serial selector is disabled or not
												if (show_batch_dialog && !frappe.flags.hide_serial_batch_dialog)
													return frappe.db.get_single_value('Stock Settings', 'disable_serial_no_and_batch_selector')
														.then((value) => {
															if (value) {
																frappe.flags.hide_serial_batch_dialog = true;
															}
														});
											},
											() => {
												if (show_batch_dialog && !frappe.flags.hide_serial_batch_dialog) {
													var d = locals[cdt][cdn];
													$.each(r.message, function (k, v) {
														if (!d[k]) d[k] = v;
													});
			
													if (d.has_batch_no && d.has_serial_no) {
														d.batch_no = undefined;
													}
			
													erpnext.show_serial_batch_selector(me.frm, d, (item) => {
														me.frm.script_manager.trigger('qty', item.doctype, item.name);
														if (!me.frm.doc.set_warehouse)
															me.frm.script_manager.trigger('warehouse', item.doctype, item.name);
														me.apply_price_list(item, true);
													}, undefined, !frappe.flags.hide_serial_batch_dialog);
												}
											},
											() => me.conversion_factor(doc, cdt, cdn, true),
											() => me.remove_pricing_rule(item),
											() => {
												if (item.apply_rule_on_other_items) {
													let key = item.name;
													me.apply_rule_on_other_items({ key: item });
												}
											},
											() => {
												var company_currency = me.get_company_currency();
												me.update_item_grid_labels(company_currency);
											}
										]);
									}
								}
							});
						}
					}
				}
				else{
					if (item.item_code || item.serial_no) {
						if (!this.validate_company_and_party()) {
							this.frm.fields_dict["items"].grid.grid_rows[item.idx - 1].remove();
						} else {
							item.pricing_rules = ''
							return this.frm.call({
								method: "erpnext.stock.get_item_details.get_item_details",
								child: item,
								args: {
									doc: me.frm.doc,
									args: {
										item_code: item.item_code,
										barcode: item.barcode,
										serial_no: item.serial_no,
										batch_no: item.batch_no,
										set_warehouse: me.frm.doc.set_warehouse,
										warehouse: item.warehouse,
										customer: me.frm.doc.customer || me.frm.doc.party_name,
										quotation_to: me.frm.doc.quotation_to,
										supplier: me.frm.doc.supplier,
										currency: me.frm.doc.currency,
										update_stock: update_stock,
										conversion_rate: me.frm.doc.conversion_rate,
										price_list: me.frm.doc.selling_price_list || me.frm.doc.buying_price_list,
										price_list_currency: me.frm.doc.price_list_currency,
										plc_conversion_rate: me.frm.doc.plc_conversion_rate,
										company: me.frm.doc.company,
										order_type: me.frm.doc.order_type,
										is_pos: cint(me.frm.doc.is_pos),
										is_return: cint(me.frm.doc.is_return),
										is_subcontracted: me.frm.doc.is_subcontracted,
										ignore_pricing_rule: me.frm.doc.ignore_pricing_rule,
										doctype: me.frm.doc.doctype,
										name: me.frm.doc.name,
										project: item.project || me.frm.doc.project,
										qty: item.qty || 1,
										net_rate: item.rate,
										stock_qty: item.stock_qty,
										conversion_factor: item.conversion_factor,
										weight_per_unit: item.weight_per_unit,
										uom: item.uom,
										weight_uom: item.weight_uom,
										manufacturer: item.manufacturer,
										stock_uom: item.stock_uom,
										pos_profile: cint(me.frm.doc.is_pos) ? me.frm.doc.pos_profile : '',
										cost_center: item.cost_center,
										tax_category: me.frm.doc.tax_category,
										item_tax_template: item.item_tax_template,
										child_docname: item.name,
										is_old_subcontracting_flow: me.frm.doc.is_old_subcontracting_flow,
									}
								},
			
								callback: function (r) {
									if (!r.exc) {
										frappe.run_serially([
											() => {
												var d = locals[cdt][cdn];
												cur_frm.script_manager.trigger('item_tax_template', cdt, cdn)
												me.add_taxes_from_item_tax_template(d.item_tax_rate);
												if (d.free_item_data) {
													me.apply_product_discount(d);
												}
											},
											() => {
												// for internal customer instead of pricing rule directly apply valuation rate on item
												if (me.frm.doc.is_internal_customer || me.frm.doc.is_internal_supplier) {
													me.get_incoming_rate(item, me.frm.posting_date, me.frm.posting_time,
														me.frm.doc.doctype, me.frm.doc.company);
												} else {
													me.frm.script_manager.trigger("price_list_rate", cdt, cdn);
												}
											},
											() => {
												if (me.frm.doc.is_internal_customer || me.frm.doc.is_internal_supplier) {
													me.calculate_taxes_and_totals();
												}
											},
											() => me.toggle_conversion_factor(item),
											() => {
												if (show_batch_dialog)
													return frappe.db.get_value("Item", item.item_code, ["has_batch_no", "has_serial_no"])
														.then((r) => {
															if (r.message &&
																(r.message.has_batch_no || r.message.has_serial_no)) {
																frappe.flags.hide_serial_batch_dialog = false;
															}
														});
											},
											() => {
												// check if batch serial selector is disabled or not
												if (show_batch_dialog && !frappe.flags.hide_serial_batch_dialog)
													return frappe.db.get_single_value('Stock Settings', 'disable_serial_no_and_batch_selector')
														.then((value) => {
															if (value) {
																frappe.flags.hide_serial_batch_dialog = true;
															}
														});
											},
											() => {
												if (show_batch_dialog && !frappe.flags.hide_serial_batch_dialog) {
													var d = locals[cdt][cdn];
													$.each(r.message, function (k, v) {
														if (!d[k]) d[k] = v;
													});
			
													if (d.has_batch_no && d.has_serial_no) {
														d.batch_no = undefined;
													}
			
													erpnext.show_serial_batch_selector(me.frm, d, (item) => {
														me.frm.script_manager.trigger('qty', item.doctype, item.name);
														if (!me.frm.doc.set_warehouse)
															me.frm.script_manager.trigger('warehouse', item.doctype, item.name);
														me.apply_price_list(item, true);
													}, undefined, !frappe.flags.hide_serial_batch_dialog);
												}
											},
											() => me.conversion_factor(doc, cdt, cdn, true),
											() => me.remove_pricing_rule(item),
											() => {
												if (item.apply_rule_on_other_items) {
													let key = item.name;
													me.apply_rule_on_other_items({ key: item });
												}
											},
											() => {
												var company_currency = me.get_company_currency();
												me.update_item_grid_labels(company_currency);
											}
										]);
									}
								}
							});
						}
					}
				}
			})
		}
		else{
			if (item.item_code || item.serial_no) {
				if (!this.validate_company_and_party()) {
					this.frm.fields_dict["items"].grid.grid_rows[item.idx - 1].remove();
				} else {
					item.pricing_rules = ''
					return this.frm.call({
						method: "erpnext.stock.get_item_details.get_item_details",
						child: item,
						args: {
							doc: me.frm.doc,
							args: {
								item_code: item.item_code,
								barcode: item.barcode,
								serial_no: item.serial_no,
								batch_no: item.batch_no,
								set_warehouse: me.frm.doc.set_warehouse,
								warehouse: item.warehouse,
								customer: me.frm.doc.customer || me.frm.doc.party_name,
								quotation_to: me.frm.doc.quotation_to,
								supplier: me.frm.doc.supplier,
								currency: me.frm.doc.currency,
								update_stock: update_stock,
								conversion_rate: me.frm.doc.conversion_rate,
								price_list: me.frm.doc.selling_price_list || me.frm.doc.buying_price_list,
								price_list_currency: me.frm.doc.price_list_currency,
								plc_conversion_rate: me.frm.doc.plc_conversion_rate,
								company: me.frm.doc.company,
								order_type: me.frm.doc.order_type,
								is_pos: cint(me.frm.doc.is_pos),
								is_return: cint(me.frm.doc.is_return),
								is_subcontracted: me.frm.doc.is_subcontracted,
								ignore_pricing_rule: me.frm.doc.ignore_pricing_rule,
								doctype: me.frm.doc.doctype,
								name: me.frm.doc.name,
								project: item.project || me.frm.doc.project,
								qty: item.qty || 1,
								net_rate: item.rate,
								stock_qty: item.stock_qty,
								conversion_factor: item.conversion_factor,
								weight_per_unit: item.weight_per_unit,
								uom: item.uom,
								weight_uom: item.weight_uom,
								manufacturer: item.manufacturer,
								stock_uom: item.stock_uom,
								pos_profile: cint(me.frm.doc.is_pos) ? me.frm.doc.pos_profile : '',
								cost_center: item.cost_center,
								tax_category: me.frm.doc.tax_category,
								item_tax_template: item.item_tax_template,
								child_docname: item.name,
								is_old_subcontracting_flow: me.frm.doc.is_old_subcontracting_flow,
							}
						},
	
						callback: function (r) {
							if (!r.exc) {
								frappe.run_serially([
									() => {
										var d = locals[cdt][cdn];
										cur_frm.script_manager.trigger('item_tax_template', cdt, cdn)
										me.add_taxes_from_item_tax_template(d.item_tax_rate);
										if (d.free_item_data) {
											me.apply_product_discount(d);
										}
									},
									() => {
										// for internal customer instead of pricing rule directly apply valuation rate on item
										if (me.frm.doc.is_internal_customer || me.frm.doc.is_internal_supplier) {
											me.get_incoming_rate(item, me.frm.posting_date, me.frm.posting_time,
												me.frm.doc.doctype, me.frm.doc.company);
										} else {
											me.frm.script_manager.trigger("price_list_rate", cdt, cdn);
										}
									},
									() => {
										if (me.frm.doc.is_internal_customer || me.frm.doc.is_internal_supplier) {
											me.calculate_taxes_and_totals();
										}
									},
									() => me.toggle_conversion_factor(item),
									() => {
										if (show_batch_dialog)
											return frappe.db.get_value("Item", item.item_code, ["has_batch_no", "has_serial_no"])
												.then((r) => {
													if (r.message &&
														(r.message.has_batch_no || r.message.has_serial_no)) {
														frappe.flags.hide_serial_batch_dialog = false;
													}
												});
									},
									() => {
										// check if batch serial selector is disabled or not
										if (show_batch_dialog && !frappe.flags.hide_serial_batch_dialog)
											return frappe.db.get_single_value('Stock Settings', 'disable_serial_no_and_batch_selector')
												.then((value) => {
													if (value) {
														frappe.flags.hide_serial_batch_dialog = true;
													}
												});
									},
									() => {
										if (show_batch_dialog && !frappe.flags.hide_serial_batch_dialog) {
											var d = locals[cdt][cdn];
											$.each(r.message, function (k, v) {
												if (!d[k]) d[k] = v;
											});
	
											if (d.has_batch_no && d.has_serial_no) {
												d.batch_no = undefined;
											}
	
											erpnext.show_serial_batch_selector(me.frm, d, (item) => {
												me.frm.script_manager.trigger('qty', item.doctype, item.name);
												if (!me.frm.doc.set_warehouse)
													me.frm.script_manager.trigger('warehouse', item.doctype, item.name);
												me.apply_price_list(item, true);
											}, undefined, !frappe.flags.hide_serial_batch_dialog);
										}
									},
									() => me.conversion_factor(doc, cdt, cdn, true),
									() => me.remove_pricing_rule(item),
									() => {
										if (item.apply_rule_on_other_items) {
											let key = item.name;
											me.apply_rule_on_other_items({ key: item });
										}
									},
									() => {
										var company_currency = me.get_company_currency();
										me.update_item_grid_labels(company_currency);
									}
								]);
							}
						}
					});
				}
			}
		}
	}

	price_list_rate(doc, cdt, cdn) {
		var item = frappe.get_doc(cdt, cdn);
		frappe.model.round_floats_in(item, ["price_list_rate", "discount_percentage"]);

		// check if child doctype is Sales Order Item/Quotation Item and calculate the rate
		if (in_list(["Quotation Item", "Sales Order Item", "Delivery Note Item", "Sales Invoice Item", "POS Invoice Item", "Purchase Invoice Item", "Purchase Order Item", "Purchase Receipt Item"]), cdt)
			this.apply_pricing_rule_on_item(item);
		else
			item.rate = flt(item.price_list_rate * (1 - item.discount_percentage / 100.0),
				precision("rate", item));

		this.calculate_taxes_and_totals();
	}

	margin_rate_or_amount(doc, cdt, cdn) {
		// calculated the revised total margin and rate on margin rate changes
		let item = frappe.get_doc(cdt, cdn);
		this.apply_pricing_rule_on_item(item);
		this.calculate_taxes_and_totals();
		cur_frm.refresh_fields();
	}

	margin_type(doc, cdt, cdn) {
		// calculate the revised total margin and rate on margin type changes
		let item = frappe.get_doc(cdt, cdn);
		if (!item.margin_type) {
			frappe.model.set_value(cdt, cdn, "margin_rate_or_amount", 0);
		} else {
			this.apply_pricing_rule_on_item(item, doc, cdt, cdn);
			this.calculate_taxes_and_totals();
			cur_frm.refresh_fields();
		}
	}

	get_incoming_rate(item, posting_date, posting_time, voucher_type, company) {

		let item_args = {
			'item_code': item.item_code,
			'warehouse': in_list('Purchase Receipt', 'Purchase Invoice') ? item.from_warehouse : item.warehouse,
			'posting_date': posting_date,
			'posting_time': posting_time,
			'qty': item.qty * item.conversion_factor,
			'serial_no': item.serial_no,
			'batch_no': item.batch_no,
			'voucher_type': voucher_type,
			'company': company,
			'allow_zero_valuation_rate': item.allow_zero_valuation_rate
		}

		frappe.call({
			method: 'erpnext.stock.utils.get_incoming_rate',
			args: {
				args: item_args
			},
			callback: function (r) {
				frappe.model.set_value(item.doctype, item.name, 'rate', r.message * item.conversion_factor);
			}
		});
	}

	add_taxes_from_item_tax_template(item_tax_map) {
		let me = this;

		if (item_tax_map && cint(frappe.defaults.get_default("add_taxes_from_item_tax_template"))) {
			if (typeof (item_tax_map) == "string") {
				item_tax_map = JSON.parse(item_tax_map);
			}

			$.each(item_tax_map, function (tax, rate) {
				let found = (me.frm.doc.taxes || []).find(d => d.account_head === tax);
				if (!found) {
					let child = frappe.model.add_child(me.frm.doc, "taxes");
					child.charge_type = "On Net Total";
					child.account_head = tax;
					child.rate = 0;
				}
			});
		}
	}

	serial_no(doc, cdt, cdn) {
		var me = this;
		var item = frappe.get_doc(cdt, cdn);

		if (item && item.doctype === 'Purchase Receipt Item Supplied') {
			return;
		}

		if (item && item.serial_no) {
			if (!item.item_code) {
				this.frm.trigger("item_code", cdt, cdn);
			}
			else {
				// Replace all occurences of comma with line feed
				item.serial_no = item.serial_no.replace(/,/g, '\n');
				item.conversion_factor = item.conversion_factor || 1;
				refresh_field("serial_no", item.name, item.parentfield);
				if (!doc.is_return && cint(frappe.user_defaults.set_qty_in_transactions_based_on_serial_no_input)) {
					setTimeout(() => {
						me.update_qty(cdt, cdn);
					}, 10000);
				}
			}
		}
	}

	update_qty(cdt, cdn) {
		var valid_serial_nos = [];
		var serialnos = [];
		var item = frappe.get_doc(cdt, cdn);
		serialnos = item.serial_no.split("\n");
		for (var i = 0; i < serialnos.length; i++) {
			if (serialnos[i] != "") {
				valid_serial_nos.push(serialnos[i]);
			}
		}
		frappe.model.set_value(item.doctype, item.name,
			"qty", valid_serial_nos.length / item.conversion_factor);
		frappe.model.set_value(item.doctype, item.name, "stock_qty", valid_serial_nos.length);
	}

	validate() {
		this.calculate_taxes_and_totals(false);
	}

	update_stock() {
		this.frm.trigger('set_default_internal_warehouse');
	}

	set_default_internal_warehouse() {
		let me = this;
		if ((this.frm.doc.doctype === 'Sales Invoice' && me.frm.doc.update_stock)
			|| this.frm.doc.doctype == 'Delivery Note') {
			if (this.frm.doc.is_internal_customer && this.frm.doc.company === this.frm.doc.represents_company) {
				frappe.db.get_value('Company', this.frm.doc.company, 'default_in_transit_warehouse', function (value) {
					me.frm.set_value('set_target_warehouse', value.default_in_transit_warehouse);
				});
			}
		}

		if ((this.frm.doc.doctype === 'Purchase Invoice' && me.frm.doc.update_stock)
			|| this.frm.doc.doctype == 'Purchase Receipt') {
			if (this.frm.doc.is_internal_supplier && this.frm.doc.company === this.frm.doc.represents_company) {
				frappe.db.get_value('Company', this.frm.doc.company, 'default_in_transit_warehouse', function (value) {
					me.frm.set_value('set_from_warehouse', value.default_in_transit_warehouse);
				});
			}
		}
	}

	company() {
		var me = this;
		var set_pricing = function () {
			if (me.frm.doc.company && me.frm.fields_dict.currency) {
				var company_currency = me.get_company_currency();
				var company_doc = frappe.get_doc(":Company", me.frm.doc.company);

				if (!me.frm.doc.currency) {
					me.frm.set_value("currency", company_currency);
				}

				if (me.frm.doc.currency == company_currency) {
					me.frm.set_value("conversion_rate", 1.0);
				}
				if (me.frm.doc.price_list_currency == company_currency) {
					me.frm.set_value('plc_conversion_rate', 1.0);
				}
				if (company_doc.default_letter_head) {
					if (me.frm.fields_dict.letter_head) {
						me.frm.set_value("letter_head", company_doc.default_letter_head);
					}
				}
				let selling_doctypes_for_tc = ["Sales Invoice", "Quotation", "Sales Order", "Delivery Note"];
				if (company_doc.default_selling_terms && frappe.meta.has_field(me.frm.doc.doctype, "tc_name") &&
					selling_doctypes_for_tc.indexOf(me.frm.doc.doctype) != -1) {
					me.frm.set_value("tc_name", company_doc.default_selling_terms);
				}
				let buying_doctypes_for_tc = ["Request for Quotation", "Supplier Quotation", "Purchase Order",
					"Material Request", "Purchase Receipt"];
				// Purchase Invoice is excluded as per issue #3345
				if (company_doc.default_buying_terms && frappe.meta.has_field(me.frm.doc.doctype, "tc_name") &&
					buying_doctypes_for_tc.indexOf(me.frm.doc.doctype) != -1) {
					me.frm.set_value("tc_name", company_doc.default_buying_terms);
				}

				frappe.run_serially([
					() => me.frm.script_manager.trigger("currency"),
					() => me.update_item_tax_map(),
					() => me.apply_default_taxes(),
					() => me.apply_pricing_rule()
				]);
			}
		}

		var set_party_account = function (set_pricing) {
			if (in_list(["Sales Invoice", "Purchase Invoice"], me.frm.doc.doctype)) {
				if (me.frm.doc.doctype == "Sales Invoice") {
					var party_type = "Customer";
					var party_account_field = 'debit_to';
				} else {
					var party_type = "Supplier";
					var party_account_field = 'credit_to';
				}

				var party = me.frm.doc[frappe.model.scrub(party_type)];
				if (party && me.frm.doc.company) {
					return frappe.call({
						method: "erpnext.accounts.party.get_party_account",
						args: {
							company: me.frm.doc.company,
							party_type: party_type,
							party: party
						},
						callback: function (r) {
							if (!r.exc && r.message) {
								me.frm.set_value(party_account_field, r.message);
								set_pricing();
							}
						}
					});
				} else {
					set_pricing();
				}
			} else {
				set_pricing();
			}

		}

		if (frappe.meta.get_docfield(this.frm.doctype, "shipping_address") &&
			in_list(['Purchase Order', 'Purchase Receipt', 'Purchase Invoice'], this.frm.doctype)) {
			erpnext.utils.get_shipping_address(this.frm, function () {
				set_party_account(set_pricing);
			});

		} else {
			set_party_account(set_pricing);
		}

		if (this.frm.doc.company) {
			erpnext.last_selected_company = this.frm.doc.company;
		}
	}

	transaction_date() {
		if (this.frm.doc.transaction_date) {
			this.frm.transaction_date = this.frm.doc.transaction_date;
			frappe.ui.form.trigger(this.frm.doc.doctype, "currency");
		}
	}

	posting_date() {
		var me = this;
		if (this.frm.doc.posting_date) {
			this.frm.posting_date = this.frm.doc.posting_date;

			if ((this.frm.doc.doctype == "Sales Invoice" && this.frm.doc.customer) ||
				(this.frm.doc.doctype == "Purchase Invoice" && this.frm.doc.supplier)) {
				return frappe.call({
					method: "erpnext.accounts.party.get_due_date",
					args: {
						"posting_date": me.frm.doc.posting_date,
						"party_type": me.frm.doc.doctype == "Sales Invoice" ? "Customer" : "Supplier",
						"bill_date": me.frm.doc.bill_date,
						"party": me.frm.doc.doctype == "Sales Invoice" ? me.frm.doc.customer : me.frm.doc.supplier,
						"company": me.frm.doc.company
					},
					callback: function (r, rt) {
						if (r.message) {
							me.frm.doc.due_date = r.message;
							refresh_field("due_date");
							frappe.ui.form.trigger(me.frm.doc.doctype, "currency");
							me.recalculate_terms();
						}
					}
				})
			} else {
				frappe.ui.form.trigger(me.frm.doc.doctype, "currency");
			}
		}
	}

	due_date() {
		// due_date is to be changed, payment terms template and/or payment schedule must
		// be removed as due_date is automatically changed based on payment terms
		if (this.frm.doc.doctype != "Purchase Invoice") {
			if (this.frm.doc.due_date && !this.frm.updating_party_details && !this.frm.doc.is_pos) {
				if (this.frm.doc.payment_terms_template ||
					(this.frm.doc.payment_schedule && this.frm.doc.payment_schedule.length)) {
					var message1 = "";
					var message2 = "";
					var final_message = __("Please clear the") + " ";

					if (this.frm.doc.payment_terms_template) {
						message1 = __("selected Payment Terms Template");
						final_message = final_message + message1;
					}

					if ((this.frm.doc.payment_schedule || []).length) {
						message2 = __("Payment Schedule Table");
						if (message1.length !== 0) message2 = " and " + message2;
						final_message = final_message + message2;
					}
					frappe.msgprint(final_message);
				}
			}
		}
	}

	bill_date() {
		this.posting_date();
	}

	recalculate_terms() {
		const doc = this.frm.doc;
		if (doc.payment_terms_template) {
			this.payment_terms_template();
		} else if (doc.payment_schedule) {
			const me = this;
			doc.payment_schedule.forEach(
				function (term) {
					if (term.payment_term) {
						me.payment_term(doc, term.doctype, term.name);
					} else {
						frappe.model.set_value(
							term.doctype, term.name, 'due_date',
							doc.posting_date || doc.transaction_date
						);
					}
				}
			);
		}
	}

	get_company_currency() {
		return erpnext.get_currency(this.frm.doc.company);
	}

	contact_person() {
		erpnext.utils.get_contact_details(this.frm);
	}

	currency() {
		// The transaction date be either transaction_date (from orders) or posting_date (from invoices)
		let transaction_date = this.frm.doc.transaction_date || this.frm.doc.posting_date;

		let me = this;
		this.set_dynamic_labels();
		let company_currency = this.get_company_currency();
		// Added `ignore_price_list` to determine if document is loading after mapping from another doc
		if (this.frm.doc.currency && this.frm.doc.currency !== company_currency
			&& !(this.frm.doc.__onload && this.frm.doc.__onload.ignore_price_list)) {

			this.get_exchange_rate(transaction_date, this.frm.doc.currency, company_currency,
				function (exchange_rate) {
					if (exchange_rate != me.frm.doc.conversion_rate) {
						me.set_margin_amount_based_on_currency(exchange_rate);
						me.set_actual_charges_based_on_currency(exchange_rate);
						me.frm.set_value("conversion_rate", exchange_rate);
					}
				});
		} else {
			// company currency and doc currency is same
			// this will prevent unnecessary conversion rate triggers
			if (this.frm.doc.currency === this.get_company_currency()) {
				this.frm.set_value("conversion_rate", 1.0);
			} else {
				this.conversion_rate();
			}
		}
	}

	conversion_rate() {
		const me = this.frm;
		if (this.frm.doc.currency === this.get_company_currency()) {
			this.frm.set_value("conversion_rate", 1.0);
		}
		if (this.frm.doc.currency === this.frm.doc.price_list_currency &&
			this.frm.doc.plc_conversion_rate !== this.frm.doc.conversion_rate) {
			this.frm.set_value("plc_conversion_rate", this.frm.doc.conversion_rate);
		}

		if (flt(this.frm.doc.conversion_rate) > 0.0) {
			if (this.frm.doc.__onload && this.frm.doc.__onload.ignore_price_list) {
				this.calculate_taxes_and_totals();
			} else if (!this.in_apply_price_list) {
				this.apply_price_list();
			}

		}
		// Make read only if Accounts Settings doesn't allow stale rates
		this.frm.set_df_property("conversion_rate", "read_only", erpnext.stale_rate_allowed() ? 0 : 1);
	}

	shipping_rule() {
		var me = this;
		if (this.frm.doc.shipping_rule) {
			return this.frm.call({
				doc: this.frm.doc,
				method: "apply_shipping_rule",
				callback: function (r) {
					me._calculate_taxes_and_totals();
				}
			}).fail(() => this.frm.set_value('shipping_rule', ''));
		}
	}

	set_margin_amount_based_on_currency(exchange_rate) {
		if (in_list(["Quotation", "Sales Order", "Delivery Note", "Sales Invoice", "Purchase Invoice", "Purchase Order", "Purchase Receipt"]), this.frm.doc.doctype) {
			var me = this;
			$.each(this.frm.doc.items || [], function (i, d) {
				if (d.margin_type == "Amount") {
					frappe.model.set_value(d.doctype, d.name, "margin_rate_or_amount",
						flt(d.margin_rate_or_amount) / flt(exchange_rate));
				}
			});
		}
	}

	set_actual_charges_based_on_currency(exchange_rate) {
		var me = this;
		$.each(this.frm.doc.taxes || [], function (i, d) {
			if (d.charge_type == "Actual") {
				frappe.model.set_value(d.doctype, d.name, "tax_amount",
					flt(d.base_tax_amount) / flt(exchange_rate));
			}
		});
	}

	get_exchange_rate(transaction_date, from_currency, to_currency, callback) {
		var args;
		if (["Quotation", "Sales Order", "Delivery Note", "Sales Invoice"].includes(this.frm.doctype)) {
			args = "for_selling";
		}
		else if (["Purchase Order", "Purchase Receipt", "Purchase Invoice"].includes(this.frm.doctype)) {
			args = "for_buying";
		}

		if (!transaction_date || !from_currency || !to_currency) return;
		return frappe.call({
			method: "erpnext.setup.utils.get_exchange_rate",
			args: {
				transaction_date: transaction_date,
				from_currency: from_currency,
				to_currency: to_currency,
				args: args
			},
			freeze: true,
			freeze_message: __("Fetching exchange rates ..."),
			callback: function (r) {
				callback(flt(r.message));
			}
		});
	}

	price_list_currency() {
		var me = this;
		this.set_dynamic_labels();

		var company_currency = this.get_company_currency();
		// Added `ignore_price_list` to determine if document is loading after mapping from another doc
		if (this.frm.doc.price_list_currency !== company_currency &&
			!(this.frm.doc.__onload && this.frm.doc.__onload.ignore_price_list)) {
			this.get_exchange_rate(this.frm.doc.posting_date, this.frm.doc.price_list_currency, company_currency,
				function (exchange_rate) {
					me.frm.set_value("plc_conversion_rate", exchange_rate);
				});
		} else {
			this.plc_conversion_rate();
		}
	}

	plc_conversion_rate() {
		if (this.frm.doc.price_list_currency === this.get_company_currency()) {
			this.frm.set_value("plc_conversion_rate", 1.0);
		} else if (this.frm.doc.price_list_currency === this.frm.doc.currency
			&& this.frm.doc.plc_conversion_rate && cint(this.frm.doc.plc_conversion_rate) != 1 &&
			cint(this.frm.doc.plc_conversion_rate) != cint(this.frm.doc.conversion_rate)) {
			this.frm.set_value("conversion_rate", this.frm.doc.plc_conversion_rate);
		}

		if (!this.in_apply_price_list) {
			this.apply_price_list(null, true);
		}
	}

	uom(doc, cdt, cdn) {
		var me = this;
		var item = frappe.get_doc(cdt, cdn);
		item.pricing_rules = ''
		if (item.item_code && item.uom) {
			return this.frm.call({
				method: "erpnext.stock.get_item_details.get_conversion_factor",
				args: {
					item_code: item.item_code,
					uom: item.uom
				},
				callback: function (r) {
					if (!r.exc) {
						frappe.model.set_value(cdt, cdn, 'conversion_factor', r.message.conversion_factor);
					}
				}
			});
		}
		me.calculate_stock_uom_rate(doc, cdt, cdn);
	}

	conversion_factor(doc, cdt, cdn, dont_fetch_price_list_rate) {
		if (frappe.meta.get_docfield(cdt, "stock_qty", cdn)) {
			var item = frappe.get_doc(cdt, cdn);
			frappe.model.round_floats_in(item, ["qty", "conversion_factor"]);
			item.stock_qty = flt(item.qty * item.conversion_factor, precision("stock_qty", item));
			refresh_field("stock_qty", item.name, item.parentfield);
			this.toggle_conversion_factor(item);

			if (doc.doctype != "Material Request") {
				item.total_weight = flt(item.stock_qty * item.weight_per_unit);
				refresh_field("total_weight", item.name, item.parentfield);
				this.calculate_net_weight();
			}

			// for handling customization not to fetch price list rate
			if (frappe.flags.dont_fetch_price_list_rate) {
				return
			}

			if (!dont_fetch_price_list_rate &&
				frappe.meta.has_field(doc.doctype, "price_list_currency")) {
				this.apply_price_list(item, true);
			}
			this.calculate_stock_uom_rate(doc, cdt, cdn);
		}
	}

	is_a_mapped_document(item) {
		const mapped_item_field_map = {
			"Delivery Note Item": ["si_detail", "so_detail", "dn_detail"],
			"Sales Invoice Item": ["dn_detail", "so_detail", "sales_invoice_item"],
			"Purchase Receipt Item": ["purchase_order_item", "purchase_invoice_item", "purchase_receipt_item"],
			"Purchase Invoice Item": ["purchase_order_item", "pr_detail", "po_detail"],
		};
		const mappped_fields = mapped_item_field_map[item.doctype] || [];

		return mappped_fields
			.map((field) => item[field])
			.filter(Boolean).length > 0;
	}

	batch_no(doc, cdt, cdn) {
		let item = frappe.get_doc(cdt, cdn);
		if (!this.is_a_mapped_document(item)) {
			this.apply_price_list(item, true);
		}
	}

	toggle_conversion_factor(item) {
		// toggle read only property for conversion factor field if the uom and stock uom are same
		if (this.frm.get_field('items').grid.fields_map.conversion_factor) {
			this.frm.fields_dict.items.grid.toggle_enable("conversion_factor",
				((item.uom != item.stock_uom) && !frappe.meta.get_docfield(cur_frm.fields_dict.items.grid.doctype, "conversion_factor").read_only) ? true : false);
		}

	}

	qty(doc, cdt, cdn) {
		let item = frappe.get_doc(cdt, cdn);
		// item.pricing_rules = ''
		frappe.run_serially([
			() => this.remove_pricing_rule(item),
			() => this.conversion_factor(doc, cdt, cdn, true),
			() => this.calculate_stock_uom_rate(doc, cdt, cdn),
			() => this.apply_pricing_rule(item, true)
		]);
	}

	calculate_stock_uom_rate(doc, cdt, cdn) {
		let item = frappe.get_doc(cdt, cdn);
		item.stock_uom_rate = flt(item.rate) / flt(item.conversion_factor);
		refresh_field("stock_uom_rate", item.name, item.parentfield);
	}
	service_stop_date(frm, cdt, cdn) {
		var child = locals[cdt][cdn];

		if (child.service_stop_date) {
			let start_date = Date.parse(child.service_start_date);
			let end_date = Date.parse(child.service_end_date);
			let stop_date = Date.parse(child.service_stop_date);

			if (stop_date < start_date) {
				frappe.model.set_value(cdt, cdn, "service_stop_date", "");
				frappe.throw(__("Service Stop Date cannot be before Service Start Date"));
			} else if (stop_date > end_date) {
				frappe.model.set_value(cdt, cdn, "service_stop_date", "");
				frappe.throw(__("Service Stop Date cannot be after Service End Date"));
			}
		}
	}

	service_start_date(frm, cdt, cdn) {
		var child = locals[cdt][cdn];

		if (child.service_start_date) {
			frappe.call({
				"method": "erpnext.stock.get_item_details.calculate_service_end_date",
				args: { "args": child },
				callback: function (r) {
					frappe.model.set_value(cdt, cdn, "service_end_date", r.message.service_end_date);
				}
			})
		}
	}

	calculate_net_weight() {
		/* Calculate Total Net Weight then further applied shipping rule to calculate shipping charges.*/
		var me = this;
		this.frm.doc.total_net_weight = 0.0;

		$.each(this.frm.doc["items"] || [], function (i, item) {
			me.frm.doc.total_net_weight += flt(item.total_weight);
		});
		refresh_field("total_net_weight");
		this.shipping_rule();
	}

	set_dynamic_labels() {
		// What TODO? should we make price list system non-mandatory?
		this.frm.toggle_reqd("plc_conversion_rate",
			!!(this.frm.doc.price_list_name && this.frm.doc.price_list_currency));

		var company_currency = this.get_company_currency();
		this.change_form_labels(company_currency);
		this.change_grid_labels(company_currency);
		this.frm.refresh_fields();
	}

	change_form_labels(company_currency) {
		var me = this;

		this.frm.set_currency_labels(["base_total", "base_net_total", "base_total_taxes_and_charges",
			"base_discount_amount", "base_grand_total", "base_rounded_total", "base_in_words",
			"base_taxes_and_charges_added", "base_taxes_and_charges_deducted", "total_amount_to_pay",
			"base_paid_amount", "base_write_off_amount", "base_change_amount", "base_operating_cost",
			"base_raw_material_cost", "base_total_cost", "base_scrap_material_cost",
			"base_rounding_adjustment"], company_currency);

		this.frm.set_currency_labels(["total", "net_total", "total_taxes_and_charges", "discount_amount",
			"grand_total", "taxes_and_charges_added", "taxes_and_charges_deducted", "tax_withholding_net_total",
			"rounded_total", "in_words", "paid_amount", "write_off_amount", "operating_cost",
			"scrap_material_cost", "rounding_adjustment", "raw_material_cost",
			"total_cost"], this.frm.doc.currency);

		this.frm.set_currency_labels(["outstanding_amount", "total_advance"],
			this.frm.doc.party_account_currency);

		cur_frm.set_df_property("conversion_rate", "description", "1 " + this.frm.doc.currency
			+ " = [?] " + company_currency);

		if (this.frm.doc.price_list_currency && this.frm.doc.price_list_currency != company_currency) {
			cur_frm.set_df_property("plc_conversion_rate", "description", "1 "
				+ this.frm.doc.price_list_currency + " = [?] " + company_currency);
		}

		// toggle fields
		this.frm.toggle_display(["conversion_rate", "base_total", "base_net_total", "base_tax_withholding_net_total",
			"base_total_taxes_and_charges", "base_taxes_and_charges_added", "base_taxes_and_charges_deducted",
			"base_grand_total", "base_rounded_total", "base_in_words", "base_discount_amount",
			"base_paid_amount", "base_write_off_amount", "base_operating_cost", "base_raw_material_cost",
			"base_total_cost", "base_scrap_material_cost", "base_rounding_adjustment"],
			this.frm.doc.currency != company_currency);

		this.frm.toggle_display(["plc_conversion_rate", "price_list_currency"],
			this.frm.doc.price_list_currency != company_currency);

		var show = cint(cur_frm.doc.discount_amount) ||
			((cur_frm.doc.taxes || []).filter(function (d) { return d.included_in_print_rate === 1 }).length);

		if (frappe.meta.get_docfield(cur_frm.doctype, "net_total"))
			cur_frm.toggle_display("net_total", false);

		if (frappe.meta.get_docfield(cur_frm.doctype, "base_net_total"))
			cur_frm.toggle_display("base_net_total", (show && (me.frm.doc.currency != company_currency)));

	}

	change_grid_labels(company_currency) {
		var me = this;

		this.update_item_grid_labels(company_currency);

		this.toggle_item_grid_columns(company_currency);

		if (this.frm.doc.operations && this.frm.doc.operations.length > 0) {
			this.frm.set_currency_labels(["operating_cost", "hour_rate"], this.frm.doc.currency, "operations");
			this.frm.set_currency_labels(["base_operating_cost", "base_hour_rate"], company_currency, "operations");

			var item_grid = this.frm.fields_dict["operations"].grid;
			$.each(["base_operating_cost", "base_hour_rate"], function (i, fname) {
				if (frappe.meta.get_docfield(item_grid.doctype, fname))
					item_grid.set_column_disp(fname, me.frm.doc.currency != company_currency);
			});
		}

		if (this.frm.doc.scrap_items && this.frm.doc.scrap_items.length > 0) {
			this.frm.set_currency_labels(["rate", "amount"], this.frm.doc.currency, "scrap_items");
			this.frm.set_currency_labels(["base_rate", "base_amount"], company_currency, "scrap_items");

			var item_grid = this.frm.fields_dict["scrap_items"].grid;
			$.each(["base_rate", "base_amount"], function (i, fname) {
				if (frappe.meta.get_docfield(item_grid.doctype, fname))
					item_grid.set_column_disp(fname, me.frm.doc.currency != company_currency);
			});
		}

		if (this.frm.doc.taxes && this.frm.doc.taxes.length > 0) {
			this.frm.set_currency_labels(["tax_amount", "total", "tax_amount_after_discount"], this.frm.doc.currency, "taxes");

			this.frm.set_currency_labels(["base_tax_amount", "base_total", "base_tax_amount_after_discount"], company_currency, "taxes");
		}

		if (this.frm.doc.advances && this.frm.doc.advances.length > 0) {
			this.frm.set_currency_labels(["advance_amount", "allocated_amount"],
				this.frm.doc.party_account_currency, "advances");
		}

		this.update_payment_schedule_grid_labels(company_currency);
	}

	update_item_grid_labels(company_currency) {
		this.frm.set_currency_labels([
			"base_rate", "base_net_rate", "base_price_list_rate",
			"base_amount", "base_net_amount", "base_rate_with_margin"
		], company_currency, "items");

		this.frm.set_currency_labels([
			"rate", "net_rate", "price_list_rate", "amount",
			"net_amount", "stock_uom_rate", "rate_with_margin"
		], this.frm.doc.currency, "items");
	}

	update_payment_schedule_grid_labels(company_currency) {
		const me = this;
		if (this.frm.doc.payment_schedule && this.frm.doc.payment_schedule.length > 0) {
			this.frm.set_currency_labels(["base_payment_amount", "base_outstanding", "base_paid_amount"],
				company_currency, "payment_schedule");
			this.frm.set_currency_labels(["payment_amount", "outstanding", "paid_amount"],
				this.frm.doc.currency, "payment_schedule");

			var schedule_grid = this.frm.fields_dict["payment_schedule"].grid;
			$.each(["base_payment_amount", "base_outstanding", "base_paid_amount"], function (i, fname) {
				if (frappe.meta.get_docfield(schedule_grid.doctype, fname))
					schedule_grid.set_column_disp(fname, me.frm.doc.currency != company_currency);
			});
		}
	}

	toggle_item_grid_columns(company_currency) {
		const me = this;
		// toggle columns
		var item_grid = this.frm.fields_dict["items"].grid;
		$.each(["base_rate", "base_price_list_rate", "base_amount", "base_rate_with_margin"], function (i, fname) {
			if (frappe.meta.get_docfield(item_grid.doctype, fname))
				item_grid.set_column_disp(fname, me.frm.doc.currency != company_currency);
		});

		var show = (cint(cur_frm.doc.discount_amount)) ||
			((cur_frm.doc.taxes || []).filter(function (d) { return d.included_in_print_rate === 1 }).length);

		$.each(["net_rate", "net_amount"], function (i, fname) {
			if (frappe.meta.get_docfield(item_grid.doctype, fname))
				item_grid.set_column_disp(fname, show);
		});

		$.each(["base_net_rate", "base_net_amount"], function (i, fname) {
			if (frappe.meta.get_docfield(item_grid.doctype, fname))
				item_grid.set_column_disp(fname, (show && (me.frm.doc.currency != company_currency)));
		});
	}

	recalculate() {
		this.calculate_taxes_and_totals();
	}

	recalculate_values() {
		this.calculate_taxes_and_totals();
	}

	calculate_charges() {
		this.calculate_taxes_and_totals();
	}

	ignore_pricing_rule() {
		if (this.frm.doc.ignore_pricing_rule) {
			var me = this;
			var item_list = [];

			$.each(this.frm.doc["items"] || [], function (i, d) {
				if (d.item_code) {
					if (d.is_free_item) {
						// Simply remove free items
						me.frm.get_field("items").grid.grid_rows[i].remove();
					} else {
						item_list.push({
							"doctype": d.doctype,
							"name": d.name,
							"item_code": d.item_code,
							"pricing_rules": d.pricing_rules,
							"parenttype": d.parenttype,
							"parent": d.parent,
							"price_list_rate": d.price_list_rate
						})
					}
				}
			});
			return this.frm.call({
				method: "erpnext.accounts.doctype.pricing_rule.pricing_rule.remove_pricing_rules",
				args: { item_list: item_list },
				callback: function (r) {
					if (!r.exc && r.message) {
						r.message.forEach(row_item => {
							me.remove_pricing_rule(row_item);
						});
						me._set_values_for_item_list(r.message);
						me.calculate_taxes_and_totals();
						if (me.frm.doc.apply_discount_on) me.frm.trigger("apply_discount_on");
					}
				}
			});
		} else {
			this.apply_pricing_rule();
		}
	}

	apply_pricing_rule(item, calculate_taxes_and_totals) {
		var me = this;
		var args = this._get_args(item);
		if (!(args.items && args.items.length)) {
			if (calculate_taxes_and_totals) me.calculate_taxes_and_totals();
			return;
		}

		// Target doc created from a mapped doc
		if (this.frm.doc.__onload && this.frm.doc.__onload.ignore_price_list) {
			// Calculate totals even though pricing rule is not applied.
			// `apply_pricing_rule` is triggered due to change in data which most likely contributes to Total.
			if (calculate_taxes_and_totals) me.calculate_taxes_and_totals();
			return;
		}

		return this.frm.call({
			method: "erpnext.accounts.doctype.pricing_rule.pricing_rule.apply_pricing_rule",
			args: { args: args, doc: me.frm.doc },
			callback: function (r) {
				if (!r.exc && r.message) {
					me._set_values_for_item_list(r.message);
					if (item) me.set_gross_profit(item);
					if (me.frm.doc.apply_discount_on) me.frm.trigger("apply_discount_on")
				}
			}
		});
	}

	_get_args(item) {
		var me = this;
		return {
			"items": this._get_item_list(item),
			"customer": me.frm.doc.customer || me.frm.doc.party_name,
			"quotation_to": me.frm.doc.quotation_to,
			"customer_group": me.frm.doc.customer_group,
			"territory": me.frm.doc.territory,
			"supplier": me.frm.doc.supplier,
			"supplier_group": me.frm.doc.supplier_group,
			"currency": me.frm.doc.currency,
			"conversion_rate": me.frm.doc.conversion_rate,
			"price_list": me.frm.doc.selling_price_list || me.frm.doc.buying_price_list,
			"price_list_currency": me.frm.doc.price_list_currency,
			"plc_conversion_rate": me.frm.doc.plc_conversion_rate,
			"company": me.frm.doc.company,
			"transaction_date": me.frm.doc.transaction_date || me.frm.doc.posting_date,
			"campaign": me.frm.doc.campaign,
			"sales_partner": me.frm.doc.sales_partner,
			"ignore_pricing_rule": me.frm.doc.ignore_pricing_rule,
			"doctype": me.frm.doc.doctype,
			"name": me.frm.doc.name,
			"is_return": cint(me.frm.doc.is_return),
			"update_stock": in_list(['Sales Invoice', 'Purchase Invoice'], me.frm.doc.doctype) ? cint(me.frm.doc.update_stock) : 0,
			"conversion_factor": me.frm.doc.conversion_factor,
			"pos_profile": me.frm.doc.doctype == 'Sales Invoice' ? me.frm.doc.pos_profile : '',
			"coupon_code": me.frm.doc.coupon_code
		};
	}

	_get_item_list(item) {
		var item_list = [];
		var append_item = function (d) {
			if (d.item_code) {
				item_list.push({
					"doctype": d.doctype,
					"name": d.name,
					"child_docname": d.name,
					"item_code": d.item_code,
					"item_group": d.item_group,
					"brand": d.brand,
					"qty": d.qty,
					"stock_qty": d.stock_qty,
					"uom": d.uom,
					"stock_uom": d.stock_uom,
					"parenttype": d.parenttype,
					"parent": d.parent,
					"pricing_rules": d.pricing_rules,
					"warehouse": d.warehouse,
					"serial_no": d.serial_no,
					"batch_no": d.batch_no,
					"price_list_rate": d.price_list_rate,
					"conversion_factor": d.conversion_factor || 1.0
				});

				// if doctype is Quotation Item / Sales Order Iten then add Margin Type and rate in item_list
				if (in_list(["Quotation Item", "Sales Order Item", "Delivery Note Item", "Sales Invoice Item", "Purchase Invoice Item", "Purchase Order Item", "Purchase Receipt Item"]), d.doctype) {
					item_list[0]["margin_type"] = d.margin_type;
					item_list[0]["margin_rate_or_amount"] = d.margin_rate_or_amount;
				}
			}
		};

		if (item) {
			append_item(item);
		} else {
			$.each(this.frm.doc["items"] || [], function (i, d) {
				append_item(d);
			});
		}
		return item_list;
	}

	_set_values_for_item_list(children) {
		const items_rule_dict = {};

		for (const child of children) {
			const existing_pricing_rule = frappe.model.get_value(child.doctype, child.name, "pricing_rules");

			for (const [key, value] of Object.entries(child)) {
				if (!["doctype", "name"].includes(key)) {
					if (key === "price_list_rate") {
						frappe.model.set_value(child.doctype, child.name, "rate", value);
					}

					if (key === "pricing_rules") {
						frappe.model.set_value(child.doctype, child.name, key, value);
					}

					if (key !== "free_item_data") {
						if (child.apply_rule_on_other_items && JSON.parse(child.apply_rule_on_other_items).length) {
							if (!in_list(JSON.parse(child.apply_rule_on_other_items), child.item_code)) {
								continue;
							}
						}

						frappe.model.set_value(child.doctype, child.name, key, value);
					}
				}
			}

			frappe.model.round_floats_in(
				frappe.get_doc(child.doctype, child.name),
				["price_list_rate", "discount_percentage"],
			);

			// if pricing rule set as blank from an existing value, apply price_list
			if (!this.frm.doc.ignore_pricing_rule && existing_pricing_rule && !child.pricing_rules) {
				this.apply_price_list(frappe.get_doc(child.doctype, child.name));
			} else if (!child.pricing_rules) {
				this.remove_pricing_rule(frappe.get_doc(child.doctype, child.name));
			}

			if (child.free_item_data && child.free_item_data.length > 0) {
				this.apply_product_discount(child);
			}

			if (child.apply_rule_on_other_items && JSON.parse(child.apply_rule_on_other_items).length) {
				items_rule_dict[child.name] = child;
			}
		}

		this.apply_rule_on_other_items(items_rule_dict);
		this.calculate_taxes_and_totals();
	}

	apply_rule_on_other_items(args) {
		const me = this;
		const fields = ["discount_percentage", "pricing_rules", "discount_amount", "rate"];

		for (var k in args) {
			let data = args[k];

			if (data && data.apply_rule_on_other_items && JSON.parse(data.apply_rule_on_other_items)) {
				me.frm.doc.items.forEach(d => {
					if (in_list(JSON.parse(data.apply_rule_on_other_items), d[data.apply_rule_on])) {
						for (var k in data) {
							if (in_list(fields, k) && data[k] && (data.price_or_product_discount === 'Price' || k === 'pricing_rules')) {
								frappe.model.set_value(d.doctype, d.name, k, data[k]);
							}
						}
					}
				});
			}
		}
	}

	apply_product_discount(args) {
		const items = this.frm.doc.items.filter(d => (d.is_free_item)) || [];

		const exist_items = items.map(row => (row.item_code, row.pricing_rules));

		args.free_item_data.forEach(pr_row => {
			let row_to_modify = {};
			if (!items || !in_list(exist_items, (pr_row.item_code, pr_row.pricing_rules))) {

				row_to_modify = frappe.model.add_child(this.frm.doc,
					this.frm.doc.doctype + ' Item', 'items');

			} else if (items) {
				row_to_modify = items.filter(d => (d.item_code === pr_row.item_code
					&& d.pricing_rules === pr_row.pricing_rules))[0];
			}

			for (let key in pr_row) {
				row_to_modify[key] = pr_row[key];
			}
			this.frm.script_manager.copy_from_first_row("items", row_to_modify, ["expense_account", "income_account"]);
		});

		// free_item_data is a temporary variable
		args.free_item_data = '';
		refresh_field('items');
	}

	apply_price_list(item, reset_plc_conversion) {
		// We need to reset plc_conversion_rate sometimes because the call to
		// `erpnext.stock.get_item_details.apply_price_list` is sensitive to its value
		if (!reset_plc_conversion) {
			this.frm.set_value("plc_conversion_rate", "");
		}

		var me = this;
		var args = this._get_args(item);
		if (!((args.items && args.items.length) || args.price_list)) {
			return;
		}

		if (me.in_apply_price_list == true) return;

		me.in_apply_price_list = true;
		return this.frm.call({
			method: "erpnext.stock.get_item_details.apply_price_list",
			args: { args: args },
			callback: function (r) {
				if (!r.exc) {
					frappe.run_serially([
						() => me.frm.set_value("price_list_currency", r.message.parent.price_list_currency),
						() => me.frm.set_value("plc_conversion_rate", r.message.parent.plc_conversion_rate),
						() => {
							if (args.items.length) {
								me._set_values_for_item_list(r.message.children);
							}
						},
						() => { me.in_apply_price_list = false; }
					]);

				} else {
					me.in_apply_price_list = false;
				}
			}
		}).always(() => {
			me.in_apply_price_list = false;
		});
	}

	remove_pricing_rule(item) {
		let me = this;
		const fields = ["discount_percentage",
			"discount_amount", "margin_rate_or_amount", "rate_with_margin"];

		if (item.remove_free_item) {
			var items = [];

			me.frm.doc.items.forEach(d => {
				if (d.item_code != item.remove_free_item || !d.is_free_item) {
					items.push(d);
				}
			});

			me.frm.doc.items = items;
			refresh_field('items');
		} else if (item.applied_on_items && item.apply_on) {
			const applied_on_items = item.applied_on_items.split(',');
			me.frm.doc.items.forEach(row => {
				if (applied_on_items.includes(row[item.apply_on])) {
					fields.forEach(f => {
						row[f] = 0;
					});

					["pricing_rules", "margin_type"].forEach(field => {
						if (row[field]) {
							row[field] = '';
						}
					})
				}
			});

			me.trigger_price_list_rate();
		}
	}

	trigger_price_list_rate() {
		var me = this;

		this.frm.doc.items.forEach(child_row => {
			me.frm.script_manager.trigger("price_list_rate",
				child_row.doctype, child_row.name);
		})
	}

	validate_company_and_party() {
		var me = this;
		var valid = true;

		$.each(["company", "customer"], function (i, fieldname) {
			if (frappe.meta.has_field(me.frm.doc.doctype, fieldname) && me.frm.doc.doctype != "Purchase Order") {
				if (!me.frm.doc[fieldname]) {
					frappe.msgprint(__("Please specify") + ": " +
						frappe.meta.get_label(me.frm.doc.doctype, fieldname, me.frm.doc.name) +
						". " + __("It is needed to fetch Item Details."));
					valid = false;
				}
			}
		});
		return valid;
	}

	get_terms() {
		var me = this;

		erpnext.utils.get_terms(this.frm.doc.tc_name, this.frm.doc, function (r) {
			if (!r.exc) {
				me.frm.set_value("terms", r.message);
			}
		});
	}

	taxes_and_charges() {
		var me = this;
		if (this.frm.doc.taxes_and_charges) {
			return this.frm.call({
				method: "erpnext.controllers.accounts_controller.get_taxes_and_charges",
				args: {
					"master_doctype": frappe.meta.get_docfield(this.frm.doc.doctype, "taxes_and_charges",
						this.frm.doc.name).options,
					"master_name": this.frm.doc.taxes_and_charges
				},
				callback: function (r) {
					if (!r.exc) {
						if (me.frm.doc.shipping_rule && me.frm.doc.taxes) {
							for (let tax of r.message) {
								me.frm.add_child("taxes", tax);
							}

							refresh_field("taxes");
						} else {
							me.frm.set_value("taxes", r.message);
							me.calculate_taxes_and_totals();
						}
					}
				}
			});
		}
	}

	tax_category() {
		var me = this;
		if (me.frm.updating_party_details) return;

		frappe.run_serially([
			() => this.update_item_tax_map(),
			() => erpnext.utils.set_taxes(this.frm, "tax_category"),
		]);
	}

	update_item_tax_map() {
		let me = this;
		let item_codes = [];
		let item_rates = {};
		let item_tax_templates = {};

		$.each(this.frm.doc.items || [], function (i, item) {
			if (item.item_code) {
				// Use combination of name and item code in case same item is added multiple times
				item_codes.push([item.item_code, item.name]);
				item_rates[item.name] = item.net_rate;
				item_tax_templates[item.name] = item.item_tax_template;
			}
		});

		if (item_codes.length) {
			return this.frm.call({
				method: "erpnext.stock.get_item_details.get_item_tax_info",
				args: {
					company: me.frm.doc.company,
					tax_category: cstr(me.frm.doc.tax_category),
					item_codes: item_codes,
					item_rates: item_rates,
					item_tax_templates: item_tax_templates
				},
				callback: function (r) {
					if (!r.exc) {
						$.each(me.frm.doc.items || [], function (i, item) {
							if (item.name && r.message.hasOwnProperty(item.name) && r.message[item.name].item_tax_template) {
								item.item_tax_template = r.message[item.name].item_tax_template;
								item.item_tax_rate = r.message[item.name].item_tax_rate;
								me.add_taxes_from_item_tax_template(item.item_tax_rate);
							}
						});
					}
				}
			});
		}
	}

	item_tax_template(doc, cdt, cdn) {
		var me = this;
		if (me.frm.updating_party_details) return;

		var item = frappe.get_doc(cdt, cdn);

		if (item.item_tax_template) {
			return this.frm.call({
				method: "erpnext.stock.get_item_details.get_item_tax_map",
				args: {
					company: me.frm.doc.company,
					item_tax_template: item.item_tax_template,
					as_json: true
				},
				callback: function (r) {
					if (!r.exc) {
						item.item_tax_rate = r.message;
						me.add_taxes_from_item_tax_template(item.item_tax_rate);
						me.calculate_taxes_and_totals();
					}
				}
			});
		} else {
			item.item_tax_rate = "{}";
			me.calculate_taxes_and_totals();
		}
	}


	is_recurring() {
		// set default values for recurring documents
		if (this.frm.doc.is_recurring && this.frm.doc.__islocal) {
			frappe.msgprint(__("Please set recurring after saving"));
			this.frm.set_value('is_recurring', 0);
			return;
		}

		if (this.frm.doc.is_recurring) {
			if (!this.frm.doc.recurring_id) {
				this.frm.set_value('recurring_id', this.frm.doc.name);
			}

			var owner_email = this.frm.doc.owner == "Administrator"
				? frappe.user_info("Administrator").email
				: this.frm.doc.owner;

			this.frm.doc.notification_email_address = $.map([cstr(owner_email),
			cstr(this.frm.doc.contact_email)], function (v) { return v || null; }).join(", ");
			this.frm.doc.repeat_on_day_of_month = frappe.datetime.str_to_obj(this.frm.doc.posting_date).getDate();
		}

		refresh_many(["notification_email_address", "repeat_on_day_of_month"]);
	}

	from_date() {
		// set to_date
		if (this.frm.doc.from_date) {
			var recurring_type_map = {
				'Monthly': 1, 'Quarterly': 3, 'Half-yearly': 6,
				'Yearly': 12
			};

			var months = recurring_type_map[this.frm.doc.recurring_type];
			if (months) {
				var to_date = frappe.datetime.add_months(this.frm.doc.from_date,
					months);
				this.frm.doc.to_date = frappe.datetime.add_days(to_date, -1);
				refresh_field('to_date');
			}
		}
	}

	set_gross_profit(item) {
		if (["Sales Order", "Quotation"].includes(this.frm.doc.doctype) && item.valuation_rate) {
			var rate = flt(item.rate) * flt(this.frm.doc.conversion_rate || 1);
			item.gross_profit = flt(((rate - item.valuation_rate) * item.stock_qty), precision("amount", item));
		}
	}

	setup_item_selector() {
		// TODO: remove item selector

		return;
		// if(!this.item_selector) {
		// 	this.item_selector = new erpnext.ItemSelector({frm: this.frm});
		// }
	}

	get_advances() {
		if (!this.frm.is_return) {
			return this.frm.call({
				method: "set_advances",
				doc: this.frm.doc,
				callback: function (r, rt) {
					refresh_field("advances");
				}
			})
		}
	}

	make_payment_entry() {
		return frappe.call({
			method: cur_frm.cscript.get_method_for_payment(),
			args: {
				"dt": cur_frm.doc.doctype,
				"dn": cur_frm.doc.name
			},
			callback: function (r) {
				var doclist = frappe.model.sync(r.message);
				frappe.set_route("Form", doclist[0].doctype, doclist[0].name);
				// cur_frm.refresh_fields()
			}
		});
	}

	make_quality_inspection() {
		let data = [];
		const fields = [
			{
				label: "Items",
				fieldtype: "Table",
				fieldname: "items",
				cannot_add_rows: true,
				in_place_edit: true,
				data: data,
				get_data: () => {
					return data;
				},
				fields: [
					{
						fieldtype: "Data",
						fieldname: "docname",
						hidden: true
					},
					{
						fieldtype: "Read Only",
						fieldname: "item_code",
						label: __("Item Code"),
						in_list_view: true
					},
					{
						fieldtype: "Read Only",
						fieldname: "item_name",
						label: __("Item Name"),
						in_list_view: true
					},
					{
						fieldtype: "Float",
						fieldname: "qty",
						label: __("Accepted Quantity"),
						in_list_view: true,
						read_only: true
					},
					{
						fieldtype: "Float",
						fieldname: "sample_size",
						label: __("Sample Size"),
						reqd: true,
						in_list_view: true
					},
					{
						fieldtype: "Data",
						fieldname: "description",
						label: __("Description"),
						hidden: true
					},
					{
						fieldtype: "Data",
						fieldname: "serial_no",
						label: __("Serial No"),
						hidden: true
					},
					{
						fieldtype: "Data",
						fieldname: "batch_no",
						label: __("Batch No"),
						hidden: true
					}
				]
			}
		];

		const me = this;
		const dialog = new frappe.ui.Dialog({
			title: __("Select Items for Quality Inspection"),
			fields: fields,
			primary_action: function () {
				const data = dialog.get_values();
				frappe.call({
					method: "erpnext.controllers.stock_controller.make_quality_inspections",
					args: {
						doctype: me.frm.doc.doctype,
						docname: me.frm.doc.name,
						items: data.items
					},
					freeze: true,
					callback: function (r) {
						if (r.message.length > 0) {
							if (r.message.length === 1) {
								frappe.set_route("Form", "Quality Inspection", r.message[0]);
							} else {
								frappe.route_options = {
									"reference_type": me.frm.doc.doctype,
									"reference_name": me.frm.doc.name
								};
								frappe.set_route("List", "Quality Inspection");
							}
						}
						dialog.hide();
					}
				});
			},
			primary_action_label: __("Create")
		});

		this.frm.doc.items.forEach(item => {
			if (!item.quality_inspection) {
				let dialog_items = dialog.fields_dict.items;
				dialog_items.df.data.push({
					"docname": item.name,
					"item_code": item.item_code,
					"item_name": item.item_name,
					"qty": item.qty,
					"description": item.description,
					"serial_no": item.serial_no,
					"batch_no": item.batch_no,
					"sample_size": item.sample_quantity
				});
				dialog_items.grid.refresh();
			}
		});

		data = dialog.fields_dict.items.df.data;
		if (!data.length) {
			frappe.msgprint(__("All items in this document already have a linked Quality Inspection."));
		} else {
			dialog.show();
		}
	}

	get_method_for_payment() {
		var method = "erpnext.accounts.doctype.payment_entry.payment_entry.get_payment_entry";
		if (cur_frm.doc.__onload && cur_frm.doc.__onload.make_payment_via_journal_entry) {
			if (in_list(['Sales Invoice', 'Purchase Invoice'], cur_frm.doc.doctype)) {
				method = "erpnext.accounts.doctype.journal_entry.journal_entry.get_payment_entry_against_invoice";
			} else {
				method = "erpnext.accounts.doctype.journal_entry.journal_entry.get_payment_entry_against_order";
			}
		}

		return method
	}

	set_query_for_batch(doc, cdt, cdn) {
		// Show item's batches in the dropdown of batch no

		var me = this;
		var item = frappe.get_doc(cdt, cdn);

		if (!item.item_code) {
			frappe.throw(__("Please enter Item Code to get batch no"));
		} else if (doc.doctype == "Purchase Receipt" ||
			(doc.doctype == "Purchase Invoice" && doc.update_stock)) {
			return {
				filters: { 'item': item.item_code }
			}
		} else {
			let filters = {
				'item_code': item.item_code,
				'posting_date': me.frm.doc.posting_date || frappe.datetime.nowdate(),
			}

			if (doc.is_return) {
				filters["is_return"] = 1;
			}

			if (item.warehouse) filters["warehouse"] = item.warehouse;

			return {
				query: "erpnext.controllers.queries.get_batch_no",
				filters: filters
			}
		}
	}

	set_query_for_item_tax_template(doc, cdt, cdn) {
		var item = frappe.get_doc(cdt, cdn);
		return doc.company ? { filters: { company: doc.company } } : {};
		if (!item.item_code) {
			return doc.company ? { filters: { company: doc.company } } : {};
		} else {
			let filters = {
				'item_code': item.item_code,
				'valid_from': ["<=", doc.transaction_date || doc.bill_date || doc.posting_date],
				'item_group': item.item_group,
			}

			if (doc.tax_category)
				filters['tax_category'] = doc.tax_category;
			if (doc.company)
				filters['company'] = doc.company;
			return {
				query: "erpnext.controllers.queries.get_tax_template",
				filters: filters
			}
		}
	}

	payment_terms_template() {
		var me = this;
		const doc = this.frm.doc;
		if (doc.payment_terms_template && doc.doctype !== 'Delivery Note') {
			var posting_date = doc.posting_date || doc.transaction_date;
			frappe.call({
				method: "erpnext.controllers.accounts_controller.get_payment_terms",
				args: {
					terms_template: doc.payment_terms_template,
					posting_date: posting_date,
					grand_total: doc.rounded_total || doc.grand_total,
					base_grand_total: doc.base_rounded_total || doc.base_grand_total,
					bill_date: doc.bill_date
				},
				callback: function (r) {
					if (r.message && !r.exc) {
						me.frm.set_value("payment_schedule", r.message);
						const company_currency = me.get_company_currency();
						me.update_payment_schedule_grid_labels(company_currency);
					}
				}
			})
		}
	}

	payment_term(doc, cdt, cdn) {
		const me = this;
		var row = locals[cdt][cdn];
		if (row.payment_term) {
			frappe.call({
				method: "erpnext.controllers.accounts_controller.get_payment_term_details",
				args: {
					term: row.payment_term,
					bill_date: this.frm.doc.bill_date,
					posting_date: this.frm.doc.posting_date || this.frm.doc.transaction_date,
					grand_total: this.frm.doc.rounded_total || this.frm.doc.grand_total,
					base_grand_total: this.frm.doc.base_rounded_total || this.frm.doc.base_grand_total
				},
				callback: function (r) {
					if (r.message && !r.exc) {
						for (var d in r.message) {
							frappe.model.set_value(cdt, cdn, d, r.message[d]);
							const company_currency = me.get_company_currency();
							me.update_payment_schedule_grid_labels(company_currency);
						}
					}
				}
			})
		}
	}

	against_blanket_order(doc, cdt, cdn) {
		var item = locals[cdt][cdn];
		if (!item.against_blanket_order) {
			frappe.model.set_value(this.frm.doctype + " Item", item.name, "blanket_order", null);
			frappe.model.set_value(this.frm.doctype + " Item", item.name, "blanket_order_rate", 0.00);
		}
	}

	blanket_order(doc, cdt, cdn) {
		var me = this;
		var item = locals[cdt][cdn];
		if (item.blanket_order && (item.parenttype == "Sales Order" || item.parenttype == "Purchase Order")) {
			frappe.call({
				method: "erpnext.stock.get_item_details.get_blanket_order_details",
				args: {
					args: {
						item_code: item.item_code,
						customer: doc.customer,
						supplier: doc.supplier,
						company: doc.company,
						transaction_date: doc.transaction_date,
						blanket_order: item.blanket_order
					}
				},
				callback: function (r) {
					if (!r.message) {
						frappe.throw(__("Invalid Blanket Order for the selected Customer and Item"));
					} else {
						frappe.run_serially([
							() => frappe.model.set_value(cdt, cdn, "blanket_order_rate", r.message.blanket_order_rate),
							() => me.frm.script_manager.trigger("price_list_rate", cdt, cdn)
						]);
					}
				}
			})
		}
	}

	set_reserve_warehouse() {
		this.autofill_warehouse(this.frm.doc.supplied_items, "reserve_warehouse", this.frm.doc.set_reserve_warehouse);
	}

	set_warehouse() {
		this.autofill_warehouse(this.frm.doc.items, "warehouse", this.frm.doc.set_warehouse);
	}

	set_target_warehouse() {
		this.autofill_warehouse(this.frm.doc.items, "target_warehouse", this.frm.doc.set_target_warehouse);
	}

	set_from_warehouse() {
		this.autofill_warehouse(this.frm.doc.items, "from_warehouse", this.frm.doc.set_from_warehouse);
	}

	autofill_warehouse(child_table, warehouse_field, warehouse) {
		if (warehouse && child_table && child_table.length) {
			let doctype = child_table[0].doctype;
			$.each(child_table || [], function (i, item) {
				frappe.model.set_value(doctype, item.name, warehouse_field, warehouse);
			});
		}
	}

	coupon_code() {
		if (this.frm.doc.coupon_code || this.frm._last_coupon_code) {
			// reset pricing rules if coupon code is set or is unset
			const _ignore_pricing_rule = this.frm.doc.ignore_pricing_rule;
			return frappe.run_serially([
				() => this.frm.doc.ignore_pricing_rule = 1,
				() => this.frm.trigger('ignore_pricing_rule'),
				() => this.frm.doc.ignore_pricing_rule = _ignore_pricing_rule,
				() => this.frm.trigger('apply_pricing_rule'),
				() => this.frm._last_coupon_code = this.frm.doc.coupon_code
			]);
		}
	}
};
frappe.provide("frappe.views");

frappe.views.ListViewSelect = class ListViewSelect {
	constructor(opts) {
		$.extend(this, opts);
		this.set_current_view();
		this.setup_views();
	}

	add_view_to_menu(view, action) {
		if (this.doctype == "File" && view == "List") {
			view = "File";
		}
		let $el = this.page.add_custom_menu_item(
			this.parent,
			__(view),
			action,
			true,
			null,
			this.icon_map[view] || "list"
		);
		$el.parent().attr("data-view", view);
	}

	set_current_view() {
		this.current_view = "List";
		const route = frappe.get_route();
		const view_name = frappe.utils.to_title_case(route[2] || "");
		if (route.length > 2 && frappe.views.view_modes.includes(view_name)) {
			this.current_view = view_name;

			if (this.current_view === "Kanban") {
				this.kanban_board = route[3];
			} else if (this.current_view === "Inbox") {
				this.email_account = route[3];
			}
		}
	}

	set_route(view, calendar_name) {
		const route = [this.slug(), "view", view];
		if (calendar_name) route.push(calendar_name);
		frappe.set_route(route);
	}

	setup_views() {
		const views = {
			List: {
				condition: true,
				action: () => this.set_route("list"),
			},
			Report: {
				condition: true,
				action: () => {
					if (this.doctype === "Sales Invoice") {
						this.set_route("report", "Sales Invoice Report");
					} else {
						this.set_route("report");
					}
				},
				current_view_handler: () => {
					const reports = this.get_reports();
					let default_action = {};
					// Only add action if current route is not report builder
					if (frappe.get_route().length > 3) {
						default_action = {
							label: __("Report Builder"),
							action: () => {
								if (this.doctype === "Sales Invoice") {
									this.set_route("report", "Sales Invoice Report");
								} else {
									this.set_route("report");
								}
							},
						};
					}
					this.setup_dropdown_in_sidebar("Report", reports, default_action);
				},
			},
			Dashboard: {
				condition: true,
				action: () => this.set_route("dashboard"),
			},
			Calendar: {
				condition: frappe.views.calendar[this.doctype],
				action: () => this.set_route("calendar", "default"),
				current_view_handler: () => {
					this.get_calendars().then((calendars) => {
						this.setup_dropdown_in_sidebar("Calendar", calendars);
					});
				},
			},
			Gantt: {
				condition: frappe.views.calendar[this.doctype],
				action: () => this.set_route("gantt"),
			},
			Inbox: {
				condition: this.doctype === "Communication" && frappe.boot.email_accounts.length,
				action: () => this.set_route("inbox"),
				current_view_handler: () => {
					const accounts = this.get_email_accounts();
					let default_action;
					if (has_common(frappe.user_roles, ["System Manager", "Administrator"])) {
						default_action = {
							label: __("New Email Account"),
							action: () => frappe.new_doc("Email Account"),
						};
					}
					this.setup_dropdown_in_sidebar("Inbox", accounts, default_action);
				},
			},
			Image: {
				condition: this.list_view.meta.image_field,
				action: () => this.set_route("image"),
			},
			Tree: {
				condition:
					frappe.treeview_settings[this.doctype] ||
					frappe.get_meta(this.doctype).is_tree,
				action: () => this.set_route("tree"),
			},
			Kanban: {
				condition: this.doctype != "File",
				action: () => this.setup_kanban_boards(),
				current_view_handler: () => {
					frappe.views.KanbanView.get_kanbans(this.doctype).then((kanbans) =>
						this.setup_kanban_switcher(kanbans)
					);
				},
			},
			Map: {
				condition:
					this.list_view.settings.get_coords_method ||
					(this.list_view.meta.fields.find((i) => i.fieldname === "latitude") &&
						this.list_view.meta.fields.find((i) => i.fieldname === "longitude")) ||
					this.list_view.meta.fields.find(
						(i) => i.fieldname === "location" && i.fieldtype == "Geolocation"
					),
				action: () => this.set_route("map"),
			},
		};

		frappe.views.view_modes.forEach((view) => {
			if (this.current_view !== view && views[view].condition) {
				this.add_view_to_menu(view, views[view].action);
			}

			if (this.current_view == view) {
				views[view].current_view_handler && views[view].current_view_handler();
			}
		});
	}

	setup_dropdown_in_sidebar(view, items, default_action) {
		if (!this.sidebar) return;
		const views_wrapper = this.sidebar.sidebar.find(".views-section");
		views_wrapper.find(".sidebar-label").html(__(view));
		const $dropdown = views_wrapper.find(".views-dropdown");

		let placeholder = __("Select {0}", [__(view)]);
		let html = ``;

		if (!items || !items.length) {
			html = `<div class="empty-state">
						${__("No {0} Found", [__(view)])}
				</div>`;
		} else {
			const page_name = this.get_page_name();
			items.map((item) => {
				if (item.name.toLowerCase() == page_name.toLowerCase()) {
					placeholder = item.name;
				} else {
					html += `<li><a class="dropdown-item" href="${item.route}">${item.name}</a></li>`;
				}
			});
		}

		views_wrapper.find(".selected-view").html(placeholder);

		if (default_action) {
			views_wrapper.find(".sidebar-action a").html(default_action.label);
			views_wrapper.find(".sidebar-action a").click(() => default_action.action());
		}

		$dropdown.html(html);

		views_wrapper.removeClass("hide");
	}

	setup_kanban_switcher(kanbans) {
		const kanban_switcher = this.page.add_custom_button_group(
			__("Select Kanban"),
			null,
			this.list_view.$filter_section
		);

		kanbans.map((k) => {
			this.page.add_custom_menu_item(
				kanban_switcher,
				k.name,
				() => this.set_route("kanban", k.name),
				false
			);
		});

		this.page.add_custom_menu_item(
			kanban_switcher,
			__("Create New Kanban Board"),
			() => frappe.views.KanbanView.show_kanban_dialog(this.doctype),
			true
		);
	}

	get_page_name() {
		return frappe.utils.to_title_case(frappe.get_route().slice(-1)[0] || "");
	}

	get_reports() {
		// add reports linked to this doctype to the dropdown
		let added = [];
		let reports_to_add = [];

		let add_reports = (reports) => {
			reports.map((r) => {
				if (!r.ref_doctype || r.ref_doctype == this.doctype) {
					const report_type =
						r.report_type === "Report Builder"
							? `/app/list/${r.ref_doctype}/report`
							: "/app/query-report";

					const route = r.route || report_type + "/" + (r.title || r.name);

					if (added.indexOf(route) === -1) {
						// don't repeat
						added.push(route);
						reports_to_add.push({
							name: __(r.title || r.name),
							route: route,
						});
					}
				}
			});
		};

		// from reference doctype
		if (this.list_view.settings.reports) {
			add_reports(this.list_view.settings.reports);
		}

		// Sort reports alphabetically
		var reports =
			Object.values(frappe.boot.user.all_reports).sort((a, b) =>
				a.title.localeCompare(b.title)
			) || [];

		// from specially tagged reports
		add_reports(reports);

		return reports_to_add;
	}

	setup_kanban_boards() {
		function fetch_kanban_board(doctype) {
			frappe.db.get_value(
				"Kanban Board",
				{ reference_doctype: doctype },
				"name",
				(board) => {
					if (!$.isEmptyObject(board)) {
						frappe.set_route("list", doctype, "kanban", board.name);
					} else {
						frappe.views.KanbanView.show_kanban_dialog(doctype);
					}
				}
			);
		}

		const last_opened_kanban =
			frappe.model.user_settings[this.doctype]["Kanban"]?.last_kanban_board;
		if (!last_opened_kanban) {
			fetch_kanban_board(this.doctype);
		} else {
			frappe.db.exists("Kanban Board", last_opened_kanban).then((exists) => {
				if (exists) {
					frappe.set_route("list", this.doctype, "kanban", last_opened_kanban);
				} else {
					fetch_kanban_board(this.doctype);
				}
			});
		}
	}

	get_calendars() {
		const doctype = this.doctype;
		let calendars = [];

		return frappe.db
			.get_list("Calendar View", {
				filters: {
					reference_doctype: doctype,
				},
			})
			.then((result) => {
				if (!(result && Array.isArray(result) && result.length)) return;

				if (frappe.views.calendar[this.doctype]) {
					// has standard calendar view
					calendars.push({
						name: "Default",
						route: `/app/${this.slug()}/view/calendar/default`,
					});
				}
				result.map((calendar) => {
					calendars.push({
						name: calendar.name,
						route: `/app/${this.slug()}/view/calendar/${calendar.name}`,
					});
				});

				return calendars;
			});
	}

	get_email_accounts() {
		let accounts_to_add = [];
		let accounts = frappe.boot.email_accounts;
		accounts.forEach((account) => {
			let email_account =
				account.email_id == "All Accounts" ? "All Accounts" : account.email_account;
			let route = `/app/communication/view/inbox/${email_account}`;
			let display_name = ["All Accounts", "Sent Mail", "Spam", "Trash"].includes(
				account.email_id
			)
				? __(account.email_id)
				: account.email_account;

			accounts_to_add.push({
				name: display_name,
				route: route,
			});
		});

		return accounts_to_add;
	}

	slug() {
		return frappe.router.slug(frappe.router.doctype_layout || this.doctype);
	}
};

frappe.views.CommunicationComposer = class {
	constructor(opts) {
		$.extend(this, opts);
		if (!this.doc) {
			this.doc = (this.frm && this.frm.doc) || {};
		}

		this.make();
	}

	make() {
		const me = this;

		this.dialog = new frappe.ui.Dialog({
			title: this.title || this.subject || __("New Email"),
			no_submit_on_enter: true,
			fields: this.get_fields(),
			primary_action_label: __("Send"),
			primary_action() {
				me.send_action();
			},
			secondary_action_label: __("Discard"),
			secondary_action() {
				me.dialog.hide();
				me.clear_cache();
			},
			size: "large",
			minimizable: true,
		});

		$(this.dialog.$wrapper.find(".form-section").get(0)).addClass("to_section");

		this.prepare();
		this.dialog.show();

		if (this.frm) {
			$(document).trigger("form-typing", [this.frm]);
		}
	}

	get_fields() {
		const fields = [
			{
				label: __("To"),
				fieldtype: "MultiSelect",
				reqd: 0,
				fieldname: "recipients",
			},
			{
				fieldtype: "Button",
				label: frappe.utils.icon("down"),
				fieldname: "option_toggle_button",
				click: () => {
					this.toggle_more_options();
				},
			},
			{
				fieldtype: "Section Break",
				hidden: 1,
				fieldname: "more_options",
			},
			{
				label: __("CC"),
				fieldtype: "MultiSelect",
				fieldname: "cc",
			},
			{
				label: __("BCC"),
				fieldtype: "MultiSelect",
				fieldname: "bcc",
			},
			{
				label: __("Email Template"),
				fieldtype: "Link",
				options: "Email Template",
				fieldname: "email_template",
			},
			{ fieldtype: "Section Break" },
			{
				label: __("Subject"),
				fieldtype: "Data",
				reqd: 1,
				fieldname: "subject",
				length: 524288,
			},
			{
				label: __("Message"),
				fieldtype: "Text Editor",
				fieldname: "content",
				onchange: frappe.utils.debounce(this.save_as_draft.bind(this), 300),
			},
			{
				fieldtype: "Button",
				label: __("Add Signature"),
				fieldname: "add_signature",
				hidden: 1,
				click: async () => {
					let sender_email = this.dialog.get_value("sender") || "";
					this.content_set = false;
					await this.set_content(sender_email);
				},
			},
			{ fieldtype: "Section Break" },
			{
				label: __("Send me a copy"),
				fieldtype: "Check",
				fieldname: "send_me_a_copy",
				default: frappe.boot.user.send_me_a_copy,
			},
			{
				label: __("Send Read Receipt"),
				fieldtype: "Check",
				fieldname: "send_read_receipt",
			},
			{
				label: __("Attach Document Print"),
				fieldtype: "Check",
				fieldname: "attach_document_print",
			},
			{
				label: __("Select Print Format"),
				fieldtype: "Select",
				fieldname: "select_print_format",
			},
			{
				label: __("Select Languages"),
				fieldtype: "Select",
				fieldname: "language_sel",
			},
			{ fieldtype: "Column Break" },
			{
				label: __("Select Attachments"),
				fieldtype: "HTML",
				fieldname: "select_attachments",
			},
		];

		// add from if user has access to multiple email accounts
		const email_accounts = frappe.boot.email_accounts.filter((account) => {
			return (
				!in_list(["All Accounts", "Sent", "Spam", "Trash"], account.email_account) &&
				account.enable_outgoing
			);
		});

		if (email_accounts.length) {
			this.user_email_accounts = email_accounts.map(function (e) {
				return e.email_id;
			});

			fields.unshift({
				label: __("From"),
				fieldtype: "Select",
				reqd: 1,
				fieldname: "sender",
				options: this.user_email_accounts,
			});
			//Preselect email senders if there is only one
			if (this.user_email_accounts.length == 1) {
				this["sender"] = this.user_email_accounts;
			}
		}

		return fields;
	}

	toggle_more_options(show_options) {
		show_options = show_options || this.dialog.fields_dict.more_options.df.hidden;
		this.dialog.set_df_property("more_options", "hidden", !show_options);

		const label = frappe.utils.icon(show_options ? "up-line" : "down");
		this.dialog.get_field("option_toggle_button").set_label(label);
	}

	prepare() {
		this.setup_multiselect_queries();
		this.setup_subject_and_recipients();
		this.setup_print_language();
		this.setup_print();
		this.setup_attach();
		this.setup_email();
		this.setup_email_template();
		this.setup_last_edited_communication();
		this.setup_add_signature_button();
		this.set_values();
	}

	setup_add_signature_button() {
		let has_sender = this.dialog.has_field("sender");
		this.dialog.set_df_property("add_signature", "hidden", !has_sender);
	}

	setup_multiselect_queries() {
		["recipients", "cc", "bcc"].forEach((field) => {
			this.dialog.fields_dict[field].get_data = () => {
				const data = this.dialog.fields_dict[field].get_value();
				const txt = data.match(/[^,\s*]*$/)[0] || "";

				frappe.call({
					method: "frappe.email.get_contact_list",
					args: { txt },
					callback: (r) => {
						this.dialog.fields_dict[field].set_data(r.message);
					},
				});
			};
		});
	}

	setup_subject_and_recipients() {
		this.subject = this.subject || "";

		if (!this.forward && !this.recipients && this.last_email) {
			this.recipients = this.last_email.sender;
			this.cc = this.last_email.cc;
			this.bcc = this.last_email.bcc;
		}

		if (!this.forward && !this.recipients) {
			this.recipients = this.frm && this.frm.timeline.get_recipient();
		}

		if (!this.subject && this.frm) {
			// get subject from last communication
			const last = this.frm.timeline.get_last_email();

			if (last) {
				this.subject = last.subject;
				if (!this.recipients) {
					this.recipients = last.sender;
				}

				// prepend "Re:"
				if (strip(this.subject.toLowerCase().split(":")[0]) != "re") {
					this.subject = __("Re: {0}", [this.subject]);
				}
			}

			if (!this.subject) {
				this.subject = this.frm.doc.name;
				if (this.frm.meta.subject_field && this.frm.doc[this.frm.meta.subject_field]) {
					this.subject = this.frm.doc[this.frm.meta.subject_field];
				} else if (this.frm.meta.title_field && this.frm.doc[this.frm.meta.title_field]) {
					this.subject = this.frm.doc[this.frm.meta.title_field];
				}
			}

			// always add an identifier to catch a reply
			// some email clients (outlook) may not send the message id to identify
			// the thread. So as a backup we use the name of the document as identifier
			const identifier = `#${this.frm.doc.name}`;

			// converting to str for int names
			if (!cstr(this.subject).includes(identifier)) {
				this.subject = `${this.subject} (${identifier})`;
			}
		}

		if (this.frm && !this.recipients) {
			this.recipients = this.frm.doc[this.frm.email_field];
		}
	}

	setup_email_template() {
		const me = this;

		this.dialog.fields_dict["email_template"].df.onchange = () => {
			const email_template = me.dialog.fields_dict.email_template.get_value();
			if (!email_template) return;

			function prepend_reply(reply) {
				if (me.reply_added === email_template) return;

				const content_field = me.dialog.fields_dict.content;
				const subject_field = me.dialog.fields_dict.subject;

				let content = content_field.get_value() || "";

				content_field.set_value(`${reply.message}`);
				subject_field.set_value(reply.subject);

				me.reply_added = email_template;
			}

			frappe.call({
				method: "frappe.email.doctype.email_template.email_template.get_email_template",
				args: {
					template_name: email_template,
					doc: me.doc,
					_lang: me.dialog.get_value("language_sel"),
				},
				callback(r) {
					prepend_reply(r.message);
				},
			});
		};
	}

	setup_last_edited_communication() {
		if (this.frm) {
			this.doctype = this.frm.doctype;
			this.key = this.frm.docname;
		} else {
			this.doctype = this.key = "Inbox";
		}

		if (this.last_email) {
			this.key = this.key + ":" + this.last_email.name;
		}

		if (this.subject) {
			this.key = this.key + ":" + this.subject;
		}

		this.dialog.on_hide = () => {
			$.extend(this.get_last_edited_communication(true), this.dialog.get_values(true));

			if (this.frm) {
				$(document).trigger("form-stopped-typing", [this.frm]);
			}
		};
	}

	get_last_edited_communication(clear) {
		if (!frappe.last_edited_communication[this.doctype]) {
			frappe.last_edited_communication[this.doctype] = {};
		}

		if (clear || !frappe.last_edited_communication[this.doctype][this.key]) {
			frappe.last_edited_communication[this.doctype][this.key] = {};
		}

		return frappe.last_edited_communication[this.doctype][this.key];
	}

	async set_values() {
		for (const fieldname of ["recipients", "cc", "bcc", "sender"]) {
			await this.dialog.set_value(fieldname, this[fieldname] || "");
		}

		const subject = frappe.utils.html2text(this.subject) || "";
		await this.dialog.set_value("subject", subject);

		await this.set_values_from_last_edited_communication();
		await this.set_content();

		// set default email template for the first email in a document
		if (this.frm && !this.is_a_reply && !this.content_set) {
			const email_template = this.frm.meta.default_email_template || "";
			await this.dialog.set_value("email_template", email_template);
		}

		for (const fieldname of ["email_template", "cc", "bcc"]) {
			if (this.dialog.get_value(fieldname)) {
				this.toggle_more_options(true);
				break;
			}
		}
	}

	async set_values_from_last_edited_communication() {
		if (this.message) return;

		const last_edited = this.get_last_edited_communication();
		if (!last_edited.content) return;

		// prevent re-triggering of email template
		if (last_edited.email_template) {
			const template_field = this.dialog.fields_dict.email_template;
			await template_field.set_model_value(last_edited.email_template);
			delete last_edited.email_template;
		}

		await this.dialog.set_values(last_edited);
		this.content_set = true;
	}

	selected_format() {
		return (
			this.dialog.fields_dict.select_print_format.input.value ||
			(this.frm && this.frm.meta.default_print_format) ||
			"Standard"
		);
	}

	get_print_format(format) {
		if (!format) {
			format = this.selected_format();
		}

		if (locals["Print Format"] && locals["Print Format"][format]) {
			return locals["Print Format"][format];
		} else {
			return {};
		}
	}

	setup_print_language() {
		const fields = this.dialog.fields_dict;

		//Load default print language from doctype
		this.lang_code =
			this.doc.language ||
			this.get_print_format().default_print_language ||
			frappe.boot.lang;

		//On selection of language retrieve language code
		const me = this;
		$(fields.language_sel.input).change(function () {
			me.lang_code = this.value;
		});

		// Load all languages in the select field language_sel
		$(fields.language_sel.input).empty().add_options(frappe.get_languages());

		if (this.lang_code) {
			$(fields.language_sel.input).val(this.lang_code);
		}
	}

	setup_print() {
		// print formats
		const fields = this.dialog.fields_dict;

		// toggle print format
		$(fields.attach_document_print.input).click(function () {
			$(fields.select_print_format.wrapper).toggle($(this).prop("checked"));
		});

		// select print format
		$(fields.select_print_format.wrapper).toggle(false);

		if (this.frm) {
			const print_formats = frappe.meta.get_print_formats(this.frm.meta.name);
			$(fields.select_print_format.input)
				.empty()
				.add_options(print_formats)
				.val(print_formats[0]);
		} else {
			$(fields.attach_document_print.wrapper).toggle(false);
		}
	}

	setup_attach() {
		const fields = this.dialog.fields_dict;
		const attach = $(fields.select_attachments.wrapper);

		if (!this.attachments) {
			this.attachments = [];
		}

		let args = {
			folder: "Home/Attachments",
			on_success: (attachment) => {
				this.attachments.push(attachment);
				this.render_attachment_rows(attachment);
			},
		};

		if (this.frm) {
			args = {
				doctype: this.frm.doctype,
				docname: this.frm.docname,
				folder: "Home/Attachments",
				on_success: (attachment) => {
					this.frm.attachments.attachment_uploaded(attachment);
					this.render_attachment_rows(attachment);
				},
			};
		}

		$(`
			<label class="control-label">
				${__("Select Attachments")}
			</label>
			<div class='attach-list'></div>
			<p class='add-more-attachments'>
				<button class='btn btn-xs btn-default'>
					${frappe.utils.icon("small-add", "xs")}&nbsp;
					${__("Add Attachment")}
				</button>
			</p>
		`).appendTo(attach.empty());

		attach
			.find(".add-more-attachments button")
			.on("click", () => new frappe.ui.FileUploader(args));
		this.render_attachment_rows();
	}

	render_attachment_rows(attachment) {
		const select_attachments = this.dialog.fields_dict.select_attachments;
		const attachment_rows = $(select_attachments.wrapper).find(".attach-list");
		if (attachment) {
			attachment_rows.append(this.get_attachment_row(attachment, true));
		} else {
			let files = [];
			if (this.attachments && this.attachments.length) {
				files = files.concat(this.attachments);
			}
			if (this.frm) {
				files = files.concat(this.frm.get_files());
			}

			if (files.length) {
				$.each(files, (i, f) => {
					if (!f.file_name) return;
					if (!attachment_rows.find(`[data-file-name="${f.name}"]`).length) {
						f.file_url = frappe.urllib.get_full_url(f.file_url);
						attachment_rows.append(this.get_attachment_row(f));
					}
				});
			}
		}
	}

	get_attachment_row(attachment, checked) {
		return $(`<p class="checkbox flex">
			<label class="ellipsis" title="${attachment.file_name}">
				<input
					type="checkbox"
					data-file-name="${attachment.name}"
					${checked ? "checked" : ""}>
				</input>
				<span class="ellipsis">${attachment.file_name}</span>
			</label>
			&nbsp;
			<a href="${attachment.file_url}" target="_blank" class="btn-linkF">
				${frappe.utils.icon("link-url")}
			</a>
		</p>`);
	}

	setup_email() {
		// email
		const fields = this.dialog.fields_dict;

		if (this.attach_document_print) {
			$(fields.attach_document_print.input).click();
			$(fields.select_print_format.wrapper).toggle(true);
		}

		$(fields.send_me_a_copy.input).on("click", () => {
			// update send me a copy (make it sticky)
			const val = fields.send_me_a_copy.get_value();
			frappe.db.set_value("User", frappe.session.user, "send_me_a_copy", val);
			frappe.boot.user.send_me_a_copy = val;
		});
	}

	send_action() {
		const me = this;
		const btn = me.dialog.get_primary_btn();
		const form_values = this.get_values();
		if (!form_values) return;

		const selected_attachments = $.map(
			$(me.dialog.wrapper).find("[data-file-name]:checked"),
			function (element) {
				return $(element).attr("data-file-name");
			}
		);

		if (form_values.attach_document_print) {
			me.send_email(
				btn,
				form_values,
				selected_attachments,
				null,
				form_values.select_print_format || ""
			);
		} else {
			me.send_email(btn, form_values, selected_attachments);
		}
	}

	get_values() {
		const form_values = this.dialog.get_values();

		// cc
		for (let i = 0, l = this.dialog.fields.length; i < l; i++) {
			const df = this.dialog.fields[i];

			if (df.is_cc_checkbox) {
				// concat in cc
				if (form_values[df.fieldname]) {
					form_values.cc = (form_values.cc ? form_values.cc + ", " : "") + df.fieldname;
					form_values.bcc =
						(form_values.bcc ? form_values.bcc + ", " : "") + df.fieldname;
				}

				delete form_values[df.fieldname];
			}
		}

		return form_values;
	}

	save_as_draft() {
		if (this.dialog && this.frm) {
			let message = this.dialog.get_value("content");
			message = message.split(separator_element)[0];
			localforage.setItem(this.frm.doctype + this.frm.docname, message).catch((e) => {
				if (e) {
					// silently fail
					console.log(e); // eslint-disable-line
					console.warn(
						"[Communication] IndexedDB is full. Cannot save message as draft"
					); // eslint-disable-line
				}
			});
		}
	}

	clear_cache() {
		this.delete_saved_draft();
		this.get_last_edited_communication(true);
	}

	delete_saved_draft() {
		if (this.dialog && this.frm) {
			localforage.removeItem(this.frm.doctype + this.frm.docname).catch((e) => {
				if (e) {
					// silently fail
					console.log(e); // eslint-disable-line
					console.warn(
						"[Communication] IndexedDB is full. Cannot save message as draft"
					); // eslint-disable-line
				}
			});
		}
	}

	send_email(btn, form_values, selected_attachments, print_html, print_format) {
		const me = this;
		this.dialog.hide();

		if (!form_values.recipients) {
			frappe.msgprint(__("Enter Email Recipient(s)"));
			return;
		}

		if (!form_values.attach_document_print) {
			print_html = null;
			print_format = null;
		}

		if (this.frm && !frappe.model.can_email(this.doc.doctype, this.frm)) {
			frappe.msgprint(__("You are not allowed to send emails related to this document"));
			return;
		}

		return frappe.call({
			method: "frappe.core.doctype.communication.email.make",
			args: {
				recipients: form_values.recipients,
				cc: form_values.cc,
				bcc: form_values.bcc,
				subject: form_values.subject,
				content: form_values.content,
				doctype: me.doc.doctype,
				name: me.doc.name,
				send_email: 1,
				print_html: print_html,
				send_me_a_copy: form_values.send_me_a_copy,
				print_format: print_format,
				sender: form_values.sender,
				sender_full_name: form_values.sender ? frappe.user.full_name() : undefined,
				email_template: form_values.email_template,
				attachments: selected_attachments,
				_lang: me.lang_code,
				read_receipt: form_values.send_read_receipt,
				print_letterhead: me.is_print_letterhead_checked(),
			},
			btn,
			callback(r) {
				if (!r.exc) {
					frappe.utils.play_sound("email");

					if (r.message["emails_not_sent_to"]) {
						frappe.msgprint(
							__("Email not sent to {0} (unsubscribed / disabled)", [
								frappe.utils.escape_html(r.message["emails_not_sent_to"]),
							])
						);
					}

					me.clear_cache();

					if (me.frm) {
						me.frm.reload_doc();
					}

					// try the success callback if it exists
					if (me.success) {
						try {
							me.success(r);
						} catch (e) {
							console.log(e); // eslint-disable-line
						}
					}
				} else {
					frappe.msgprint(
						__("There were errors while sending email. Please try again.")
					);

					// try the error callback if it exists
					if (me.error) {
						try {
							me.error(r);
						} catch (e) {
							console.log(e); // eslint-disable-line
						}
					}
				}
			},
		});
	}

	is_print_letterhead_checked() {
		if (this.frm && $(this.frm.wrapper).find(".form-print-wrapper").is(":visible")) {
			return $(this.frm.wrapper).find(".print-letterhead").prop("checked") ? 1 : 0;
		} else {
			return (
				frappe.model.get_doc(":Print Settings", "Print Settings") || { with_letterhead: 1 }
			).with_letterhead
				? 1
				: 0;
		}
	}

	async set_content(sender_email) {
		if (this.content_set) return;

		let message = this.message || "";
		if (!message && this.frm) {
			const { doctype, docname } = this.frm;
			message = (await localforage.getItem(doctype + docname)) || "";
		}

		if (message) {
			this.content_set = true;
		}

		message += await this.get_signature(sender_email || null);

		if (this.is_a_reply && !this.reply_set) {
			message += this.get_earlier_reply();
		}

		await this.dialog.set_value("content", message);
	}

	async get_signature(sender_email) {
		let signature = frappe.boot.user.email_signature;

		if (!signature) {
			let filters = {
				add_signature: 1,
			};

			if (sender_email) {
				filters["email_id"] = sender_email;
			} else {
				filters["default_outgoing"] = 1;
			}

			const email_accounts = await frappe.db.get_list("Email Account", {
				filters: filters,
				fields: ["signature", "email_id"],
				limit: 1,
			});

			let filtered_email = null;
			if (email_accounts.length) {
				signature = email_accounts[0].signature;
				filtered_email = email_accounts[0].email_id;
			}

			if (!sender_email && filtered_email) {
				if (
					this.user_email_accounts &&
					this.user_email_accounts.includes(filtered_email)
				) {
					this.dialog.set_value("sender", filtered_email);
				}
			}
		}

		if (!signature) return "";

		if (!frappe.utils.is_html(signature)) {
			signature = signature.replace(/\n/g, "<br>");
		}

		return "<br>" + signature;
	}

	get_earlier_reply() {
		this.reply_set = false;

		const last_email = this.last_email || (this.frm && this.frm.timeline.get_last_email(true));

		if (!last_email) return "";
		let last_email_content = last_email.original_comment || last_email.content;

		// convert the email context to text as we are enclosing
		// this inside <blockquote>
		last_email_content = this.html2text(last_email_content).replace(/\n/g, "<br>");

		// clip last email for a maximum of 20k characters
		// to prevent the email content from getting too large
		if (last_email_content.length > 20 * 1024) {
			last_email_content += "<div>" + __("Message clipped") + "</div>" + last_email_content;
			last_email_content = last_email_content.slice(0, 20 * 1024);
		}

		const communication_date = frappe.datetime.global_date_format(
			last_email.communication_date || last_email.creation
		);

		this.reply_set = true;

		return `
			<div><br></div>
			${separator_element || ""}
			<p>
			${__("On {0}, {1} wrote:", [communication_date, last_email.sender])}
			</p>
			<blockquote>
			${last_email_content}
			</blockquote>
		`;
	}

	html2text(html) {
		// convert HTML to text and try and preserve whitespace

		html = html
			.replace(/<\/div>/g, "<br></div>") // replace end of blocks
			.replace(/<\/p>/g, "<br></p>") // replace end of paragraphs
			.replace(/<br>/g, "\n");

		const text = frappe.utils.html2text(html);
		return text.replace(/\n{3,}/g, "\n\n");
	}
};

$(document).ready(function () {
	if (frappe.user.has_role("POS User")) {
		$("#posapp-link").removeClass("hidden")
	}

	$("#insight-link").click(() => {
		frappe.msgprint("Coming soon!")
	})
})

frappe.ui.form.LinkedWith = class LinkedWith {
	constructor(opts) {
		$.extend(this, opts);
	}

	show() {
		if (!this.dialog) this.make_dialog();

		$(this.dialog.body).html(
			`<div class="text-muted text-center" style="padding: 30px 0px">
				${__("Loading")}...
			</div>`
		);

		this.dialog.show();
	}

	make_dialog() {
		this.dialog = new frappe.ui.Dialog({
			title: __("Linked With"),
		});

		this.dialog.on_page_show = () => {
			frappe
				.xcall("frappe.desk.form.linked_with.get", {
					doctype: this.frm.doctype,
					docname: this.frm.docname,
				})
				.then((r) => {
					this.frm.__linked_docs = r;
				})
				.then(() => this.make_html());
		};
	}

	make_html() {
		let html = "";
		const linked_docs = this.frm.__linked_docs;
		const linked_doctypes = Object.keys(linked_docs);

		if (linked_doctypes.length === 0) {
			html = __("Not Linked to any record");
		} else {
			html = linked_doctypes
				.map((doctype) => {
					const docs = linked_docs[doctype];
					return `
					<div class="list-item-table margin-bottom">
						${this.make_doc_head(doctype)}
						${docs.map((doc) => this.make_doc_row(doc, doctype)).join("")}
					</div>
				`;
				})
				.join("");
		}

		$(this.dialog.body).html(html);
	}

	make_doc_head(heading) {
		return `
			<header class="level list-row list-row-head text-muted small">
				<div>${__(heading)}</div>
			</header>
		`;
	}

	make_doc_row(doc, doctype) {
		return `<div class="list-row-container">
			<div class="level list-row small">
				<div class="level-left bold">
					<a href="/app/${frappe.router.slug(doctype)}/${doc.name}">${doc.name}</a>
				</div>
			</div>
		</div>`;
	}
};
