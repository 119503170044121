
	frappe.templates['bank_account_list'] = `<div class="clearfix customize_float float-label"></div>
{% for(var i=0, l=bank_account_list.length; i<l; i++) { %}
<div class="address-box">	
	<p class="h6">
		{%= i+1 %}. {%= bank_account_list[i].account_name %} 
		

		<a href="/app/Form/Bank%20Account/{%= encodeURIComponent(bank_account_list[i].name) %}" class="btn btn-default btn-xs pull-right"
			>
			{%= __("Edit") %}</a>
	</p>
	
	<p>{% if(bank_account_list[i].bank) { %}{%= bank_account_list[i].bank %}<br>{% } %}
		{% if(bank_account_list[i].bank_account_no) { %}{%= bank_account_list[i].bank_account_no %}<br>{% } %}
		{% if(bank_account_list[i].branch_code) { %}{%= bank_account_list[i].branch_code %}<br>{% } %}
		{% if(bank_account_list[i].micr_code) { %}{%= bank_account_list[i].micr_code %}<br>{% } %}
		{% if(bank_account_list[i].swift_code) { %}{%= bank_account_list[i].swift_code %}<br>{% } %}
	</p>
    
</div>
{% } %}
{% if(!bank_account_list.length) { %}
<p class="text-muted small">{%= __("No Bank Details added yet.") %}</p>
{% } %}
<p><button class="btn btn-xs btn-default btn-bankdetails">{{ __("New Bank Account") }}</button></p>`;
